import React from 'react';
import {Link} from 'react-router-dom';

const SectionFour = () => {
  return (
    <>
         <section className="complimentry-section-three ip-padding">
        <div className="container">
            <div className="row g-lg-4 g-3 align-items-lg-center align-items-start">
                <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                    <div className="gallery-buckt d-flex gap-3 align-items-center ">
                        <img  className="bucket_super-child img-fluid" src="/images/products/bag-house/d5.jpg" alt="" style={{borderRadius:'8px'}}/>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                    <div className="bn-content-wrapper ps-xl-5 ps-md-3 ps-0 mt-md-0 mt-3">
                        <h4 className="section-heading  mb-2 w-lg-75">Revolutionizing Air Quality Across Industries</h4>
                        <p className="section-paragraph ">See how Bag Houses revolutionize air quality by capturing dust in cement factories, controlling emissions in steel plants, and reducing fly ash in power plants.</p>

                        <div className="mt-4 justify-content-center justify-content-md-start">
                            <ul className="icon-list style-two  ps-0">
                                <li><i className="bi bi-check-circle"></i> Cement Industry: Capturing Kiln Dust and Dedusting at various locations</li>
                                <li><i className="bi bi-check-circle"></i> Steel Plants: Controlling Emissions from Furnaces and Dedusting at various locations</li>
                                <li><i className="bi bi-check-circle"></i> Power Plants: Reducing Fly Ash in Exhaust Gases</li>
                            </ul>

                        </div>

                        <div className="mt-5">
                            <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>  
    </>
  )
}

export default SectionFour;