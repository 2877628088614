import React from 'react';
import { Link } from 'react-router-dom';


const SectionTwoThree = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/dome-valve/dv-main.jpg" alt="" />

                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2">High Efficiency Dome Valves</h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span> Dome Valve solutions are engineered for reliability and longevity, featuring non-clogging designs and wear-resistant materials for trouble-free operation.</p>
                                <div className="d-flex gap-3  mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Proven Reliability</li>
                                        <li><i className="bi bi-check-circle"></i> Longevity</li>
                                        <li><i className="bi bi-check-circle"></i> Economic Efficiency</li>

                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none ps-0">
                                        <li><i className="bi bi-check-circle"></i> Customizable Design</li>
                                        <li><i className="bi bi-check-circle"></i> Global Application</li>
                                        <li><i className="bi bi-check-circle"></i> Optimal Performance</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-md-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInRight" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>

                                <h4 className="section-heading mb-2 pe-5 pe-md-0 w-md-75 w-100 mx-auto "> Why Choose UNICON</h4>
                                <p className="section-paragraph">UNICON stands out as a leader in providing superior Dome Valve solutions, recognized for our commitment to:</p>
                                <div className="d-flex gap-3  mt-4 ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> Innovative Design</li>
                                        <li><i className="bi bi-check-circle"></i> Quality Assurance</li>
                                        <li><i className="bi bi-check-circle"></i> Customization</li>
                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none ps-0">
                                        <li><i className="bi bi-check-circle"></i> Global Support</li>
                                        <li><i className="bi bi-check-circle"></i> Customer Satisfaction</li>
                                        <li><i className="bi bi-check-circle"></i> Proven Track Record</li>
                                    </ul>
                                </div>
                                <div className="mt-4">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center mt-lg-0 mt-3">

                                <img style={{ "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/dome-valve/dv-main2.jpg" alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`

                .ar, .ad{
                    font-size:30px;
                }
                    .adm{
                     font-size: 18px;
                      margin-top: -18px;
                    }
                .flow-diagram {
                    display: grid;
                    grid-template-columns: 1fr 50px 1fr 50px 1fr;
                    grid-template-rows: repeat(5, auto);
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                }
                .box {
                    padding: 10px;
                    border-radius:8px;
                    font-weight: 600;
                    color: #1a1a1a;
                    border: 1px solid;
                    background-color: #fff;
                    position: relative;
                }
        
        @media only screen and (max-width: 991px) {
            .icon-list.style-three {
                display: flex;
            }
            ul.icon-list.style-three li {
                align-items: center;
                margin-bottom: 0;
                text-align: center;
            }
        }
        @media only screen and (max-width: 575px) {
            .flex-sm-d-wrap {
                flex-wrap: wrap;
            }
        }
        `}
            </style>
        </>
    )
}

export default SectionTwoThree;