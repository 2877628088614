import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon | Screw Conveyors for Efficient Material Handling"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Screw Conveyors for Efficient Material Handling</title>
      <meta
        name="title"
        content="Unicon Screw Conveyors for Efficient Material Handling"
      />
      <meta
        name="description"
        content="Unicon's screw conveyors offer reliable, efficient material handling solutions with durable design, ensuring smooth performance in various industrial applications."
      />
      <meta name="keywords" content="Best Screw Conveyors" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />

      <meta
        property="og:title"
        content="Unicon Screw Conveyors for Efficient Material Handling"
      />
      <meta property="og:site_name" content="Unicon Screw Conveyors" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/screw-conveyor"
      />
      <meta
        property="og:description"
        content="Unicon's screw conveyors offer reliable, efficient material handling solutions with durable design, ensuring smooth performance in various industrial applications."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/screw-conveyor/sc-main.jpg"
      />
    </Helmet>
  );
};

export default Meta;
