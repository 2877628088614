import React, { useEffect } from 'react';
import HeroSection from './HeroSection';
import Industry from './Industry';
import ProfileSection from './ProfileSection';
import WhatWeDoSection from './WhatWeDoSection';
import DecadesSection from './DecadesSection';
import CertificateSection from './CertificateSection';
import SolutionSection from './SolutionSection';
import Partners from './Partners';
import ServcieSection from './ServcieSection';
import SolutionSectionTwo from './SolutionSectionTwo';
import GlobeMap from './GlobeMap';
import HighlightsSection from './HighlightsSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from '../../common/Meta/home';

const Home = () => {
  useEffect(() => {
    document.title = "Unicon - Air Pollution Control Equipment Manufacturers";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
       
        <Meta />
        <HeroSection/>
        <Industry/>
        <ProfileSection/>
        <WhatWeDoSection/>
        <DecadesSection/>
        <CertificateSection/>
        <SolutionSection/>
        <Partners/>
        <ServcieSection/>
        <SolutionSectionTwo/>
        <GlobeMap/>
        <HighlightsSection/>
       
    </>
  )
}

export default Home;