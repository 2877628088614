import React from "react";
import { Link } from "react-router-dom";

const LowNoxHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/low-nox-burners/burner3.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Low NOx Burners
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start ">
                  Upgrade to greener combustion with UNICON! Our Low NOx Burners
                  reduce emissions without sacrificing efficiency. Trust 40+
                  years of excellence. Choose UNICON for a sustainable industry.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const EspHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/electrostatic-percipitators/banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Electrostatic Precipitators (ESP / EP)
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start ">
                  Purify Your Industrial Emissions with Unicon's Electrostatic
                  Precipitators
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const BagHouseHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/bag-house/banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Bag House / Bag Filter{" "}
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start ">
                  Bag Houses, also known as Bag Filters, are essential air
                  pollution control devices used to filter particulate matter
                  from industrial exhaust streams.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const FcsHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/flue-gas-conditioning/hero.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Flue Gas Conditioning System for
                  <br />
                  Waste to Energy
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon’s system optimizes gas treatment for waste-to-energy,
                  ensuring effective emission control and better energy
                  recovery.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const AfgcHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/afgc/afgc-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  AFGC Systems
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON has a credential record of success in designing,
                  engineering, fabricating, Supplying, erection and
                  commissioning of AFGC (Ammonia Flue Gas Conditioning) Systems
                  for reduction of SPM from ESPs installed with Indian Coal
                  Fired Thermal Power Plants.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const MistHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/mist-separators-candle-filters/hero-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Mist Separator - Candle Filter
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Capture and remove fine mist from gas streams with our
                  high-performance candle filters, ensuring optimal air quality
                  and equipment longevity.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const PcsHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/pneumatic-conveying-system/banner-img.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Pneumatic Conveying Systems / Ash
                  <br className="d-lg-block d-none" /> Handling System
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Discover UNICON’s cutting-edge Pneumatic Conveying Systems a
                  dust-free solution that ensures compliance with environmental
                  standards. Our dry method eliminates wastewater concerns,
                  making it ideal for various industries.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const RotaryHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/rotary-airlock-valve/hero-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Rotary Airlock Valves
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Highlight the benefits of using rotary airlock valves, such as
                  preventing air leakage, maintaining pressure differentials,
                  and ensuring smooth material flow.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SlideGateHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/slide-gate/hero.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Slide Gate
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON has a proven track record in designing, manufacturing,
                  and supplying high-performance Slide Gate Valves for precise
                  service isolation in diverse industrial applications.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DoubleConeHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/double-cone-valve/dcv-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Double Cone Valve
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Double Cone Valves hold a prominent position in the market due
                  to their exceptional ability to provide controlled feeding and
                  maintain airtight sealing between the collection chamber and
                  conveying equipment.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DoubleFlapHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/double-flap-valve/dfp-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Double Flap Valve
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON’s Double Flap Valves excel in precise feeding control
                  and effective airlock sealing between collection chambers and
                  conveying equipment.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ClamShellHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/clam-shell-gate/csg-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Clam Shell Gate / Sector Gate Systems
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON’s Clam Shell Gate / Sector Gate Systems facilitate
                  efficient unloading of dust and coarse particles from hoppers
                  and storage bins, optimizing industrial operations.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DomeHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/dome-valve/dv-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Dome Valve
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON has a distinguished record in designing and supplying
                  high-efficiency dome valves that excel across a wide range of
                  industrial applications.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DrgaChainHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/drag-chain-conveyor/dcc-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Drag Chain Conveyor
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON boasts a proven track record in expertly installing
                  Drag Chain Conveyors across diverse applications, ensuring
                  optimal performance and reliability in industrial settings.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const BeltHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/belt-conveyor/cc-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Belt Conveyor
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is supplying belt conveyors with vast pool expertise in
                  standard type and special type. Unicon Belt conveyors are used
                  for various applications like transportation of cement,
                  gypsum, Sinter dust, coal, etc.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ScrewHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/screw-conveyor/sc-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Screw Conveyor
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON has a proven track record of installing various types
                  of screw conveyors for diverse applications. Our expertise
                  ensures efficient, reliable, and customized material handling
                  solutions tailored to meet the specific needs of our clients.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SilencersHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/silencers/silencer-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Silencers
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start ">
                  Reduces noise generated during atmospheric expansion of gases
                  during valve discharges, blowdowns, and ejections. This
                  ensures a safer and quieter working environment, complying
                  with noise regulations.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DefenceEquipmentsHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/defence/dfc-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Defence Equipments
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON provides dust-free, high-performance defence equipment,
                  ensuring top quality and reliability for various defence
                  departments.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const StorageTankHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/storage-tanks/st-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Storage Tank / Storage Silo
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON boasts a proven track record in the design,
                  manufacturing, supply, erection, and commissioning of storage
                  tanks on a turnkey basis.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const AgitatorsHero = () => { 
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/agitators/agi-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Agitators
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON offers a comprehensive range of agitators tailored for
                  various industries, including paints, resins, chemicals,
                  defence, and breweries.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const JacketedHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/jacketed-vessels/jv-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Jacketed Vessels
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Union is having vast pool expertise in Design, Engineering,
                  Manufacturing, Supply, Erection and Commissioning of Jacketed
                  vessels.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const PressureHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/pressure-vessels/ps-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Pressure Vessels
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is having vast pool expertise in Design, Engineering,
                  Manufacturing, Supply, Erection, Testing and Commissioning of
                  Low, Medium and High Pressure Vessels for liquid and gaseous
                  applications.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DecomposersHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/products/decomposers/dc-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Decomposers
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON has a proven track record in supplying a diverse range
                  of decomposers tailored for various applications.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
Request Quote<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// Services Section Hero Content

const CFDHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/cfd/cfd-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Computational Fluid Dynamics
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is equipped with Computational Fluid Dynamics software
                  and more than 35 years in carrying out study of ESP
                  properties.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const GDTHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/gd-testing/gd-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  GD Testing
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is equipped to carryout GD testing of any make ESPs
                  with more than 35 years experience in the field.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const EmissionTestingHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/emission-testing/emission-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Emission Testing
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon conducts emission level testing for pollution control
                  equipment such as ESPs, Bag Filters, and Cyclones. This
                  comprehensive testing program involves simultaneous sampling
                  of both inlet and outlet conditions to measure particulate
                  mass loading.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SupervisoryServiceHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/supervisory-service/ss-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Supervisory Service
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON provides swift, expert service with our skilled
                  workforce and extensive inventory. We specialize in ESPs, Bag
                  Houses, and Wet Scrubbers for power units, steel, sugar,
                  cement, and paper industries.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ECHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/erection-comissioning/ec-banner.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Erection & Commissioning
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON excels in Air Pollution Control Equipment with
                  expertise in design, engineering, manufacturing, civil works,
                  erection, commissioning, servicing, and upgrades for ESPs, Bag
                  Houses, Cyclones, and more.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const RetrofitsHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/retrofits/retrofit-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Retrofits
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  UNICON is fully equipped with spares and highly Skilled and
                  experienced service department. UNICON assures fastest
                  delivery and an immediate attention to our customer calls for
                  any service requirement.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const UpgradesHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/upgrades/upgrades-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Upgrades
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                Enhance ESP efficiency and reduce costs with seamless implementation, minimizing downtime and meeting emission standards.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const RLAHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/rla/rla-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Residual Life Assessment
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is fully equipped with tools, tackles and expert
                  manpower to make a detailed RLA study of your plant and to
                  provide suitable solutions.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SMHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/rla/rla-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Shutdown Maintenance
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is equipped with all Tools, Tackles, Skilled Manpower
                  and Spares to meet customer needs for Shutdown Maintenance and
                  Breakdown Maintenance Services.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const AMHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/annual-maintenance/annual-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Annual Maintenance
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is fully equipped with tools, tackles and expert
                  manpower to make maximum availability of your plant with a
                  suitable Annual maintenance service.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SFESPHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/esp-spares/spares-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Spares for ESP
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is Equipped with a Design Team, Drawings and
                  Manufacturing facilities to supply Spares for all make of
                  ESPs. We have a vast pool of expertise in supply of the spares
                  for ESPs more than 30 years.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const SFBHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/esp-spares/spares-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Spares for Baghouse
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is Equipped with Design Team, Drawings and
                  Manufacturing facilities to supply Spares for all make of Bag
                  Houses. We are having a vast pool expertise in supply of
                  spares for Bag House more than 30 years.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const CPSHero = () => {
  return (
    <>
      <section
        className="product-banner position-relative inner-page-padding "
        style={{
          backgroundImage: `url("/images/services/esp-spares/spares-bg.jpg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div
            className="wow fadeInUpBig "
            data-wow-delay="0s"
            style={{
              visibility: "visible",
              animationDelay: "0s",
              animationName: "fadeInUpBig",
            }}
          >
            <div className="d-lg-flex align-items-center justify-content-between gap-10">
              <div className="contentwrapper position-relative z-1 text-center text-lg-start">
                <h2 className="product-title text-center text-lg-start text-white">
                  Cement Plant Spares
                </h2>
                <p className="product-description w-lg-75 text-center text-lg-start mb-0">
                  Unicon is Equipped with Design Team and Manufacturing
                  facilities to supply Spares for Cement Plants. We are having a
                  vast pool expertise in supply of spares for Cement Plants more
                  than 30 years.
                </p>
              </div>
              <div className="flex-grow-1 flex-shrink-0 position-relative z-1 text-lg-end text-center">
                <Link className="main-btn text-white" data-bs-toggle="modal"
data-bs-target="#exampleModal">
    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
  </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export {
  LowNoxHero,
  EspHero,
  BagHouseHero,
  FcsHero,
  AfgcHero,
  MistHero,
  PcsHero,
  RotaryHero,
  SlideGateHero,
  DoubleConeHero,
  DoubleFlapHero,
  ClamShellHero,
  DomeHero,
  DrgaChainHero,
  BeltHero,
  ScrewHero,
  SilencersHero,
  DefenceEquipmentsHero,
  StorageTankHero,
  AgitatorsHero,
  JacketedHero,
  PressureHero,
  DecomposersHero,
  CFDHero,
  GDTHero,
  EmissionTestingHero,
  SupervisoryServiceHero,
  ECHero,
  RetrofitsHero,
  UpgradesHero,
  RLAHero,
  SMHero,
  AMHero,
  SFESPHero,
  SFBHero,
  CPSHero
};
