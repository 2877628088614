import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon |  Annual Maintenance for Reliable System Operation"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon |  Annual Maintenance for Reliable System Operation</title>
      <meta name="title" content="Unicon Annual Maintenance for Reliable System Operation"/>
<meta name="description" content="Unicon provides annual maintenance services, ensuring consistent performance, reliability, and longevity of your industrial equipment throughout the year"/>
<meta name="keywords" content="Annual Maintenance Services"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Annual Maintenance for Reliable System Operation"/>
<meta property="og:site_name" content="Unicon Annual Maintenance"/>
<meta property="og:url" content="https://www.uniconinter.com/annual-maintenance"/>
<meta property="og:description" content="Unicon offers comprehensive shutdown maintenance services to ensure thorough inspection, repair, and optimization of industrial systems for peak performance."/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/services/annual-maintenance/annual-maintenance.jpg"/> 

    </Helmet>
  );
};

export default Meta;
