import React, { useEffect } from 'react';
import { AfgcHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { AmmoniaAcc } from '../../../common/Components/AccordionSection';
import { AfgcCta } from '../../../common/Components/CtaSection';
import { AfgcQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/AmmoniaFluGas";

const AmmoniaFluGas = () => {
  useEffect(() => {

    document.title = "Unicon | Advanced Flue Gas Conditioning System";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <AfgcHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <AmmoniaAcc/>
        <AfgcCta/>
        <AfgcQUickLinks/>
    </>
  )
}

export default AmmoniaFluGas;