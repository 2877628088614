import React, { useEffect } from 'react';
import { BeltHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { BeltAcc } from '../../../common/Components/AccordionSection';
import { BeltCta } from '../../../common/Components/CtaSection';
import { BeltQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/BeltConveyors";

const Belt = () => {
  useEffect(() => {
    document.title = "Unicon | Belt Conveyors for Smooth Material Transport";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <BeltHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <BeltAcc/>
        <BeltCta/>
        <BeltQUickLinks/>
    </>
  )
}

export default Belt;