import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import $, { data } from "jquery";
import axios from "axios";

const areaOfInterestOptions = [
  {
    id: 1,
    name: "Electrical Engineer"
  },
  {
    id: 2,
    name: "Electrical Engineer (Fresher)"
  },
  {
    id: 3,
    name: "Mechanical Engineer (Fresher)"
  },
  {
    id: 4,
    name: "Mechanical Engineer"
  },
  {
    id: 5,
    name: "HR - Admin Executive"
  },
  {
    id: 6,
    name: "Production Engineer (Ass. Manager)"
  },
  {
    id: 7,
    name: "Project Manager"
  },
  {
    id: 8,
    name: "Purchase Manager"
  },
  {
    id:9,
    name: "Telecalling Executive"
  },
  {
    id: 10,
    name: "Sales Manager"
  },
  {
    id: 11,
    name: "Quality Manager"
  },
  {
    id: 12,
    name: "Marketing Engineer"
  },
  {
    id: 13,
    name: "Jr. Design Engineer"
  },
  
];

const JobForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [selectedAreaId, setSelectedAreaId] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [selectedSubOption, setSelectedSubOption] = useState("");

  const handleAreaChange = (e) => {
    const selectedId = e.target.value;
    const selectedArea = areaOfInterestOptions.find(
      (area) => area.id === parseInt(selectedId, 10)
    );

    setSelectedAreaId(selectedId);
    setSelectedAreaName(selectedArea ? selectedArea.name : "");
    setSelectedSubOption("");

    $("#areaOfInterest").next(".error-msg").remove();
  };

  console.log(selectedAreaName);

  const handleSubOptionChange = (e) => {
    setSelectedSubOption(e.target.value);

    $("#subField").next(".error-msg").remove();
  };

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };

  const handleKeypress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const validateEmail = (value) => {
    const domainExtensions = [".com", ".net", ".in", ".co"];
    const matches = value.match(/\.\w+/g);

    if (matches) {
      const uniqueExtensions = new Set(
        matches.map((match) => match.toLowerCase())
      );
      if (uniqueExtensions.size !== matches.length) {
        return false; // Reject if there are repeated domain extensions
      }
    }

    if (value.includes("..")) {
      return false; // Reject if there are consecutive dots
    }

    const [localPart, domainPart] = value.split("@");
    if (!localPart || !domainPart) {
      return false; // Reject if there is no local part or domain part
    }

    if (
      localPart.startsWith(".") ||
      localPart.endsWith(".") ||
      domainPart.startsWith(".")
    ) {
      return false; // Reject if the local part starts or ends with a dot or if the domain part starts with a dot
    }

    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
  };

  const validatePhone = (value) => {
    var pattern1 = /^\d{10}$/;
    var pattern2 = /^(?:\+\d{1,3}[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/;
    var pattern3 =
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

    return pattern1.test(value) || pattern2.test(value) || pattern3.test(value);
  };

  useEffect(() => {
    const validateField = (selector, validationFn, errorMessage) => {
      const value = $(selector).val();
      if (!validationFn(value)) {
        if (!$(selector).next(".error-msg").length) {
          $(selector).after(
            `<div class="error-msg text-danger">${errorMessage}</div>`
          );
        }
      } else {
        $(selector).next(".error-msg").remove();
      }
    };

    const validateName = (value) =>
      /^[A-Za-z\s]*$/.test(value) && value.length >= 3 && value.length <= 50;


    $("#name").on("input", () =>
      validateField(
        "#name",
        validateName,
        "Name must be at least 3 characters long"
      )
    );
    $("#email").on("input", () =>
      validateField(
        "#email",
        validateEmail,
        "Please enter a valid email address."
      )
    );
    $("#phone").on("input", () => {
      const phoneValue = $("#phone").val();
      if (!validatePhone(phoneValue)) {
        if (!$("#phone-wrapper .error-msg").length) {
          $("#phone-wrapper").append(
            '<div class="error-msg text-danger">Please enter a valid phone number.</div>'
          );
        }
      } else {
        $("#phone-wrapper .error-msg").remove();
      }
    });
    $("#phone").on("keydown", function (e) {
      if (
        $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (e.keyCode == 65 && e.ctrlKey === true) ||
        (e.keyCode == 67 && e.ctrlKey === true) ||
        (e.keyCode == 86 && e.ctrlKey === true) ||
        (e.keyCode == 88 && e.ctrlKey === true) ||
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }

      // Allow numeric digits, '+', '(', and ')' keys only
      if (!/[\d()+]/.test(e.key)) {
        e.preventDefault();
      }
    });
    $("#phone").on("keypress", function (event) {
      var phone = $(this).val();
      if (phone.length >= 15) {
        event.preventDefault();
      }
    });
  }, []);

  const handleValidation = () => {
    let isValid = true;
    $(".error-msg").remove(); // Clear existing error messages

    // Validate Name
    const name = $("#name").val();
    if (!name) {
      isValid = false;
      $("#name").after(
        '<div class="error-msg text-danger">Name is required.</div>'
      );
    } else if (
      !/^[A-Za-z\s]*$/.test(name) ||
      name.length < 3 ||
      name.length > 50
    ) {
      isValid = false;
      $("#name").after(
        '<div class="error-msg text-danger">Name must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Email
    const email = $("#email").val();
    if (!email) {
      isValid = false;
      $("#email").after(
        '<div class="error-msg text-danger">Email is required.</div>'
      );
    } else if (!validateEmail(email)) {
      isValid = false;
      $("#email").after(
        '<div class="error-msg text-danger">Please enter a valid email address.</div>'
      );
    }

    // Validate Phone
    const phone = $("#phone").val();
    if (!phone) {
      isValid = false;
      $("#phone-wrapper").append(
        '<div class="error-msg text-danger">Phone number is required.</div>'
      );
    } else if (!validatePhone(phone)) {
      isValid = false;
      $("#phone-wrapper").append(
        '<div class="error-msg text-danger">Please enter a valid phone number.</div>'
      );
    }

    // Validate Designation
    const company = $("#designation").val();
    if (!company) {
      isValid = false;
      $("#designation").after(
        '<div class="error-msg text-danger">Designation is required.</div>'
      );
    } else if (company.length < 3 || company.length > 50) {
      isValid = false;
      $("#designation").after(
        '<div class="error-msg text-danger">Designation must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Organisation
    const organisation = $("#organisation").val();
    if (!organisation) {
      isValid = false;
      $("#organisation").after(
        '<div class="error-msg text-danger">Organisation is required.</div>'
      );
    } else if (organisation.length < 3 || organisation.length > 50) {
      isValid = false;
      $("#organisation").after(
        '<div class="error-msg text-danger">Organisation must be between 3 and 50 characters long.</div>'
      );
    }

    // Validate Area of Interest and Sub-option
    const selectedAreaId = $("#areaOfInterest").val();

    if (!selectedAreaId) {
      isValid = false;
      $("#areaOfInterest").after(
        '<div class="error-msg text-danger">Please select an area of interest.</div>'
      );
    } 

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) return;

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "http://localhost/Uniconbackend/Contact_form.php",
        {
          name: $("#name").val(),
          email: $("#email").val(),
          phone: $("#phone").val(),
          designation: $("#designation").val(),
          organisation: $("#organisation").val(),
          message: $("#message").val(),
          areaOfInterest: selectedAreaName,
          subField: selectedSubOption,
          mode: "addCenterModuleData_new",
        }
        // {
        //   headers: {
        //     "Content-Type": "application/json", // Ensure the Content-Type is set to application/json
        //   },
        // }
      );

      const responseData = response.data;
      if (responseData === 1) {
        // Reset form fields
        $("#contactform")[0].reset();

        // Reset state variables
        setSelectedAreaId("");
        setSelectedAreaName("");
        setSelectedSubOption("");

        navigate("/");
        window.location.reload();
      } else {
        console.error("Error submitting form data");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className="modal fade"
      id="exampleModalJob"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header p-3">
            <h6 className="section-sub_heading mb-0" id="staticBackdropLabel">
            Your Next Career Chapter Begins Here!
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-0">
            <div className="reach-us_form">
              <form
                id="contactform"
                className="contactform"
                onSubmit={handleSubmit}
              >
                <div className="row g-3 p-3">
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                        Full Name <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Full Name"
                        onInput={handleNameInput}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                        Email Address <span className="text-red">*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                        onKeyDown={handleKeypress}
                      />
                    </div>
                  </div>
                  <div className="col-12 position-relative" id="phone-wrapper">
                    <div className="form-group ">
                      <label>
                        Phone Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Enter Your Phone Number"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group position-relative">
                      <label>Position Applied For <span className="text-red">*</span></label>
                      <select
                        name="area_of_Interest"
                        className="form-select  border"
                        aria-label="Default select example"
                        onChange={handleAreaChange}
                        value={selectedAreaId}
                        id="areaOfInterest"
                      >
                        <option value="">Select</option>
                        {areaOfInterestOptions.map((interest) => (
                          <option key={interest.id} value={interest.id}>
                            {interest.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  
                  <div className="col-12">
                    <div className="form-group position-relative">
                      <label>
                      Upload Your Resume / CV <span className="text-red">*</span>
                      </label>
                      <input type="file" accept=".doc,.docx,.pdf" className="form-control" id="docfile" name="docfile" />
                    </div>
                  </div>
                </div>
                <div className="modal-footer  p-3 mt-2">
                  <button
                    type="submit"
                    className="btn primary-btn-1"
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "18px",
                      textTransform: "none",
                      backgroundColor: "#052d83",
                      border: "1px solid #052d83",
                      padding: "8px 24px",
                      borderRadius: "8px",
                      whiteSpace: "normal",
                    }}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobForm;
