import React from 'react';
import {DesignTable} from '../../common/Components/TablesProduct'


const SectionThree = () => {
    return (
        <>
            <section className="cust-padding" id="rop">
                <div className="container wow fadeInUp" data-wow-delay=".1s" >
                    <h4 className="section-heading text-center mb-5">Range of Products</h4>

                    <div className="table-responsive">
                        <DesignTable/>
                    </div>

                </div>
            </section>
        </>
    )
}

export default SectionThree;