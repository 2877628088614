import React from 'react'

const SecondSection = () => {
    return (
        <>

            <div className="service-page-slider">
                <div id="carouselExampleInterval" className="carousel slide">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="/images/services/esp-spares/slide-1.jpg" alt="" style={{ borderRadius: "10px", width: "100%" }} />
                        </div>
                        <div className="carousel-item">
                            <img src="/images/services/esp-spares/slide-2.jpg" alt="" style={{ borderRadius: "10px", width: "100%" }} />
                        </div>
                        <div className="carousel-item">
                            <img src="/images/services/esp-spares/slide-1.jpg" alt="" style={{ borderRadius: "10px", width: "100%" }} />
                        </div>
                        <div className="carousel-item">
                            <img src="/images/services/esp-spares/slide-2.jpg" alt="" style={{ borderRadius: "10px", width: "100%" }} />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>


            <style>
                {`
                    .service-page-slider-main{
                     overflow:hidden;
                    }
                    .service-page-slider{
                        padding:10px 10px 80px 10px;
                        background: #fff;
                        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
                        border-radius:8px;
                    }
                    .carousel-control-next, .carousel-control-prev{
                    opacity:1;
                    }
                    .carousel-control-next-icon{
                       background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2NjMDAxNyIgY2xhc3M9ImJpIGJpLWNoZXZyb24tcmlnaHQiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42NDYgMS42NDZhLjUuNSAwIDAgMSAuNzA4IDBsNiA2YS41LjUgMCAwIDEgMCAuNzA4bC02IDZhLjUuNSAwIDEgMS0uNzA4LS43MDhMMTAuMjkzIDggNC42NDYgMi4zNTRhLjUuNSAwIDAgMSAwLS43MDgiLz4KPC9zdmc+');
                           width: 24px;
                          height: 24px;
                    }
                    .carousel-control-prev-icon{
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2NjMDAxNyIgY2xhc3M9ImJpIGJpLWNoZXZyb24tbGVmdCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zNTQgMS42NDZhLjUuNSAwIDAgMSAwIC43MDhsLTUuNjQ3IDUuNjQ3IDUuNjQ3IDUuNjQ2YS41LjUgMCAwIDEtLjcwOC43MDhsLTYtNmEuNS41IDAgMCAxIDAtLjcwOGw2LTZhLjUuNSAwIDAgMSAuNzA4IDAiLz4KPC9zdmc+');
                            width: 24px;
                          height: 24px;
                    }

                    .carousel-control-next {
                        right: 0;
                        top: auto;
                        bottom: -61px;
                        right: 40%;
                        border: 1px solid #cc0017;
                        width: fit-content;
                        padding: 9px;
                        border-radius: 50%;
                     }
                    .carousel-control-prev{  
                        top: auto;
                        bottom: -61px;
                        left: 40%;
                        border: 1px solid #cc0017;
                        width: fit-content;
                        padding: 9px;
                        border-radius: 50%;
                     }
                    .carousel-control-next:hover, .carousel-control-prev:hover{
                      background-color:#cc0017;
                    }
                    .carousel-control-next:hover .carousel-control-next-icon{
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2ZmZiIgY2xhc3M9ImJpIGJpLWNoZXZyb24tcmlnaHQiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42NDYgMS42NDZhLjUuNSAwIDAgMSAuNzA4IDBsNiA2YS41LjUgMCAwIDEgMCAuNzA4bC02IDZhLjUuNSAwIDEgMS0uNzA4LS43MDhMMTAuMjkzIDggNC42NDYgMi4zNTRhLjUuNSAwIDAgMSAwLS43MDgiLz4KPC9zdmc+);
                    }
                    .carousel-control-prev:hover .carousel-control-prev-icon {
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2ZmZiIgY2xhc3M9ImJpIGJpLWNoZXZyb24tbGVmdCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zNTQgMS42NDZhLjUuNSAwIDAgMSAwIC43MDhsLTUuNjQ3IDUuNjQ3IDUuNjQ3IDUuNjQ2YS41LjUgMCAwIDEtLjcwOC43MDhsLTYtNmEuNS41IDAgMCAxIDAtLjcwOGw2LTZhLjUuNSAwIDAgMSAuNzA4IDAiLz4KPC9zdmc+');
                    }

                    @media(max-width:1200px){
                     .carousel-control-prev{
                         left: 36%;
                     }
                    .carousel-control-next{
                         right: 36%;
                     }
                    }
                     @media(max-width:767px){
                     .carousel-control-prev{
                         left: 40%;
                     }
                    .carousel-control-next{
                         right: 40%;
                     }
                    }
                      @media(max-width:575px){
                     .carousel-control-prev{
                         left: 36%;
                     }
                    .carousel-control-next{
                         right: 36%;
                     }
                    }

                     @media(max-width:375px){
                     .carousel-control-prev{
                         left: 32%;
                     }
                    .carousel-control-next{
                         right: 32%;
                     }
                    }
                    `}
            </style>

        </>
    )
}

export default SecondSection;