import React from 'react'

const index = () => {
  return (
    <>
        <section className='thankyou-wrapper ty-padding' >
            <div className="container">
            <div className="thankyou-inner ">
                <div className="row justify-content-center">
                    <div className="col-xll-7 col-xl-8 col-lg-10">
                        <div className='ty-ctn d-flex flex-column justify-content-end position-relative align-items-center'>
                        <img src="/images/thankyou.jpg" className='img-fluid' alt="" style={{borderRadius: "8px"}}/>
                            <h3 className='mb-2'>Thank You for Reaching Out!</h3>
                            <p className='text-center' style={{fontSize:"20px"}}>We appreciate you taking the time to get in touch with us. Your inquiry has been received, and one of our experts will respond to you shortly.</p>
                           
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
        <style>
            {`
            .ty-padding{
            padding:290px 0 100px 0px;
            }
            .thankyou-inner{
                margin:0 40px;
            }
            .thankyou-inner img{
                   width: 490px;
                    position: absolute;
                    top: -115px;
                    left: 50%;
                    transform: translateX(-50%);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
            .ty-ctn{
                padding:30px;
                background-color: #f5f5f5;
                border-radius: 8px;
                height:410px
            }
            @media (max-width:991px){
                .ty-ctn h3{
                    font-size:30px !important;
                }
                .thankyou-inner img {
                    width: 455px;
                }
            }
            @media (max-width:767px){
                .ty-ctn h3{
                    font-size:26px !important;
                }
                .ty-ctn p{
                    font-size:18px !important;
                }
                .thankyou-inner img {
                    width: 300px;
                    top: -95px;
                }
                .ty-ctn {
                    height: 310px;
                }
            }
            @media (max-width:575px){
                .ty-padding {
                    padding: 250px 0 50px 0px;
                }
                .ty-ctn h3{
                    font-size:22px !important;
                    text-align:center;
                }
                .ty-ctn p{
                    font-size:18px !important;
                }
                
                .ty-ctn {
                    height: 330px;
                }
                .thankyou-inner {
                    margin: 0px 20px;
                }
            }
             @media (max-width:500px){
                .thankyou-inner img {
                    position:relative;
                    top:0;
                    width:auto;
                    margin-bottom:20px;
                }
                .ty-ctn {
                    height: auto;
                    padding: 15px;
                }
                .ty-ctn p {
                    font-size: 16px !important;
                    margin-bottom:0px;
                }
                .ty-padding {
                    padding: 150px 0 50px 0px;
                }
                .thankyou-inner {
                    margin: 0px 0px;
                }
            }
                            `}
        </style>
    </>
  )
}

export default index;