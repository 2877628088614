import React from 'react';

const WhatWeDoSection = () => {
    return (
        <>
            <section className="cust-padding2">
                <div className="container">
                    <div className="about-bigbox" style={{ "backgroundColor": "#f2f2f2", "padding": "70px", "borderRadius": "8px" }}>
                        <div className="top-cont wow fadeInUpBig" data-wow-delay=".1s" style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeInLeftBig", marginBottom: "50px" }}>
                            <h4 className="section-heading mb-4">What We Do<span className="text-red"><i className="bi bi-dash-lg"></i></span></h4>
                            <p style={{ "fontSize": "20px", "color": "#101010" }}>We specialize in comprehensive air pollution control solutions, offering state-of-the-art equipment and expert services. Our products include advanced filtration systems and emission control devices, tailored to meet stringent environmental standards. Partner with us for innovative solutions that ensure cleaner air and a healthier future.</p>
                        </div>

                        <div className="row align-items-center wow fadeInLeftBig" data-wow-delay=".1s" style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeInLeftBig" }}>
                            <div className="col-lg-3 col-md-12">
                                <div className="ww-sec-inner text-center">
                                    <div className="mb-3 ww-sec-icon" style={{ "width": "auto", "backgroundColor": "#cc0017", "borderRadius": "4px", "padding": "25px", "display": "inline-block" }}>
                                        <img src="/images/product.png" alt="" className='img-fluid' />
                                    </div>
                                    <h4 className="mb-0">Products</h4>

                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="ww-inner-card">
                                    <div className="mb-0">
                                        <img src="/images/esp.jpg" className="img-fluid w-100" alt="" />

                                    </div>
                                    <h6>Air Pollution Control Systems</h6>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="ww-inner-card">
                                    <div className="mb-0">
                                        <img src="/images/conv.jpg" className="img-fluid w-100" alt="" />

                                    </div>
                                    <h6>Material Handling Systems</h6>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="ww-inner-card">
                                    <div className="mb-0">
                                        <img src="/images/comp.jpg" className="img-fluid w-100" alt="" />
                                    </div>
                                    <h6>Process Equipments</h6>
                                </div>
                            </div>

                        </div>

                        <hr className="my-5" />

                        <div data-wow-delay=".1s" style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeInLeftBig" }} className="row align-items-center wow fadeInRightBig">
                            <div className="col-lg-3 col-md-12">
                                <div className="ww-sec-inner text-center">
                                    <div className="mb-3 ww-sec-icon bg-blue" style={{ "width": "auto", "borderRadius": "4px", "padding": "25px", "display": "inline-block" }}>
                                        <img src="/images/repair.png" alt="" className='img-fluid' />
                                    </div>
                                    <h4 className="mb-0">Services</h4>

                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="ww-inner-card">
                                    <div className="mb-0">
                                        <img src="/images/test-1.jpg" className="img-fluid w-100" alt="" />
                                    </div>
                                    <h6>Inspection & Testing Services</h6>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="ww-inner-card">
                                    <div className="mb-0">
                                        <img src="/images/test-2.jpg" className="img-fluid w-100" alt="" />
                                    </div>
                                    <h6>Project Management...</h6>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="ww-inner-card">
                                    <div className="mb-0">
                                        <img src="/images/test-3.jpg" className="img-fluid w-100" alt="" />
                                    </div>
                                    <h6>Spares and Support</h6>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <style>
                {`
                .ww-inner-card{
                        height:100%;
                        }
                .ww-sec-inner img {
                    width: 38px;
                }
                .ww-inner-card h6 {
                    font-size: 16px;
                    padding: 10px;
                    background-color: #ffffff;
                    text-align: center;
                }
                .ww-sec-inner {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                }
                @media (max-width: 991px) {
                    .ww-sec-inner {
                        flex-direction: row;
                         margin-bottom:14px;
                    }
                    .ww-sec-icon {
                        margin-right: 20px;
                        padding: 20px;
                    }
                        .about-bigbox {
                        padding: 50px !important;
                    }
                }
                 @media (max-width: 767px) {
                        .ww-inner-card{
                        margin-bottom:20px;
                        }
                }
                @media (max-width: 575px) {
                    .about-bigbox {
                        padding: 30px 15px !important;
                    }
                    .top-cont p {
                        font-size: 18px !important;
                    }
                    .ww-sec-icon {
                        margin-right: 12px !important;
                        padding: 12px !important;
                    }

                }



                `}
            </style>
        </>
    )
}

export default WhatWeDoSection;