import React, { useState, useEffect } from 'react';

const ScrolltoTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled up to 300px
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <>
            <div className="scroll-to-top">
                {isVisible && (
                    <button onClick={scrollToTop} className="scroll-to-top-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" stroke="#000000" stroke-width="0.7" class="bi bi-arrow-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                        </svg>


                    </button>
                )}
            </div>
            <style>
                {`
           .scroll-to-top {
                position: fixed;
                bottom: 35px;
                right: 30px;
                z-index: 1000;
            }

            .scroll-to-top-btn {
                background-color: #fff;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                color: #000;
                font-weight:600;
                border: none;
                padding: 9px 13px;
                border-radius: 50%;
                cursor: pointer;
                font-size: 15px;
            }
             
            

           
           `}
            </style>
        </>
    )
}

export default ScrolltoTop;