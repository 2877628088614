import React, { useEffect } from 'react';
import { SFESPHero } from '../../../common/Components/HeroSection';
import { EspSpareCta } from '../../../common/Components/CtaSection';
import { EspSparesQUickLinks } from '../../../common/Components/QuickLinksSection';
import { EspSparesTable } from '../../../common/Components/TablesProduct';
import { EspSparesSS } from '../../../common/Components/ServiceSecondSec';
import { ESPSpareTabs } from '../../../common/Components/TabsSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from '../../../common/Meta/EspSpares';

const EspSpare = () => {
  useEffect(() => {
    document.title = "Unicon | ESP Spares for Reliable System Performance";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
      <Meta/>
      <SFESPHero />
      <EspSparesSS />
      <ESPSpareTabs />
      <EspSparesTable />
      <EspSpareCta />
      <EspSparesQUickLinks />
    </>
  )
}

export default EspSpare;