import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Drag Chain Conveyors for Efficient Material Handling"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Drag Chain Conveyors for Efficient Material Handling </title>
      <meta name="title" content="Unicon Drag Chain Conveyors for Efficient Material Handling"/>
<meta name="description" content="Unicon's drag chain conveyors offer robust, efficient material handling, ensuring smooth operation and durability in various industrial environments."/>
<meta name="keywords" content="Efficient Drag Chain Conveyors"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Drag Chain Conveyors for Efficient Material Handling"/>
<meta property="og:site_name" content="Unicon Drag Chain Conveyors"/>
<meta property="og:url" content="https://www.uniconinter.com/drag-chain-conveyor"/>
<meta property="og:description" content="Unicon's drag chain conveyors offer robust, efficient material handling, ensuring smooth operation and durability in various industrial environments."/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/products/drag-chain-conveyor/dcc-main.jpg"/>

    </Helmet>
  );
};

export default Meta;
