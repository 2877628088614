import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Industrial Silencers for Noise Control"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Industrial Silencers for Noise Control</title>
      <meta
        name="title"
        content="Unicon Industrial Silencers for Noise Control"
      />
      <meta
        name="description"
        content="Unicon's industrial silencers provide effective noise reduction solutions, ensuring quieter operations and enhanced efficiency in various industrial settings."
      />
      <meta name="keywords" content="Industrial Silencers" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />

      <meta
        property="og:title"
        content="Unicon Industrial Silencers for Noise Control"
      />
      <meta property="og:site_name" content="Unicon Silencers" />
      <meta property="og:url" content="https://www.uniconinter.com/silencers" />
      <meta
        property="og:description"
        content="Unicon's industrial silencers provide effective noise reduction solutions, ensuring quieter operations and enhanced efficiency in various industrial settings."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/silencers/main-img.jpg"
      />
    </Helmet>
  );
};

export default Meta;
