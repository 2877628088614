import React from 'react';


const SectionThree = () => {
    return (
        <>
            
            <section className="nox-burners bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-5 g-3 align-items-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 p-relative">
                            <div className="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                <span className="section-sub-title d-block">Low-NOx Burners</span>
                                <h4 className="section-heading mb-4 ">Low-NOx Burners and OFA Systems: Customized Solutions for Cleaner Combustion</h4>


                            </div>

                            <div className="d-lg-block d-sm-block d-md-flex gap-5 gap-md-2 align-items-start">
                               
                                <div className="choose-area-icon-box mb-0 mb-md-5 align-items-md-start align-items-center wow fadeInRight mt-lg-5 mt-0" data-wow-delay=".5s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                    <div className="icon-box p-relative">
                                       <img src="/images/products/low-nox-burners/roof.png" alt="" className='img-fluid roof' style={{width:'50px'}}/>
                                       <img src="/images/products/low-nox-burners/roof-white.png" alt="" className='img-fluid r-light' style={{width:'50px'}}/>
                                    </div>
                                    <div className="content">
                                        <h6 className="section-sub_heading">Custom-Designed Burners</h6>
                                        <p className="section-paragraph">Our burners are meticulously crafted to match the specific requirements of each application.</p>
                                    </div>
                                </div>
                                <hr/>
                                  
                                    <div className="choose-area-icon-box align-items-md-start align-items-center mt-0 mt-lg-5 wow fadeInRight" data-wow-delay=".7s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                        <div className="icon-box p-relative">
                                            <img src="/images/products/low-nox-burners/safe.png" alt="" className='img-fluid safe' style={{width:'50px'}}/>
                                            <img src="/images/products/low-nox-burners/safe-white.png" alt="" className='img-fluid s-light' style={{width:'50px'}}/>
                                        </div>
                                        <div className="content">
                                            <h6 className="section-sub_heading">NOx Reduction Guarantee</h6>
                                            <p className="section-paragraph">We promise substantial reductions in NOx emissions, contributing to cleaner air.</p>
                                        </div>
                                    </div>
                                    
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 wow fadeInLeft" data-wow-delay=".4s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                            <figure className="image m-img text-center vertical-align-center mt-5">
                                <img src="/images/products/low-nox-burners/burner-float.png" alt="" className='img-fluid' style={{"objectFit":"cover","width":"100%"}}/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`
                .choose-area-icon-box .icon-box {
                    background: #fff;
                    padding: 25px;
                    display: inline-block;
                    transition: all 1000ms ease;
                }
                .choose-area-icon-box {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    gap: 20px;
                    
                }
                .choose-area-icon-box:hover .icon-box:before {
                    transform: scale(1, 1);
                }

                
                .choose-area-icon-box:hover .icon-box{
                    color: #fff;
                     background: #cc0017;
                }
                .s-light, .r-light{
                display:none;
                }
                .choose-area-icon-box:hover .roof, .choose-area-icon-box:hover .safe{
                   display:none;
                }
                .choose-area-icon-box:hover .r-light, .choose-area-icon-box:hover .s-light{
                   display:block;
                       position: relative;
                        z-index: 111;
                    }
                

                @media(max-width:575px){
                
                    .choose-area-icon-box {
                        display: block;
                        
                    }
                    .choose-area-icon-box .icon-box {
                        padding:18px;
                        margin-bottom:12px;
                }
                }
                `}
            </style>
        </>
    )
}

export default SectionThree;