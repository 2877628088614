import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Cement Plant Spares for Optimal Performance"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Cement Plant Spares for Optimal Performance</title>
      <meta
        name="title"
        content="Unicon Cement Plant Spares for Optimal Performance"
      />
      <meta
        name="description"
        content="Unicon offers high-quality spares for cement plants, ensuring reliable operation, reduced downtime, and enhanced efficiency in critical processes"
      />
      <meta name="keywords" content="Best Cement Plant Spares" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Baghouse Spares for Efficient Dust Collection"
      />
      <meta property="og:site_name" content="Unicon Spares for Cement Plant" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/spares-of-cement-plant"
      />
      <meta
        property="og:description"
        content="Unicon offers high-quality spares for cement plants, ensuring reliable operation, reduced downtime, and enhanced efficiency in critical processes"
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/services/cm-spares/tabs-images/ks-1.jpg"
      />
    </Helmet>
  );
};

export default Meta;
