import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Agitators for Efficient Mixing Solutions"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Agitators for Efficient Mixing Solutions</title>
      <meta name="title" content="Unicon Agitators for Efficient Mixing Solutions"/>
<meta name="description" content="Unicon's agitators ensure optimal mixing and blending performance, designed for reliability and efficiency across various industrial applications."/>
<meta name="keywords" content="Efficient Agitators Mixing Solutions"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Agitators for Efficient Mixing Solutions"/>
<meta property="og:site_name" content="Unicon Agitators"/>
<meta property="og:url" content="https://www.uniconinter.com/agitators"/>
<meta property="og:description" content="Unicon's agitators ensure optimal mixing and blending performance, designed for reliability and efficiency across various industrial applications."/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/products/agitators/agi-main.jpg"/>

    </Helmet>
  );
};

export default Meta;
