import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          window.scrollTo({
            top: element.getBoundingClientRect().top + window.scrollY - 140,
            behavior: "smooth",
          });
          // Clean the URL by removing the hash
          window.history.replaceState(null, null, pathname);
        }
      }, 0); // Delay to ensure DOM updates
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }, [pathname, hash]);

  return null;
};

export default ScrollToTop;
