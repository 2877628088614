import React from 'react';

const SectionFour = () => {
  return (
    <>
     <section className="cust-padding half-container position-relative">
        <div className="container">
            <div className="row align-items-center ">
                <div className="col-lg-7 col-10 wow fadeInLeftBig" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0s","animationName":"fadeInLeftBig"}}>

                    <div className="design-feat" style={{"position":"relative","padding":"10px","backgroundColor":"#fff","zIndex":"10"}}>
                        <img src="/images/design-feat.jpg" className="img-fluid" alt="" />
                    </div>

                </div>

                <div className="col-lg-5 wow fadeInRightBig" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0s","animationName":"fadeInLeftBig"}}>
                    <div className="range-sec mt-lg-0 mt-5">
                        <h4 className="section-heading mb-sm-4 mb-3 ">Special features of <br className="d-lg-block d-none" />Unicon Design</h4>
                        <ul className="spl-feat-list">
                            <li>
                                Very compact to accommodate any space constraints
                            </li>
                            <li>
                                Less weight
                            </li>
                            <li>
                                Competitive price
                            </li>
                            <li>
                                Special designs to suite customer requirements
                            </li>
                            <li>
                                Cost effective solutions to meet customer needs
                            </li>
                            <li>
                                Very less wear and tear
                            </li>
                            <li>
                                Optimum power consumption
                            </li>
                            <li>
                                Various type of designs to match different applications and to maintain competitiveness
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <style>
      {`
      .half-container:before {
          content: '';
          position: absolute;
          width: 70%;
          height: 100%;
          right: 0px;
          top: 0px;
          background-color: #ededff;
          z-index:-1;
      }
      .range-sec {
          position: relative;
          padding-left: 50px;
      }
      @media (max-width: 991px) {
        .half-container:before {
            width: 100%;
            height: 70%;
            top: 30%;
        }
        .range-sec {
            padding-left: 0;
        }
        .range-sec .section-heading {
            text-align: center;
        }
      }
      @media (max-width: 575px) {
        .half-container:before {
            width: 100%;
            height: 80%;
            top: 20%;
        }
      }
         @media (max-width: 400px) {
        .half-container:before {
            width: 100%;
            height: 100%;
        }
      }
      `}
    </style>
    </>
  )
}

export default SectionFour