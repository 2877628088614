import React from 'react';
import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';

import './App.css';
import 'animate.css/animate.min.css';
import '../src/assets/css/animate.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from './components/common/Header/Header';
import Footer from './components/common/Footer/Footer';
import GlobeScrollPageTop from './components/common/Components/GlobeScrollPageTop';
import Home from './components/pages/Home/Home';
import Profile from './components/pages/Profile/Profile';
import Products from './components/pages/Products/index';
import Services from './components/pages/Services/index';
import Design from './components/pages/Design/Design';

// Products
import LowNox from './components/pages/Products/LowNoxBurners/LowNox';
import ElectrostaticPrecipitator from './components/pages/Products/ElectrostaticPrecipitator/ElectrostaticPrecipitator';
import BagHouse from './components/pages/Products/BagHouse/BagHouse';
import FlueGasSystem from './components/pages/Products/FlueGasSystem/FlueGasSystem';
import AmmoniaFluGas from './components/pages/Products/AmmoniaFluGas/AmmoniaFluGas';
import MistSeparator from './components/pages/Products/MistSeparator/MistSeparator';
import PneumaticConvey from './components/pages/Products/PneumaticConveying/PneumaticConvey';
import Rotary from './components/pages/Products/Rotary/Rotary';
import SlideGate from './components/pages/Products/SlideGate/SlideGate';
import DoubleGateValve from './components/pages/Products/DoubleConeValve/index';
import DoubleFlapValve from './components/pages/Products/DoubleFlapValve/index';
import ClamShellGate from './components/pages/Products/ClamShellGate/index';
import DomeValve from './components/pages/Products/DomeValve/index';
import DragChain from './components/pages/Products/DragChain/index';
import BeltConveyor from './components/pages/Products/BeltConveyor/index';
import ScrewConveyor from './components/pages/Products/ScrewConveyor/index';
import JacketedVessels from './components/pages/Products/JacketedVessels/index';
import PressureVessels from './components/pages/Products/PressureVessels/index';
import Decomposers from './components/pages/Products/Decomposers/index';
import Silencers from './components/pages/Products/Silencers/index';
import DefenceEquipments from './components/pages/Products/DefenceEquipments/index';
import StorageTank from './components/pages/Products/StorageTank/index';
import Agitators from './components/pages/Products/Agitators/index';
import Cfd from './components/pages/Services/Cfd/index';
import GdTesting from './components/pages/Services/GdTesting/index';
import EmissionTesting from './components/pages/Services/EmissionTesting/index';
import Supervisory from './components/pages/Services/Supervisory/index';
import Erection from './components/pages/Services/Erection/index';
import Retrofits from './components/pages/Services/Retrofits/index';
import Upgrades from './components/pages/Services/Upgrades/index';
import Residual from './components/pages/Services/Residual/index';
import Shutdown from './components/pages/Services/Shutdown/index';
import Annual from './components/pages/Services/Annual/index';
import EspSpares from './components/pages/Services/EspSpares/index';
import BagHouseSpares from './components/pages/Services/BagHouseSpares/index';
import CementPlant from './components/pages/Services/CementPlant/index';

// Catelogue
import Catelogues from './components/pages/Catelogue/index';
import Questionnaires from './components/pages/Questionnaires/index';

// job
import Job from './components/pages/Job/index';

// connect to us
import Connect from './components/pages/ConnetUs/index';

// Get Quote
import GetQuote from './components/pages/GetQuote/index';

// Thank you
import Thankyou from './components/pages/Thankyou/index';

// privacy
import Privacy from './components/common/Privacy/PrivacyPolicy';
import Terms from './components/common/Privacy/TermsandCondition';

// Error
import Error from './components/common/Errorpage';


function App() {

  return (

        <>
       
      <BrowserRouter>
        <GlobeScrollPageTop />
        <Header />
        <Routes>

          {/* Define all your existing routes */}
          <Route exact path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/design" element={<Design />} />
          <Route path="/products" element={<Products />} />

          {/* Product inner pages */}
          <Route path="/low-nox-burners" element={<LowNox />} />
          <Route path="/electrostatic-precipitator" element={<ElectrostaticPrecipitator />} />
          <Route path="/bag-house" element={<BagHouse />} />
          <Route path="/flue-gas-conditioning-system" element={<FlueGasSystem />} />
          <Route path="/afgc" element={<AmmoniaFluGas />} />
          <Route path="/mist-separators" element={<MistSeparator />} />

          <Route path="/pneumatic-conveying-system" element={<PneumaticConvey />} />
          <Route path="/rotary-airlock-valve" element={<Rotary />} />
          <Route path="/slide-gate" element={<SlideGate />} />
          <Route path="/double-cone-valve" element={<DoubleGateValve />} />
          <Route path="/double-flap-valve" element={<DoubleFlapValve />} />
          <Route path="/clam-shell-gate" element={<ClamShellGate />} />
          <Route path="/dome-valve" element={<DomeValve />} />
          <Route path="/drag-chain-conveyor" element={<DragChain />} />
          <Route path="/belt-conveyor" element={<BeltConveyor />} />
          <Route path="/screw-conveyor" element={<ScrewConveyor />} />
          <Route path="/silencers" element={<Silencers />} />
          <Route path="/defence" element={<DefenceEquipments />} />
          <Route path="/storage-tank" element={<StorageTank />} />
          <Route path="/agitators" element={<Agitators />} />
          <Route path="/decomposers" element={<Decomposers />} />
          <Route path="/pressure-vessels" element={<PressureVessels />} />
          <Route path="/jacketed-vessels" element={<JacketedVessels />} />

          {/* Services */}
          <Route path="/services" element={<Services />} />

          {/* Service Inner pages */}
          <Route path="/cfd" element={<Cfd />} />
          <Route path="/gd-testing" element={<GdTesting />} />
          <Route path="/emission-testing" element={<EmissionTesting />} />
          <Route path="/supervisory-services" element={<Supervisory />} />
          <Route path="/erection-commissioning" element={<Erection />} />
          <Route path="/retrofits" element={<Retrofits />} />
          <Route path="/upgrades" element={<Upgrades />} />
          <Route path="/residual-life-assessment" element={<Residual />} />
          <Route path="/shut-down-maintenance" element={<Shutdown />} />
          <Route path="/annual-maintenance" element={<Annual />} />
          <Route path="/spares-for-esp" element={<EspSpares />} />
          <Route path="/spares-for-baghouse" element={<BagHouseSpares />} />
          <Route path="/spares-of-cement-plant" element={<CementPlant />} />

          {/* Catelogue */}
          <Route path='/catalogues' element={<Catelogues />} />
          <Route path='/questionnaire' element={<Questionnaires />} />

          {/* job */}
          <Route path='/job' element={<Job />} />

          {/* Connect to us */}
          <Route path='/contact' element={<Connect />} />

          {/* Get Quote */}
          <Route path='/get-a-quote' element={<GetQuote />} />

          {/* thank you page */}
          <Route path='/thank-you' element={<Thankyou />} />

          {/* privacy page */}
          <Route path='/privacy-policy' element={<Privacy />} />

          <Route path='/terms-and-conditions' element={<Terms />} />
       

          {/* error page */}
          <Route path="*" element={<Error />} />


          {/* Redirections for broken links */}
          <Route path="/filter-bag" element={<Navigate to="/bag-house" />} />
          <Route path="/enquiry-gd-testing" element={<Navigate to="/gd-testing" />} />
          <Route path="/equipments-for-process-industries" element={<Navigate to="/products" />} />
          <Route path="/immersion-pipe" element={<Navigate to="/products" />} />
          <Route path="/wearing-liner-lifting-plates" element={<Navigate to="/products" />} />
          <Route path="/enquiry-material-handling" element={<Navigate to="/products" />} />
          <Route path="/kiln-shells" element={<Navigate to="/products" />} />
          <Route path="/plain-bearings" element={<Navigate to="/products" />} />
          <Route path="/ducting" element={<Navigate to="/products" />} />
          <Route path="/emitting-discharge-electrodes" element={<Navigate to="/emission-testing" />} />
          <Route path="/enquiry-for-rotary-airlock-valve" element={<Navigate to="/rotary-airlock-valve" />} />
          <Route path="/expansion-joint" element={<Navigate to="/products" />} />
          <Route path="/enquiry-for-dome-valve/" element={<Navigate to="/dome-valve" />} />
          <Route path="/esp-enquiry" element={<Navigate to="/spares-for-esp" />} />         
          <Route path="/ducting" element={<Navigate to="/products" />} />
          <Route path="/enquiry-cfd" element={<Navigate to="/cfd" />} />
          <Route path="/ducting" element={<Navigate to="/products" />} />
          <Route path="/hammers-outer-arm" element={<Navigate to="/products" />} />
          <Route path="/enquiry-pcs" element={<Navigate to="/pneumatic-conveying-system" />} />
          <Route path="/clinker-cooler-spares" element={<Navigate to="/products" />} />
          <Route path="/bucket-carriage" element={<Navigate to="/products" />} />
          <Route path="/inner-arm" element={<Navigate to="/products" />} />
          <Route path="/insulators" element={<Navigate to="/products" />} />
          <Route path="/enquiry-for-esp-upgrade" element={<Navigate to="/upgrades" />} />
          <Route path="/jacketted-vessels" element={<Navigate to="/jacketed-vessels" />} />
          <Route path="/clam-shell-gate-2" element={<Navigate to="/clam-shell-gate" />} />
          <Route path="/suspensions" element={<Navigate to="/products" />} />
          <Route path="/bag-cage" element={<Navigate to="/bag-house" />} />
          <Route path="/enquiry-rla" element={<Navigate to="/residual-life-assessment" />} />
          <Route path="/pin-gates" element={<Navigate to="/spares-of-cement-plant" />} />
          <Route path="/enquiry-afgc" element={<Navigate to="/afgc" />} />          
          <Route path="/tube-sheets" element={<Navigate to="/products" />} />
          <Route path="/enquiry-erection" element={<Navigate to="/erection-commissioning" />} />
          <Route path="/?mdocs-file=45476" element={<Navigate to="/catalogues" />} />
          <Route path="/venturie" element={<Navigate to="/products" />} />
          <Route path="/enquiry-bag-filter" element={<Navigate to="/bag-house" />} />
          <Route path="/bucket-elevator" element={<Navigate to="/products" />} />          
          <Route path="/enquiry-retrofitting" element={<Navigate to="/retrofits" />} />
          <Route path="/heaters" element={<Navigate to="/products" />} />
          <Route path="/purging-pipe" element={<Navigate to="/products" />} />
          <Route path="/collecting-electrodes" element={<Navigate to="/products" />} />
          <Route path="/enquiry-for-clam-shell-gate" element={<Navigate to="/clam-shell-gate" />} />
          <Route path="/enquiry-esp-spares" element={<Navigate to="/spares-for-esp" />} />
          <Route path="/solenoid-valve" element={<Navigate to="/products" />} />
          <Route path="/?mdocs-file=45100" element={<Navigate to="/catalogues" />} />
          <Route path="/?mdocs-file=45106" element={<Navigate to="/catalogues" />} />
          <Route path="/?mdocs-file=45104" element={<Navigate to="/catalogues" />} />
          <Route path="/?mdocs-file=45092" element={<Navigate to="/catalogues" />} />

        </Routes>
        <Footer />
      </BrowserRouter>
   

    </>
  );
}

export default App;
