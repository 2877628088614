import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// ProductCard Component
const ProductCard = ({ id, imgSrc, title, selected, onSelect }) => {
  return (
    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
      <label htmlFor={`radio-card-${id}`} className="radio-card">
        <input
          type="radio"
          name="radio-card"
          id={`radio-card-${id}`}
          checked={selected}
          onChange={() => onSelect(id)}
        />
        <div className="card-content-wrapper h-100">
          <span className="check-icon"></span>
          <div className="card-content">
            <img
              src={imgSrc}
              alt={title}
              style={{
                marginBottom: "10px",
                width: "100%",
                borderRadius: "4px",
              }}
            />
            <h4
              style={{
                fontSize: "16px",
                letterSpacing: "-0.24px",
                textAlign: "center",
                color: "#1f2949",
              }}
            >
              {title}
            </h4>
          </div>
        </div>
      </label>
    </div>
  );
};

const products = [
  {
    id: 1,
    imgSrc: "/images/quote-prod/esp.jpg",
    title: "Electrostatic Precipitator (ESP)",
  },
  { id: 2, imgSrc: "/images/quote-prod/bag-filter.jpg", title: "Bag Filter" },
  {
    id: 3,
    imgSrc: "/images/quote-prod/ammonia-flue.jpg",
    title: "Ammonia Flue Gas Conditioning",
  },
  {
    id: 4,
    imgSrc: "/images/quote-prod/pneumatic-conveying.jpg",
    title: "Pneumatic Conveying Systems",
  },
  {
    id: 5,
    imgSrc: "/images/quote-prod/material-handling.jpg",
    title: "Material Handling",
  },
  {
    id: 6,
    imgSrc: "/images/quote-prod/cfd.jpg",
    title: "Computational Fluid Dynamics (CFD)",
  },
  { id: 7, imgSrc: "/images/quote-prod/gd-testing.jpg", title: "GD Testing" },
  {
    id: 8,
    imgSrc: "/images/quote-prod/erection-commissioning.jpg",
    title: "Erection & Commissioning",
  },
  { id: 9, imgSrc: "/images/quote-prod/retrofits.jpg", title: "Retrofits" },
  {
    id: 10,
    imgSrc: "/images/quote-prod/rla.jpg",
    title: "Residual Life Assessment",
  },
  {
    id: 11,
    imgSrc: "/images/quote-prod/spares-esp.jpg",
    title: "Spares for ESP",
  },
];

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .test(
      "no-first-space",
      "First letter should not be a space",
      (value) => value && !/^ /.test(value)
    )
    .test(
      "only-one-space-between",
      "Only one space allowed",
      (value) => value && !/ {2,}/.test(value)
    )
    .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
    .min(3, "Name must be at least 3 characters long")
    .max(50, "Name must be not more than 50 characters long"),
  designation: Yup.string()
    .required("Designation & company name is required")
    .test(
      "no-first-space",
      "First letter should not be a space",
      (value) => value && !/^ /.test(value)
    )
    .test(
      "only-one-space-between",
      "Only one space allowed between words",
      (value) => value && !/ {2,}/.test(value)
    )
    .min(3, " Designation & company must be at least 3 characters long")
    .max(50, "Maximum 50 characters allowed"),
  phone: Yup.string()
    .required("Phone number is required")
    .min(9, "Please enter a valid phone number"),
    email: Yup.string()
    .email("Please enter a valid email address")
    .test(
      "no-com.co",
      "Please enter a valid email address",
      (value) => !/\.com\.co$/.test(value)
    )
    .test(
      "no-single-letter-after-com",
      "Please enter a valid email address",
      (value) => !/\.com\.[a-zA-Z]$/.test(value)
    )
    .test(
      "no-spaces",
      "Please enter a valid email address",
      (value) => !/\s/.test(value)
    )
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address"
    )
    .test(
      "only-one-dot-com",
      "Please enter a valid email address",
      (value) => (value.match(/\.com/g) || []).length <= 1
    )
    .test(
      "no-repeated-domain",
      "Please enter a valid email address",
      (value) => {
        const domainExtensions = [".com", ".net", ".in", ".co"];
        const matches = value.match(/\.\w+/g);
        if (matches) {
          const uniqueExtensions = new Set(
            matches.map((match) => match.toLowerCase())
          );
          return uniqueExtensions.size === matches.length;
        }
        return true;
      }
    )
    .test(
      "laxEmail_new",
      "Please enter a valid email address.",
      function (value) {
        if (value) {
          if (
            value.indexOf("@") > 0 &&
            value.indexOf("@") === value.lastIndexOf("@")
          ) {
            const [localPart, domain] = value.split("@");
            const firstChar = localPart[0];
            const lastChar = localPart.charAt(localPart.length - 1);
            const afterAt = domain[0];

            if (
              firstChar === "." ||
              lastChar === "." ||
              /^[A-Za-z]$/.test(afterAt) === false
            ) {
              return false;
            }

            const domainParts = domain.split(".");
            if (domainParts.length < 2) {
              return false;
            }

            const topLevelDomain = domainParts.pop();
            if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
              return false;
            }

            return domainParts.every((part) => part.length > 1);
          } else {
            return false;
          }
        }
        return true;
      }
    )
    .required("Email is required"),
  message: Yup.string()
    .required("Message is required") // Add required validation
    .test(
      "no-first-space",
      "First letter should not be a space",
      (value) => value && !/^ /.test(value)
    )
    .test(
      "only-one-space-between",
      "Only one space allowed between words",
      (value) => value && !/ {2,}/.test(value)
    )
    .min(3, "Message must be at least 3 characters")
    .max(500, "Message must be not more than 500 characters long"),
  file: Yup.array()
    .of(
      Yup.mixed().test("fileType", "Unsupported File Format", (value) => {
        if (!value) return true;
        const supportedFormats = [
          "application/pdf", // PDF
          "image/jpeg", // JPEG
          "image/jpg", // JPG
          "image/png", // PNG
          "application/msword", // Word (.doc)
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (.docx)
          "application/vnd.ms-excel", // Excel (.xls)
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (.xlsx)
          "application/vnd.oasis.opendocument.text", // OpenDocument Text (.odt)
          "application/vnd.oasis.opendocument.spreadsheet", // OpenDocument Spreadsheet (.ods)
        ];

        return supportedFormats.includes(value.type);
      })
    )
    .test("fileSize", "One or more files are too large", (files) => {
      if (!files || files.length === 0) return true; // Skip validation if no files are uploaded
      return files.every((file) => file.size <= 25 * 1024 * 1024);
    }),
});

const GetQuoate = () => {
  const [step, setStep] = useState(1);
  const [selectedProductId, setSelectedProductId] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loFormId, setLoFormId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState("");
  const fileInputRef = useRef(null);
  const dragDropTextRef = useRef(null);
  const uploadIconRef = useRef(null);
  const progressBarRef = useRef(null);
  const navigate = useNavigate();
  const [formId, setFormId] = useState(null);

  const [uploadStatus, setUploadStatus] = useState([]);

 
  const [phone, setPhone] = useState("");

  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    // Fetch the user's country based on their IP
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // ipapi returns the country code in ISO Alpha-2 format, which react-phone-input-2 expects
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      })
      .catch((error) => {
        setCountryCode("in");
       
        console.error("Error fetching IP information:", error);
        // Optionally set a default or handle the error
      });
  }, []);

  const steps = [
    { title: "Choose Category" },
    { title: "Request a Quote" },
    { title: "Upload Files" },
  ];

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleSelect = (id) => {
    setSelectedProductId(id);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      designation: "",
      phone: "",
      email: "",
      message: "",
      files: [], // Store multiple files
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (step === 1) {
        handleNextStep();
      } else if (step === 2) {
        handleStep1Submit(values);
      } else {
        // handleStep2Submit(values);
      }
    },
  });

  const handleStep1Submit = async (values) => {
    setIsSubmitting(true);

    // Basic validation for required fields
    if (
      !values.name ||
      !values.designation ||
      !values.phone ||
      !values.email ||
      !values.message
    ) {
      setIsSubmitting(false);
      return;
    }

    // Find the selected product
    const selectedProduct = products.find(
      (product) => product.id === selectedProductId
    );

    if (!selectedProduct) {
      setIsSubmitting(false);
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("designation", values.designation);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("message", values.message);
    formData.append("product_title", selectedProduct.title);
    formData.append("product_id", selectedProduct.id);

    try {
      if (!formId) {
        // Create new form submission
        const response = await axios.post(
          "backend/get_quotes.php",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        const newFormId = response.data; // Store formId from response
        localStorage.setItem("formId", newFormId);
        setFormId(newFormId);
        console.log("New form submitted, form ID:", newFormId);
        handleNextStep();
      } else {
        let formIds = localStorage.getItem("formId");
        formData.append("formId", formIds);
        // Update the existing form
        const response = await axios.post(
          "backend/update_quote.php",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        handleNextStep();
    
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert(`Error submitting form: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  // File Upload
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]); // Store all uploaded files

  const handleFileChange = async (e) => {
    if (e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  
    const files = Array.from(e.target.files); // Get all files from input
    let validFiles = [];
    let error = "";
    let duplicateFiles = [];
  
    const uploadedFileNames = fileList.map((file) => file.name); // List of already uploaded files
  
    // Iterate over all files selected by the user
    files.forEach((file) => {
      const isDuplicate = uploadedFileNames.includes(file.name); // Check if file already uploaded
  
      if (isDuplicate) {
        duplicateFiles.push(file.name); // Collect all duplicate file names
      } else if (file.name.endsWith(".zip") || file.name.endsWith(".exe")) {
        error = `Uploaded file format not allowed: .zip, .exe.`;
      } else if (file.size > 10 * 1024 * 1024) {
        error = "The uploaded file exceeds the 10 MB limit";
      } else {
        validFiles.push(file);
      }
    });
  
    // If duplicates are found, handle error
    if (duplicateFiles.length > 0) {
      if (duplicateFiles.length === 1) {
        error = `Uploaded file already exists`;
      } else {
        error = `Uploaded files already exist`;
      }
    }
  
    if (error) {
      setUploadError(error); // Show the error message
      e.target.value = ""; // Reset file input
      return;
    }
  
    setUploadError("");
    setIsLoading(true); // Start the loading indicator
  
    try {
      const formData = new FormData();
      validFiles.forEach((file) => formData.append("files[]", file)); // Add valid files to formData
      formData.append("formId", formId); // Include formId
  
      const response = await axios.post(
        "backend/fileupload.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted); // Update progress
          },
        }
      );
  
      // Process the response and map it to the uploaded files
      const uploadedFiles = validFiles.map((file, index) => ({
        name: file.name,
        size: file.size,
        used: response.data[index]?.used_mb,
        available: response.data[index]?.available_mb,
        status: response.data[index]?.status || 0,
        message: response.data[index]?.message || "Upload failed",
        file_id: response.data[index]?.file_id,
      }));
  
      const successfulFiles = uploadedFiles.filter((file) => file.status === 1);
      const fileExceedingLimit = uploadedFiles.find((file) => file.status === 3);
  
      // Update the list with successfully uploaded files
      setFileList((prevFiles) => [...prevFiles, ...successfulFiles]);
  
      // Show error if any file exceeds the limit
      if (fileExceedingLimit) {
        const errorMessage = `Total file size exceeds 10 MB. Used: ${fileExceedingLimit.used}, Available: ${fileExceedingLimit.available}`;
        setUploadError(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End the loading indicator
      e.target.value = ""; // Reset file input to ensure same file can be uploaded again
    }
  };
  
  
  const handleRemoveFile = async (index) => {
    const fileToRemove = fileList[index]; // Get the file object to remove
    const fileId = fileToRemove.file_id; // Ensure to extract the correct file ID
    setIsLoading(true);

    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append("fileid", fileId);
      formData.append("filename", fileToRemove.name);
      formData.append("formId", formId);

      const response = await axios.post(
        "backend/removefile.php",
        formData // Use FormData in the request
      );

      if (response.data.status === 1) {
        const updatedFiles = fileList.filter((_, i) => i !== index);
        setFileList(updatedFiles);
        // alert("File removed successfully.");
        setUploadError("");
      } else {
        console.log(
          "Error: " + response.data.message ||
            "There was an error removing the file. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      console.log(
        "There was an error removing the file. Please try again later."
      );
    } finally {
      setIsLoading(false); // End loader
    }
  };

  // Handle drop event (file drag & drop)
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    handleFileChange({ target: { files } });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (dragDropTextRef.current) {
      dragDropTextRef.current.innerHTML = "Drop your file here!";
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (dragDropTextRef.current) {
      dragDropTextRef.current.innerHTML = "Drag & drop any file here";
    }
  };

  const handleStep2Submit = async () => {
    const formData = new FormData();

    // Get formId from localStorage
    const formId = localStorage.getItem("formId");

    // Check if formId exists
    if (!formId) {
      console.error("Form ID is missing. Cannot submit the form.");
      setUploadError("Form ID is missing. Please start the process again.");
      return;
    }

    formData.append("formId", formId);

    setIsSubmitting(true); // Set submitting state before making the request

    try {
      const response = await axios.post(
        "backend/formsubmit.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // Check the response status
      if (response.data.status === "success") {
        localStorage.removeItem("formId");
        // Navigate to thank you page on successful submission
        navigate("/thank-you"); // Ensure this route is correct
      } else {
        // Handle error response from the server
        console.log(
          response.data.message || "Submission failed. Please try again."
        );
        setUploadError(
          response.data.message || "Submission failed. Please try again."
        );
      }
    } catch (error) {
      // Handle any errors during the request
      console.error("Error:", error);
      setUploadError(
        "There was an error uploading files. Please try again later."
      );
    } finally {
      // Always reset submitting state after handling the response
      setIsSubmitting(false);
    }
  };

  const handleNextStep = () => {
    if (step === 2) {
      setStep((prevStep) => prevStep + 1); // Move to the next step
    } else {
      setStep((prevStep) => prevStep + 1); // Move to the next step
    }
    window.scrollTo(0, 0);
  };

  const handlePrevStep = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="wizard-section cust-padding3">
        <div className="container">
          <div className="row">
            <h4 className="section-heading text-center">Get a Quote</h4>

            {/* Progress Indicator */}

            <div className="multisteps-form__progress d-flex justify-content-between flex-wrap mt-5 mb-0">
              {steps.map((stepItem, index) => (
                <div
                  key={index}
                  data-step={index + 1}
                  className={`multisteps-form__progress-btn ${
                    index + 1 === step ? "js-active" : ""
                  } ${
                    index + 1 < step || (index + 1 === 3 && fileList.length > 0)
                      ? "completed"
                      : ""
                  }`}
                  // onClick={() => setStep(index + 1)}
                >
                  <span
                    className={`step-title ${
                      index + 1 === step ? "active" : ""
                    }`}
                  >
                    {stepItem.title}
                  </span>
                </div>
              ))}
            </div>

            <div className="mt-5">
              <form
                id="getquoteform"
                className="contact-form"
                onSubmit={formik.handleSubmit}
              >
                {step === 1 && (
                  <>
                    <div className="wow fadeInUpBig" data-wow-delay="0.1s">
                      <h4 className="mb-3 form-guide">
                        Select your Product of Interest
                      </h4>
                      <div
                        className="multi-prod-card"
                        style={{
                          backgroundColor: "#f5f5f5",
                          padding: "15px",
                          borderRadius: "0.75rem",
                          height: "100%",
                        }}
                      >
                        <div className="scroll-sec">
                          <div className="row g-3">
                            {products.map((product) => (
                              <ProductCard
                                key={product.id}
                                id={product.id}
                                imgSrc={product.imgSrc}
                                title={product.title}
                                selected={selectedProductId === product.id}
                                onSelect={handleSelect}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="text-end">
                        <button
                          type="button"
                          className="primary-btn-1 mt-4"
                          onClick={handleNextStep}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {step === 2 && (
                  <div className="wow fadeInUpBig mt-3" data-wow-delay="0.4s">
                    <h4 className="mb-3 form-guide">
                      Complete the form to request your quote
                    </h4>
                    <div
                      className="form-wrppr h-100"
                      style={{
                        padding: "25px",
                        backgroundColor: "rgb(245, 245, 245",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="row g-2">
                        <div className="col-lg-3">
                          <div className="form-group mb-2">
                            <label>
                              Name <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Enter Name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onInput={handleNameInput}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <div className="error-msg text-danger">
                                {formik.errors.name}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group mb-2">
                            <label>
                              Designation & Company Name
                              <span className="text-red"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="designation"
                              placeholder="Enter Designation"
                              value={formik.values.designation}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.designation &&
                            formik.errors.designation ? (
                              <div className="error-msg text-danger">
                                {formik.errors.designation}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group mb-2">
                            <label>
                              Phone Number <span className="text-red">*</span>
                            </label>

                             <PhoneInput
                        country={countryCode}
                        inputStyle={{
                          width: "100%",
                          height: "38px",
                        }}
                        value={formik.values.phone}
                        onChange={(value) =>
                          formik.setFieldValue("phone", value)
                        }
                        onBlur={formik.handleBlur}
                       
                      />
                     

                            {formik.touched.phone && formik.errors.phone ? (
                              <div className="error-msg text-danger">
                                {formik.errors.phone}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group mb-2">
                            <label>
                              Email ID <span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Enter Email ID"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onKeyDown={handleKeyPress}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="error-msg text-danger">
                                {formik.errors.email}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group mb-2">
                            <label>
                              Message <span className="text-red">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              name="message"
                              rows="4"
                              placeholder="Enter Message"
                              value={formik.values.message}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            ></textarea>
                            {formik.touched.message && formik.errors.message ? (
                              <div className="error-msg text-danger">
                                {formik.errors.message}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="d-flex align-items-center gap-2 justify-content-end">
                          <button
                            type="button"
                            className=" py-2 px-3 bg-transparent border-0 fw-bold mt-4"
                            style={{ fontSize: "18px" }}
                            onClick={handlePrevStep}
                          >
                            Back
                          </button>
                          <button
                            type="submit"
                            className="primary-btn-1 mt-4"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Next..." : "Next"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {step === 3 && (
                  <>
                    <div className="col-12">
                      <h4
                        className="mb-3 form-guide"
                        // style={{ fontSize: "14px", fontWeight: "700" }}
                      >
                        If any file to be Upload (Max 10 MB)
                      </h4>
                      <div>
                        <div
                          className="upload-files-container"
                          style={{
                            backgroundColor: "rgb(245, 245, 245",
                            width: "100%",
                            padding: "15px 30px",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: "5px",
                            // border: "1px solid #dadada",
                          }}
                        >
                          <div
                            className="drag-file-area"
                            style={{
                              border: "1px dashed #afafaf",
                              borderRadius: "8px",
                              margin: "10px 0 15px",
                              padding: "24px",
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "#fff",
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onClick={() =>
                              fileInputRef.current &&
                              fileInputRef.current.click()
                            }
                          >
                            <span
                              className="upload-icon"
                              style={{ fontSize: "30px" }}
                            >
                              <i className="bi bi-cloud-arrow-up"></i>
                            </span>
                            <h3 className="dynamic-message">
                              Drag & drop any file here
                            </h3>
                            <p className="mb-0">or</p>
                            <label
                              className="label d-block"
                              style={{ fontSize: "16px" }}
                            >
                              <span className="browse-files">
                                <input
                                  type="file"
                                  className="form-control"
                                  name="file"
                                  ref={fileInputRef}
                                  onChange={handleFileChange}
                                  multiple // Allow multiple file selection
                                  style={{
                                    opacity: "0",
                                    position: "absolute",
                                    bottom: "0px",
                                    left: "0px",
                                    cursor: "pointer", // Ensures the file input itself is clickable
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Stop propagation to avoid triggering drag/drop area
                                  }}
                                />
                                <span
                                  className="browse-files-text"
                                  style={{
                                    color: "#052d83",
                                    fontWeight: "bolder",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Stop propagation to avoid triggering drag/drop area
                                  }}
                                >
                                  Browse file
                                </span>
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation(); // Stop propagation to avoid triggering drag/drop area
                                  }}
                                >
                                  {" "}
                                  from device
                                </span>
                              </span>
                            </label>
                          </div>

                          {progress > 0 && progress < 100 && (
                            <div
                              className="progress-bar"
                              style={{
                                width: `${progress}%`,
                                background: "#052d83",
                                height: "5px",
                              }}
                            />
                          )}
                          {uploadError && (
                            <div className="error-msg text-danger position-relative">
                              {uploadError}
                            </div>
                          )}

                          {formik.touched.file && formik.errors.file ? (
                            <div className="error-msg text-danger position-relative">
                              {formik.errors.file}
                            </div>
                          ) : null}

                          {fileList.length > 0 && (
                            <div
                              className="uploaded-files-details"
                              style={{ width: "100%", marginTop: "10px" }}
                            >
                              <h4>Successfully Uploaded Files:</h4>
                              {fileList.map(
                                (file, index) =>
                                  file.status === 1 && ( // Only show successfully uploaded files
                                    <div
                                      key={index}
                                      className="uploaded-file-details"
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        backgroundColor: "#e9f7ef", // Green background for successful uploads
                                        borderRadius: "5px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <div>
                                        <p
                                          style={{
                                            margin: "0",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {file.name}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0",
                                            fontSize: "12px",
                                            color: "#555",
                                          }}
                                        >
                                          {(file.size / 1024 / 1024).toFixed(2)}{" "}
                                          MB
                                        </p>
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() => handleRemoveFile(index)} // Placeholder for remove functionality
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          color: "#dc3545",
                                          cursor: "pointer",
                                          fontSize: "20px",
                                        }}
                                      >
                                        ×
                                      </button>
                                    </div>
                                  )
                              )}

                              {/* Show error for files that exceed the size limit */}
                              {/* {uploadError && (
                                <div className="error-msg text-danger position-relative">
                                  {uploadError} 
                                </div>
                              )} */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-4 d-flex justify-content-end align-items-center ">
                      <div className="">
                        <button
                          type="button"
                          className=" py-2 px-3 bg-transparent border-0 fw-bold"
                          style={{ fontSize: "18px" }}
                          onClick={handlePrevStep}
                        >
                          Back
                        </button>
                      </div>
                      <div className="">
                        <button
                          type="submit"
                          className="primary-btn-1 mt-0 btn-hover"
                          disabled={isSubmitting}
                          onClick={handleStep2Submit}
                        >
                          {isSubmitting ? (
                            <span>Submitting...</span> // Loading text
                          ) : (
                            <span>
                              Send Quote Request{" "}
                              <i className="icon-right-arrow"></i>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
      />
      <style>
        {`
        .scroll-sec {
            padding: 10px;
        }
        .multi-prod-card label {
            display: flex;
            width: 100%;
            height: 100%;
        }
        label.radio-card .card-content h4 {
            font-weight: 600 !important;
        }
        label.radio-card input[type=radio]:checked + .card-content-wrapper .check-icon {
            background: #052d83;
            border-color: #052d83;
            transform: scale(1.2);
            box-shadow: 0 0 0 .20rem rgba(13, 110, 253, .25);
            transition: all 0.3s ease;
        }
        label.radio-card .check-icon {
            width: 20px;
            height: 20px;
            display: inline-block;
            border: solid 2px #a5a5a5;
            background: #fff;
            border-radius: 50%;
            transition: 200ms linear;
            position: relative;
            position: absolute;
            right: 10px;
            top: 10px;
        }
        label.radio-card .card-content-wrapper {
            background: #fff;
            border-radius: 5px;
            max-width: 100%;
            min-height: 100% !important;
            padding: 15px;
            display: block;
            box-shadow: 0 2px 4px 0 rgb(219 215 215 / 0%), 0 0 0 2px #e7e7e700;
            transition: 200ms linear;
            position: relative;
            flex-grow: 1;
            cursor: pointer;
        }
        label.radio-card input[type=radio]:checked + .card-content-wrapper {
            box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #052d83;
        }
        label.radio-card input[type=radio]:checked + .card-content-wrapper .check-icon:before {
            transform: scale(1);
            opacity: 1;
        }
        label.radio-card .check-icon:before {
            content: "";
            position: absolute;
            inset: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: center center;
            transform: scale(1.6);
            transition: 200ms linear;
            opacity: 0;
        }
        label.radio-card input[type=radio] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
        .drag-file-area h3 {
            font-size: 20px !important;
            margin: 10px 0;
        }
        .error-msg{
          // position:absolute;
          font-size:14px;
          text-wrap:wrap;
        }
        @media(max-width:1200px){
            .scroll-sec {
                max-height: 445px;
                overflow-y: scroll;
                overflow-x: hidden;
            }
        }
        @media (max-width: 575px) {
            .form-guide {
                font-size: 18px;
            }
            .form-wrppr {
                padding: 20px;
            }
        }
       `}
      </style>

      <style jsx>
        {`
          .multisteps-form__progress {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 30px; /* Space below progress */
          }

          .multisteps-form__progress-btn {
            position: relative;
            color: rgba(108, 117, 125, 0.7);
            border: none;
            background-color: transparent;
            outline: none !important;
            cursor: pointer;
            font-size: 21px;
            font-weight: 600;
            display: flex;
            align-items: center;
            min-width: max-content;
            justify-content: center;
            margin-left: 32px;
            padding: 0px 12px;
            background-color: #fff; /* Default background */
            transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
          }

          .multisteps-form__progress-btn:first-child {
            margin-left: 0;
          }

          .multisteps-form__progress-btn:before {
            content: attr(data-step);
            width: 40px;
            height: 40px;
            font-weight: 700;
            margin-right: 15px;
            line-height: 36px;
            position: relative;
            text-align: center;
            display: inline-block;
            border: 2px solid #e3e3e3; /* Inactive border color */
            border-radius: 50%;
            box-sizing: border-box;
            z-index: 3;
            transition: border-color 0.3s ease; /* Smooth transition */
          }

          .multisteps-form__progress-btn:after {
            content: "";
            position: absolute;
            top: 20px; /* Position the line below the button */
            right: -485px; /* Adjusted positioning */
            transform: translateX(-50%); /* Center align the line */
            display: block;
            width: calc(100% + 86px); /* Full width minus the button width */
            height: 4px;
            background-color: rgba(108, 117, 125, 0.7); /* Default line color */
            z-index: -1;
            transition: background-color 0.3s ease; /* Smooth transition */
          }

          .multisteps-form__progress-btn:last-child:after {
            display: none; /* Hide the line for the last button */
          }
          .multisteps-form__progress-btn:last-child.completed:before {
            // content: "";
            color: #5e9723;
          }
          .multisteps-form__progress-btn:last-child.completed:after {
            color: #052d83;
          }
          .multisteps-form__progress-btn.js-active {
            color: #052d83;
          }

          .multisteps-form__progress-btn.js-active:before {
            border-color: #052d83;
            color: #052d83;
            content: attr(data-step);
            font-weight: 900;
          }

          .multisteps-form__progress-btn.completed {
            color: #5e9723;
          }

          .multisteps-form__progress-btn.completed:before {
            border-color: #5e9723;
            content: "✔";
            font-weight: 900;
          }

          .multisteps-form__progress-btn.completed:after {
            background-color: #5e9723; /* Line color for completed steps */
            // content: "✔"; /* Check mark for completed steps */
          }

          /* Responsive styles */
          @media (max-width: 992px) {
            .step-title {
              display: none;
            }
            .multisteps-form__progress-btn {
              margin-left: 16px; /* Adjust spacing for smaller screens */
            }

            .multisteps-form__progress-btn:after {
              width: calc(100% + 200px);
              right: -425px;
              top: 18px;
            }
            .multisteps-form__progress-btn.completed:after {
              background-color: #5e9723; /* Line color for completed steps */
              content: ""; /* Check mark for completed steps */
            }

            .multisteps-form__progress-btn:before {
              width: 40px;
              height: 40px;
              line-height: 40px;
            }
          }
          @media (max-width: 768px) {
            .multisteps-form__progress-btn {
              margin-left: 16px; /* Adjust spacing for smaller screens */
            }
            .step-title {
              display: none;
            }
            .multisteps-form__progress-btn {
              margin-left: 16px; /* Adjust spacing for smaller screens */
            }

            .multisteps-form__progress-btn:after {
              width: calc(100% + 200px);
              right: -425px;
              top: 18px;
            }
            .multisteps-form__progress-btn.completed:after {
              background-color: #5e9723; /* Line color for completed steps */
              content: ""; /* Check mark for completed steps */
            }
            .multisteps-form__progress-btn:before {
              width: 40px; /* Smaller circles on mobile */
              height: 40px; /* Smaller circles on mobile */
              line-height: 40px; /* Center text */
            }
          }

          @media (max-width: 665px) {
            .step-title {
              display: none;
            }
            .multisteps-form__progress-btn {
              margin-left: 16px; /* Adjust spacing for smaller screens */
            }

            .multisteps-form__progress-btn:after {
              width: calc(100% + 200px);
              right: -340px;
              top: 18px;
            }
            .multisteps-form__progress-btn.completed:after {
              background-color: #5e9723; /* Line color for completed steps */
              content: ""; /* Check mark for completed steps */
            }
            .multisteps-form__progress-btn {
              font-size: 18px; /* Smaller font size on very small screens */
            }
          }

          @media (max-width: 576px) {
            .step-title {
              display: none;
            }
            .multisteps-form__progress-btn {
              margin-left: 16px; /* Adjust spacing for smaller screens */
            }

            .multisteps-form__progress-btn:after {
              width: calc(100% + 200px);
              right: -340px;
              top: 18px;
            }
            .multisteps-form__progress-btn.completed:after {
              background-color: #5e9723; /* Line color for completed steps */
              content: ""; /* Check mark for completed steps */
            }
            .multisteps-form__progress-btn {
              font-size: 18px; /* Smaller font size on very small screens */
            }
          }
          @media (max-width: 425px) {
            .step-title {
              display: none;
            }
            .multisteps-form__progress-btn {
              margin-left: 16px; /* Adjust spacing for smaller screens */
            }

            .multisteps-form__progress-btn:after {
              width: calc(100% + 100px);
              right: -230px;
              top: 18px;
            }
            .multisteps-form__progress-btn.completed:after {
              background-color: #5e9723; /* Line color for completed steps */
              content: ""; /* Check mark for completed steps */
            }
            .multisteps-form__progress-btn {
              font-size: 18px; /* Smaller font size on very small screens */
              padding: 0p 5px;
            }
            .multisteps-form__progress-btn:before {
              margin-right: 5px;
            }
          }
          @media (max-width: 375px) {
            .step-title {
              display: none;
            }
            .multisteps-form__progress-btn {
              margin-left: 16px; /* Adjust spacing for smaller screens */
            }

            .multisteps-form__progress-btn:after {
              width: calc(100% + 70px);
              right: -160px;
              top: 18px;
            }
            .multisteps-form__progress-btn.completed:after {
              background-color: #5e9723; /* Line color for completed steps */
              content: ""; /* Check mark for completed steps */
            }
            .multisteps-form__progress-btn {
              font-size: 18px; /* Smaller font size on very small screens */
              padding: 0px 3px;
            }
            .multisteps-form__progress-btn:before {
              margin-right: 5px;
            }
          }

          .react-international-phone-country-selector-button {
            height: 38px;
          }
        `}
      </style>

      {/* Loader */}
      <style jsx>
        {`
          .loader {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            position: relative;
            animation: rotate 1s linear infinite;
          }
          .loader::before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            inset: 0px;
            border-radius: 50%;
            border: 5px solid #fff;
            animation: prixClipFix 2s linear infinite;
          }

          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes prixClipFix {
            0% {
              clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
            }
            25% {
              clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
            }
            50% {
              clip-path: polygon(
                50% 50%,
                0 0,
                100% 0,
                100% 100%,
                100% 100%,
                100% 100%
              );
            }
            75% {
              clip-path: polygon(
                50% 50%,
                0 0,
                100% 0,
                100% 100%,
                0 100%,
                0 100%
              );
            }
            100% {
              clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
            }
          }
        `}
      </style>
    </>
  );
};

export default GetQuoate;
