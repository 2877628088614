import React from 'react';

const CertificateSection = () => {
    return (
        <>
            <section className="certi-padding wow fadeInUpBig" data-wow-delay=".1s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUpBig" }}>
                <div className="container">
                    <div className='row justify-content-center'>
                        <div className="col-lg-8 col-12">

                        <div className="certi-box" style={{"backgroundColor": "#fff", "padding": "30px", "borderSpacing": "30px", "boxShadow": "rgba(100, 100, 111, 0.13) 0px 7px 29px 0px",  "display": "flex", "justifyContent": "center", "alignItems": "center", "gap": "40px", "borderRadius": "0.25rem"}}>
                        <div className="certi-box-head">
                            <h3 className="" ><i className="bi bi-patch-check text-purp" style={{color:'#0a2e81'}}></i> Accredited for Quality</h3>
                        </div>
                        <div className="certi-box-inner">
                            <div className="certi-imgs text-center wow fadeInUpBig" data-wow-delay=".1s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUpBig" }}>
                                <img src="/images/certi/certi-1.png" className="img-fluid mb-2" alt="" style={{width: "130px"}}/>
                                <p className='mb-0'>ISO 9001</p>
                            </div>

                            
                        </div>
                    </div>


                        </div>
                    </div>
                   
                </div>
            
        </section >

        <style>
            {`
            .certi-box-head h3 {
                font-weight: 400 !important;
                margin-right: 50px;
            }

            .certi-padding {
                padding: 70px 0 70px;
            }

            .certi-box-inner {
                display: flex;
                gap: 40px;
            }
            
            @media (max-width: 991px) {
            .certi-box {
                display: block !important;
            }
            .certi-box-head h3 {
                margin-bottom: 25px !important;
                margin-right: 0px;
                text-align: center;
            }
            .certi-imgs img {
                width: 100px !important;
            }
            .certi-box-inner {
                justify-content: center;
            }

            }

             @media (max-width: 767px) {
                .certi-imgs img {
                    width: 70px !important;
                }
                .certi-box-head h3 {
                    font-size: 30px !important;
                }

            }

            @media (max-width: 575px) {
                .certi-box {
                    padding: 16px !important;
                }
                .certi-imgs img {
                    width: 60px !important;
                }
                .certi-box-head h3 {
                    font-size: 20px !important;
                }
                .certi-imgs p {
                    font-size: 14px !important;
                }
                .certi-box-inner {
                    gap: 18px !important;
                    justify-content: space-evenly !important;
                    flex-wrap: wrap !important;
    
                }
 
            }

                   
                   
                   `}
        </style>

    </>
  )
}

export default CertificateSection;