import React, { useEffect } from "react";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import CtaProfile from "./CtaProfile";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../common/Meta/Profile";


const Profile = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    document.title = "Unicon Profile | Pioneers Environmental Protection Industry";
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
      <Meta />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <CtaProfile />
     
    </>
  );
};

export default Profile;
