import React, { useEffect } from 'react';
import { PcsHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { PcsAcc } from '../../../common/Components/AccordionSection';
import { PscCta } from '../../../common/Components/CtaSection';
import { PcsQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/PneumaticConvey";

const PneumaticConvey = () => {
  useEffect(() => {
    document.title = "Pneumatic Conveying Systems | Ash Handling System";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <PcsHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <PcsAcc/>
        <PscCta/>
        <PcsQUickLinks/>
    </>
  )
}

export default PneumaticConvey;