import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon Services – Comprehensive Industrial Solutions"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon Services – Comprehensive Industrial Solutions</title>
      <meta
        name="title"
        content="Unicon Services – Comprehensive Industrial Solutions"
      />
      <meta
        name="description"
        content="Explore Unicon's range of services, including installation, maintenance, retrofitting, and more, designed to ensure optimal performance and efficiency for your systems."
      />
      <meta name="keywords" content="Unicon Services" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Services – Comprehensive Industrial Solutions"
      />
      <meta property="og:site_name" content="Unicon Services" />
      <meta property="og:url" content="https://www.uniconinter.com/services" />
      <meta
        property="og:description"
        content="Explore Unicon's range of services, including installation, maintenance, retrofitting, and more, designed to ensure optimal performance and efficiency for your systems."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/products-list/video-thumb.jpg"
      />
    </Helmet>
  );
};

export default Meta;
