import React from 'react'

const ContactSection = () => {
    return (
        <>
            <section className="cust-padding wow fadeInDown" data-wow-delay=".1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInDown' }}>
                <div className="container">
                    <div className="abt-cta">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <h4 className="section-heading text-white mb-3">Reach Us @</h4>
                                    <div className="mail-style">
                                        <h1 className="text-white">
                                            <a href="mailto:hr@uniconinter.com">
                                                <img src="/images/mail-gif.gif" className="mail-gif" alt="Mail" />
                                                hr@uniconinter.com
                                            </a>
                                        </h1>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`
                .abt-cta {
                    background-color: #052d83;
                    padding: 50px;
                    border-radius: 20px;
                }
                    .mail-style {
                    display: flex;
                        justify-content: center;
                    }
                    .mail-style h1 {
                    font-size: 64px;
                    padding: 20px 40px;
                    background-color: #3a5ba5;
                    border-radius: 10px;
                    max-width: 100%;
                    width: fit-content;
                    width: -moz-fit-content;
                   
                }        
                 .mail-style h1 a{
                  text-decoration:none;
                  color:#fff;
                }
                .mail-gif {
                     width: 90px;
                }
                @media (max-width: 1200px) {
                    .mail-style h1 {
                        font-size: 56px;
                    }
                }
                @media (max-width: 767px) {
                    .mail-style h1 {
                        font-size: 32px;
                    }
                    .mail-gif {
                        width: 40px;
                    }
                }
                @media (max-width: 575px) {
                    .abt-cta {
                        padding: 25px;
                        border-radius: 10px;
                    }
                    .mail-style h1 {
                         padding: 10px 15px;
                         font-size: 22px;
                     }
                    .mail-gif {
                        width: 32px;
                    }
    
                }


                                `}
            </style>
        </>
    )
}

export default ContactSection;