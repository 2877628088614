import React, { useEffect } from 'react';
import { RLAHero } from '../../../common/Components/HeroSection';
import { ResidualSS } from '../../../common/Components/ServiceSecondSec';
import { RLAScroll } from '../../../common/Components/ScrollSec';
import { ResidualCta } from '../../../common/Components/CtaSection';
import { ResidualQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/Residual";

const Residual = () => {
  useEffect(() => {
    document.title = "Unicon | Residual Life Assessment for Asset Longevity";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <RLAHero/>
        <ResidualSS/>
        <RLAScroll/>
        <ResidualCta/>
        <ResidualQUickLinks/>
    </>
  )
}

export default Residual;