import React, { useEffect } from 'react';
import { DoubleConeHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { DoubleConeAcc } from '../../../common/Components/AccordionSection';
import { DoubleConeCta } from '../../../common/Components/CtaSection';
import { DoubleConeQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/DoubleConeValve";

const Index = () => {
  useEffect(() => {
    document.title = "Unicon | Double Cone Valves for Bulk Handling";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
    <Meta/>
    <DoubleConeHero/>
    <SectionTwoThree/>
    <SectionFour/>
    <DoubleConeAcc/>
    <DoubleConeCta/>
    <DoubleConeQUickLinks/>
    </>
  )
}

export default Index;