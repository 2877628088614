import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | ESP Spares for Reliable System Performance"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | ESP Spares for Reliable System Performance</title>
      <meta
        name="title"
        content="Unicon ESP Spares for Reliable System Performance"
      />
      <meta
        name="description"
        content="Unicon offers high-quality spare parts for electrostatic precipitators (ESP), ensuring efficient operation, reduced downtime, and extended equipment life"
      />
      <meta name="keywords" content="Best ESP Spares" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon ESP Spares for Reliable System Performance"
      />
      <meta property="og:site_name" content="Unicon Spares for ESP" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/spares-for-esp"
      />
      <meta
        property="og:description"
        content="Unicon offers high-quality spare parts for electrostatic precipitators (ESP), ensuring efficient operation, reduced downtime, and extended equipment life"
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/services/esp-spares/slide-1.jpg"
      />
    </Helmet>
  );
};

export default Meta;
