import React from 'react';
import { Link } from 'react-router-dom';

const SectionTwoThree = () => {
    return (
        <>
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center pe-lg-3">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/mist-separators-candle-filters/sec1.jpg" alt="" />

                            </div>
                        </div>
                        <div className="col-lg-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2"><span className="text-primary-red">Unicon's</span> Advanced Wet Chlorine Management Systems
                                </h4>
                                <p className="section-paragraph">Unicon recovers chlorine gas from anode cell output, using advanced heat exchangers to cool and manage submicron NaCl mist.</p>
                                <h5 class="mb-lg-2 mb-4 mt-4">Benefits of Unicon's Mist Removal Solutions:</h5>
                                <div className="d-flex gap-3 flex-wrap  mt-3  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Increased Product Purity </li>
                                        <li><i className="bi bi-check-circle"></i>Reduced Sulfuric Acid Use</li>
                                        <li><i className="bi bi-check-circle"></i>Lower Moisture Levels</li>

                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none  ps-0">
                                        <li><i className="bi bi-check-circle"></i>Optimized Production</li>
                                        <li><i className="bi bi-check-circle"></i>Lower Cooler Fouling Rates</li>
                                        <li><i className="bi bi-check-circle"></i>Minimized Sodium Sulfate Formation</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-lg-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInRight" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>

                                <h4 className="section-heading mb-2 pe-4 pe-md-0 w-md-75 w-100 mx-auto "><span className="text-primary-red">Unicon's</span> Dry Chlorine <br className='d-lg-block d-none'/>
                                Purity Solutions
                                </h4>
                                <p className="section-paragraph">Recovered chlorine gas moisture is removed with sulfuric acid in our drying tower. Unicon’s Mist Separator - Candle Filter efficiently captures the sub-micron mist.</p>

                                <h5 class="mb-lg-2 mb-4 mt-4">Benefits of Unicon's Mist Removal Solutions:</h5>
                                <div className="d-flex gap-3 flex-wrap  mt-3  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Extend System Life</li>
                                        <li><i className="bi bi-check-circle"></i>Improve Product Purity</li>
                                        <li><i className="bi bi-check-circle"></i>Boost Efficiency</li>

                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none  ps-0">
                                        <li><i className="bi bi-check-circle"></i>Avoid Compressor Damage</li>
                                        <li><i className="bi bi-check-circle"></i>Lower Maintenance Costs</li>
                                        <li><i className="bi bi-check-circle"></i>Prevent Corrosion</li>
                                    </ul>
                                </div>
                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center mt-lg-0 mt-3 ps-lg-3">
                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/mist-separators-candle-filters/sec2.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center pe-lg-3">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid" src="/images/products/mist-separators-candle-filters/sec3.jpg" alt="" />

                            </div>
                        </div>
                        <div className="col-lg-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2"><span className="text-primary-red">Unicon's</span> Precision Hydrogen Stream Purification
                                </h4>
                                <p className="section-paragraph">Hydrogen bubbles create mist in Sodium Hydroxide (NaOH) solution. Unicon’s mist eliminators ensure clean, dry hydrogen gas for storage.</p>
                                <h5 class="mb-lg-2 mb-4 mt-4">Benefits of Unicon's Mist Removal Solutions:</h5>
                                <div className="d-flex gap-3 flex-wrap  mt-3  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Prevents Storage Tank Damage </li>
                                        <li><i className="bi bi-check-circle"></i>Protects Equipment</li>
                                        <li><i className="bi bi-check-circle"></i>Higher Purity</li>

                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none  ps-0">
                                        <li><i className="bi bi-check-circle"></i>Reduces Burner Fouling</li>
                                        <li><i className="bi bi-check-circle"></i>Enhances System Efficiency</li>
                                        <li><i className="bi bi-check-circle"></i>Prevents Compressor Damage</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-lg-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInRight" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>

                                <h4 className="section-heading mb-2 pe-4 pe-md-0 w-md-75 w-100 mx-auto "><span className="text-primary-red">Unicon's</span> Candle Filters for
                                Sulfuric Acid Industry
                                </h4>
                                <p className="section-paragraph">Unicon’s candle filters clean gas streams with fine mist particles using densely packed fibers. Liquids separated by Brownian or impaction methods drain off by gravity.</p>

                                <h5 class="mb-lg-2 mb-4 mt-4">Benefits of Unicon's Mist Removal Solutions:</h5>
                                <div className="d-flex gap-3 flex-wrap  mt-3  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i>Filter quantity depends on pressure loss and efficiency needs, with Drying Towers favoring Demisters or Mesh Pads for easy installation and low pressure loss.</li>
                                        <li><i className="bi bi-check-circle"></i>Impaction candles typically have media velocities of 1.5 m/sec and a design pressure loss of 100 to 150 mm WC.</li>
                                        <li><i className="bi bi-check-circle"></i>Carbon fibers are used where HF corrodes glass fiber, while cage assemblies are made from Stainless Steel 316 L/316 for durability.</li>
                                        <li><i className="bi bi-check-circle"></i>Intermediate Absorption Towers use Brownian Diffusion Candles to capture submicron mist, protecting downstream heat exchangers. They operate at 0.15 m/sec with a design pressure loss of 150 to 250 mm WC and achieve 100% removal of particles over 3 microns.</li>

                                    </ul>
                                   
                                </div>
                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center mt-lg-0 mt-3 ps-lg-3">
                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="w-100 img-fluid " src="/images/products/mist-separators-candle-filters/sec4.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <style>
                {`

                .ar, .ad{
                    font-size:30px;
                }
                    .adm{
                     font-size: 18px;
                      margin-top: -18px;
                    }
                .flow-diagram {
                    display: grid;
                    grid-template-columns: 1fr 50px 1fr 50px 1fr;
                    grid-template-rows: repeat(5, auto);
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                }
                .box {
                    padding: 10px;
                    border-radius:8px;
                    font-weight: 600;
                    color: #1a1a1a;
                    border: 1px solid;
                    background-color: #fff;
                    position: relative;
                }
        
                @media only screen and (max-width: 991px) {
                    .icon-list.style-three {
                        display: flex;
                    }
                    ul.icon-list.style-three li {
                        align-items: center;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
                @media only screen and (max-width: 575px) {
                    .flex-sm-d-wrap {
                        flex-wrap: wrap;
                    }
                }
                `}
            </style>
        </>
    )
}

export default SectionTwoThree;