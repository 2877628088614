import React from 'react';

const ProfileSection = () => {
    return (
        <>
            <section className="cust-padding2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="tj-sec-heading-two wow fadeInLeftBig" data-wow-delay="100ms">
                                <h6 className="mb-3 text-red">Profile</h6>
                                <h4 className="section-heading mb-3">
                                    Innovative Air Pollution<br/> Control Technologies
                                </h4>
                                <div className="desc">
                                    <p className="text-muted" style={{fontSize: "18px", marginBottom: "0", lineHeight: "1.8"}}><i>"At the heart of our mission lies a commitment to excellence in air
                                        pollution control. We innovate, we adapt, and we lead with technologies that protect our
                                        environment and improve quality of life. Together, we forge a cleaner, more sustainable
                                        future for all."</i></p>
                                </div>

                                <div className="desc mt-4">
                                    <p style={{fontSize: "18px", marginBottom: "0", lineHeight: "1.8", color:"#101010", fontWeight: "500"}}>At the forefront of environmental sustainability, our innovative air pollution control
                                        technologies significantly reduce harmful emissions and improve air quality.</p>
                                </div>

                                <a className="primary-btn-1 btn-hover mt-4" href="services">
                                    View Service <i className="bi bi-arrow-right ps-3"></i>
                                    
                                </a>
                            </div>
                        </div>

                        <div className="offset-lg-1 col-lg-4">
                            <div className="o2-img text-center wow fadeInRightBig" data-wow-delay=".1s">
                                <img src="/images/o2.png" alt="" style={{borderRadius: "0px 100px 0px 0px"}} className='img-fluid'/>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card abt-sec mt-5" style={{"padding": "30px","backgroundColor": "#ededff","border": "none", "borderRadius": "8px"}}>
                                <div className="row g-sm-5 g-3">
                                    <div className="col-lg-4 col-md-12 px-lg-4 px-2">
                                        <div className="d-flex align-items-center justify-content-md-center">
                                            <div className="d-flex sub-card align-items-center gap-3" style={{"padding": "15px","backgroundColor": "#fff","borderRadius": "100px","width": "100%","position": "relative"}}>
                                                <div className="abt-sec-icon" style={{"background": "linear-gradient(15deg, rgba(0, 166, 250, 1) 0, rgba(77, 118, 249, 1) 100%)"}}>
                                                    <img src="/images/design.png" alt="" className='img-fluid'/>
                                                </div>
                                                <h4>Design &amp; Engineering</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="d-flex sub-card align-items-center gap-3" style={{"padding": "15px","backgroundColor": "#fff","borderRadius": "100px","width": "100%","position": "relative"}}>
                                                <div className="abt-sec-icon " style={{"background": "linear-gradient(15deg, rgba(255, 83, 88, 1) 0, rgba(219, 30, 123, 1) 100%)"}}>
                                                    <img src="/images/portfolio.png" alt="" className='img-fluid'/>
                                                </div>
                                                <h4>Product Portfolio</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                        <div className="d-flex align-items-center justify-content-md-center justify-content-end">
                                            <div className="d-flex sub-card align-items-center gap-3" style={{"padding": "15px","backgroundColor": "#fff","borderRadius": "100px","width": "100%","position": "relative"}}>
                                                <div className="abt-sec-icon " style={{"background": "linear-gradient(15deg, rgba(93, 10, 222, 1) 0, rgba(136, 58, 201, 1) 100%)"}}>
                                                    <img src="/images/support.png" alt="" className='img-fluid'/>
                                                </div>
                                                <h4>Customer Service</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <style>
                {`

                .abt-sec-icon {
                    padding: 20px;
                    background: #ccc;
                    border-radius: 100px;
                    width: 80px;
                    height: 80px;
                    transition: .2s all linear;
                    text-align: center;
                    flex-shrink: 0;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    transition: transform 0.6s; 
                    transform-style: preserve-3d;
                }

                .abt-sec-icon:hover {
                    transform: rotateY(180deg); 
                    }

                @media (max-width: 991px) {
                    .o2-img img {
                        border-radius: 100px 100px 0px 0px !important;
                        margin-top:24px;
                    }
                    .sub-card {
                            width: -moz-fit-content !important;
                            width: fit-content !important;
                            justify-content: center !important;
                            padding: 15px 30px 15px 15px !important;
                    }

                    .sub-card h4 {
                        font-size: 22px !important;
                        font-weight: 800 !important;
                    }
                    .abt-sec-icon img {
                        width: 34px;
                    }
                }

                @media (max-width: 575px) {
                    .sub-card h4 {
                        font-size: 16px !important;
                        font-weight: 800 !important;
                    }
                    .abt-sec-icon {
                        width: 50px;
                        height: 50px;
                        padding: 15px;
                    }
                                }
                                    
                                
                `}
            </style>
        </>
    )
}

export default ProfileSection;