import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation} from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const HighlightsSection = () => {
    return (
        <>

            <section className="about-us-section section-space position-relative cust-padding">
                <div className="shape-area">
                    <div className="shape-1" style={{ backgroundImage: `url(/images/shapes/bg-shape-1.jpg)`}}></div>
                </div>
                <div className="container">
                    <div className="uk-section">
                        <div className="blog-head">
                            <h4 className="section-heading text-lg-center">
                                News & Highlights
                            </h4>
                        </div>

                        <Swiper
                            spaceBetween={20}
                            slidesPerView={1}
                            loop={true}
                            pagination={{ clickable: true }}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}

                            navigation
                            modules={[Autoplay, Pagination, Navigation]}
                            breakpoints={{
                                768: {
                                    slidesPerView: 1,
                                },
                                1440: {
                                    slidesPerView: 1,
                                },
                            }}

                            style={{
                                padding: '80px 10px'
                            }}
                        >
                            <SwiperSlide>
                                <div className="item">
                                    <div className="row g-0 justify-content-center">
                                        <div className="col-lg-5">
                                            <div className="blog-img h-100 text-end">
                                                <img src="/images/blog-1.jpg" className="img-fluid h-100" alt="Blog Post" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card blog-card h-100">
                                                <div className="card-body">
                                                    <h5 className="mb-3">Unicon wins</h5>
                                                    <p>
                                                        Unicon has been awarded a contract worth approximately $8.5 million for the replacement
                                                        of existing battery cyclones with 5 Nos Electrostatic Precipitators (ESPs) on
                                                        a turnkey basis from a leading Steel Plant. The project will be executed under the
                                                        consultancy of M/s. Centre for Engineering & Technology (CET). Guaranteed outlet
                                                        emission should be <span className="no-break"> <i class="bi bi-chevron-left"></i>  50mg/Nm<sup>3</sup>.</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="item">
                                    <div className="row g-0 justify-content-center">
                                        <div className="col-lg-5">
                                            <div className="blog-img h-100 text-end">
                                                <img src="/images/news-2.jpg" className="img-fluid h-100" alt="Blog Post" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card blog-card h-100">
                                                <div className="card-body">
                                                    <h5 className="mb-3">Unicon awarded ESP Upgradation Order</h5>
                                                    <p>
                                                        Unicon has been awarded a contract for Upgradation of Existing ESP by adding additional field to meet outlet emission <i class="bi bi-chevron-left"></i> 30 mg/Nm<sup>3</sup> while increasing the capacity of Boiler from 130 TPH into 160 TPH by one of the leading Sugar Plant in India. The scope of work was Design, Engineering, Manufacturing, Supply, Erection and Commissioning. Unicon have successfully completed and commissioned the project and the outlet emission is well within the guaranteed limits. Unicon had executed the project under the consultancy of one of the leading consultant in India.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="item">
                                    <div className="row g-0 justify-content-center">
                                        <div className="col-lg-5">
                                            <div className="blog-img h-100 text-end">
                                                <img src="/images/blog-3.jpg" className="img-fluid h-100" alt="Blog Post" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card blog-card h-100">
                                                <div className="card-body">
                                                    <h5 className="mb-3">Unicon successfully commissioned</h5>
                                                    <p>
                                                        Unicon have successfully completed
                                                        upgradation of ESP of Sinter Plant having
                                                        a total
                                                        sintering area of 286m<sup>2</sup>. The project also
                                                        includes installation of Pneumatic
                                                        Conveying System for conveying of
                                                        collected Sinter Dust. The project was on
                                                        turnkey
                                                        basis from leading Steel Plant. The
                                                        project to be executed under the
                                                        consultancy of
                                                        M/s. Centre for Engineering & Technology
                                                        (CET). Guaranteed outlet emission should
                                                        be
                                                        <span class="no-break">
                                                        <i class="bi bi-chevron-left"></i> 50mg/Nm<sup>3</sup>. </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="item">
                                    <div className="row g-0 justify-content-center">
                                        <div className="col-lg-5">
                                            <div className="blog-img h-100 text-end">
                                                <img src="/images/news-4.jpg" className="img-fluid h-100" alt="Blog Post" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card blog-card h-100">
                                                <div className="card-body">
                                                    <h5 className="mb-3">Unicon's ESP Project for Indian Steelmaker</h5>
                                                    <p>
                                                    Unicon has been awarded a contract for Design, Engineering, Supply, Supervision of Erection and Commissioning of 7 ESPs for their 75 TPH WHRBs (Gas Volume 97.2 m<sup>3</sup>/sec, Inlet Dust Load - 40 g/Nm<sup>3</sup> & Outlet Emission requirement : 30 mg/Nm<sup>3</sup>, with one field out of service condition) for their two plants by one of the leading steel manufacturer in India. Presently the contact is at final stage of execution. The project is executed under the consultancy of one of the prominent consultants in India.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="item">
                                    <div className="row g-0 justify-content-center">
                                        <div className="col-lg-5  col-12">
                                            <div className="blog-img h-100 text-end">
                                                <img src="/images/news-5.jpg" className="img-fluid h-100" alt="Blog Post" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="card blog-card h-100">
                                                <div className="card-body">
                                                    <h5 className="mb-3">Unicon Secures Major Steel Plant Upgrade Contract</h5>
                                                    <p>
                                                    Unicon has been awarded a contract worth approximately $ 3.6 million by one of the leading steel plant in India for Up-gradation of Blast Furnace Stock House Dedusting system on Turnkey basis including Design, Engineering, Manufacturing, Supply, Dismantling of existing systems, Erection and Commissioning with all related civil works. The contract pertains to Up-grade the existing Stock House dedusting System (wet cyclone type) with ESP based dedusting system to meet the environmental standards. The project to be executed under one of the Major Steel Plant consultancy in India. 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </Swiper>
                    </div>
                </div>
            </section>

            <style>
                {`

                .about-us-section .shape-1 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: bottom left;
                }
                .blog-card {
                    border: 0px;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    border-radius: 0px 10px 10px 0px;
                }
                .blog-card .card-body {
                    padding: 50px;
                    border-bottom-right-radius:12px;
                    border-top-right-radius:12px;
                }
                .blog-card p {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.5;
                    margin-bottom:0;
                }
                .blog-img img{
                    width:510px;
                    border-bottom-left-radius:12px;
                    border-top-left-radius:12px;
                }
                .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
                     bottom: 0px;
                }
                .swiper-button-next, .swiper-button-prev {
                       color: #fff;
                        background-color: #0a2e81;
                        padding: 8px 14px;
                        border-radius: 7px;
                        top:17px;
                        height:auto !important;
                        width:auto !important;
                }
                .swiper-button-prev {
                left:92%;
                margin-left:0px;
                }
                .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
                    content: 'next';
                    font-size: 18px;
                }
                .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
                    content: 'prev';
                      font-size: 18px;
                }

                .swiper-pagination-bullet-active{
                background:#052d83;
                }       
                .uk-section{
                position:relative !important;
                }
                .uk-section .swiper{
                position:static !important;
                }
                @media (max-width: 1200px) {
                    .blog-card p {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                    }
                    .swiper-button-prev {
                        margin-left: -22px;
                    }
                }
                
                 @media (max-width: 991px) {
                    .blog-card .card-body {
                    padding: 20px;
                    }
                     .blog-img img{
                         width:100% !important;
                         height:100%;
                         object-fit:cover;
                         border-bottom-left-radius:0px;
                         border-top-left-radius:12px;
                         border-top-right-radius:12px;
                     }
                    .blog-img{
                    width:100%;
                    }
                }

                 @media (max-width: 767px) {
                    .swiper-button-prev {
                        margin-left:-40px;
                        }
                        .uk-section .swiper{
                       padding:40px 10px !important;
                        }
                }

                 @media (max-width: 575px) {
                    .swiper-button-prev {
                        margin-left:-70px;
                        }
                }
                                                
                `}
            </style>
        </>
    )
}

export default HighlightsSection;