import React from 'react';
import { Link } from 'react-router-dom';


const SectionTwoThree = () => {
    return (
        <>
            
            <section className="complimentry-section-one ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-md-6 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                            <div className="banner-gallery text-md-center">

                                <img style={{ "boxShadow": "none", "borderRadius": "8px" }} className="img-fluid" src="/images/products/bag-house/cutout2.jpg" alt="" />

                            </div>
                        </div>
                        <div className="col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                                <h4 className="section-heading  mb-2">Features and Benefits
                                </h4>
                                <p className="section-paragraph"><span className="text-primary-red">Unicon's</span> Bag Houses are engineered with advanced features and provide numerous benefits to ensure optimal performance and user satisfaction.</p>
                                <div className="d-flex gap-3  mt-4  ">
                                    <ul className="icon-list style-two me-3 ps-0 mb-0">
                                        <li><i className="bi bi-check-circle"></i> High Efficiency</li>
                                        <li><i className="bi bi-check-circle"></i> Built to Last    </li>
                                        <li><i className="bi bi-check-circle"></i> Environmental Compliance</li>

                                    </ul>
                                    <ul className="icon-list style-two d-xl-block d-none ps-0">
                                        <li><i className="bi bi-check-circle"></i> Automated Cleaning</li>
                                        <li><i className="bi bi-check-circle"></i> Customizable Designs</li>
                                        <li><i className="bi bi-check-circle"></i> Online and Offline Cleaning Designs</li>
                                    </ul>
                                </div>


                                <div className="mt-3">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="complimentry-section-two bg-light p-relative ip-padding">
                <div className="container">
                    <div className="row g-lg-4 g-3 align-items-center">
                        <div className="col-xl-6 col-md-6  p-relative">
                            <div className="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>

                                <h4 className="section-heading mb-4 pe-5 pe-md-0 w-md-75 w-100 mx-auto "> How <span className="text-primary-red">Unicon's</span> Bag House works
                                </h4>
                                <div className="">
                                    <ul className="icon-list style-two me-4 ps-0">
                                        <li><i className="bi bi-check-circle"></i> Air containing dust enters the Bag House, where it passes through fabric filter bags that trap particulate matter.
                                        </li>
                                        <li><i className="bi bi-check-circle"></i>Clean air is then expelled, and the accumulated dust is periodically removed from the bags through a pulse-jet cleaning system.
                                        </li>

                                    </ul>
                                </div>
                                <div className="mt-4">
                                    <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Enquire Now<i className="bi bi-arrow-right ms-3"></i></Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6  wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                            <div className="flow-wrapper ms-xl-5 mt-lg-0 mt-4" style={{"background":"#fff","boxShadow":"rgba(0, 0, 0, 0.15) 0px 5px 15px 0px","padding":"25px","borderRadius":"8px"}}>
                                <div className="d-none d-xl-block">
                                    <h4 className="sub-heading mb-5 pe-5 pe-md-0 w-md-75 w-100 mx-auto text-lg-center text-start">Functional Flow</h4>
                                    <div className="flow-diagram">
                                        <div className="box bg-secondary text-white">Dust-Laden Air Intake</div>
                                        <div className="arrow-container">
                                            <i className="bi bi-chevron-right ar"></i>
                                        </div>
                                        <div className="box bg-warning">Filtration</div>
                                        <div className="arrow-container">
                                            <i className="bi bi-chevron-right ar"></i>
                                        </div>
                                        <div className="box bg-success text-white">Clean Air Exhaust</div>

                                        <div className="spacer"></div>
                                        <div></div>
                                        <div className="arrow-container">
                                            <i className="bi bi-chevron-down ad"></i>
                                        </div>
                                        <div></div>
                                        <div className="spacer"></div>

                                        <div></div>
                                        <div></div>
                                        <div className="box bg-grey">Dust Collection</div>
                                        <div className="arrow-container">

                                        </div>
                                        <div className="spacer"></div>

                                        <div></div>
                                        <div></div>
                                        <div className="box bg-grey">Automated Cleaning</div>
                                        <div className="arrow-container short-arrow d-flex flex-column">
                                            <i className="bi bi-chevron-down adm"></i>
                                            <i className="bi bi-chevron-down adm"></i>
                                            <i className="bi bi-chevron-down adm"></i>
                                        </div>
                                        <div className="spacer"></div>

                                        <div></div>
                                        <div></div>
                                        <div className="box bg-grey">Dust Disposal</div>
                                        <div></div>
                                    </div>
                                </div>

                                <div className="d-block d-xl-none text-center">
                                    <img className="w-md-100 img-fluid" src="/images/products/bag-house/flow-md.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`

                .ar, .ad{
                    font-size:30px;
                }
                    .adm{
                     font-size: 18px;
                      margin-top: -18px;
                    }
                .flow-diagram {
                    display: grid;
                    grid-template-columns: 1fr 50px 1fr 50px 1fr;
                    grid-template-rows: repeat(5, auto);
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                }
                .box {
                    padding: 10px;
                    border-radius:8px;
                    font-weight: 600;
                    color: #1a1a1a;
                    border: 1px solid;
                    background-color: #fff;
                    position: relative;
                }
        
        @media only screen and (max-width: 991px) {
            .icon-list.style-three {
                display: flex;
            }
            ul.icon-list.style-three li {
                align-items: center;
                margin-bottom: 0;
                text-align: center;
            }
        }
        @media only screen and (max-width: 575px) {
            .flex-sm-d-wrap {
                flex-wrap: wrap;
            }
        }
        `}
            </style>
        </>
    )
}

export default SectionTwoThree;