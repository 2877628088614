import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon Products – Innovative Solutions for Every Industry"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon Products – Innovative Solutions for Every Industry</title>
      <meta
        name="title"
        content="Unicon Products – Innovative Solutions for Every Industry"
      />
      <meta
        name="description"
        content="Discover Unicon's wide range of products, including air pollution control systems, material handling solutions, and industrial equipment tailored to your needs."
      />
      <meta name="keywords" content="Unicon Products" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Products – Innovative Solutions for Every Industry"
      />
      <meta property="og:site_name" content="Unicon Products" />
      <meta property="og:url" content="https://www.uniconinter.com/products" />
      <meta
        property="og:description"
        content="Discover Unicon's wide range of products, including air pollution control systems, material handling solutions, and industrial equipment tailored to your needs."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/products-list/video-thumb.jpg"
      />
    </Helmet>
  );
};

export default Meta;
