import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Residual Life Assessment for Asset Longevity"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Residual Life Assessment for Asset Longevity</title>
      <meta name="title" content="Unicon Residual Life Assessment for Asset Longevity"/>
<meta name="description" content="Unicon's residual life assessment services evaluate the remaining lifespan of industrial equipment, ensuring safety, reliability, and optimal performance"/>
<meta name="keywords" content="Best Residual Life Assessment"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Residual Life Assessment for Asset Longevity"/>
<meta property="og:site_name" content="Unicon Residual Life Assessment"/>
<meta property="og:url" content="https://www.uniconinter.com/residual-life-assessment"/>
<meta property="og:description" content="Unicon's residual life assessment services evaluate the remaining lifespan of industrial equipment, ensuring safety, reliability, and optimal performance"/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/services/rla/rla-main.jpg"/> 

    </Helmet>
  );
};

export default Meta;
