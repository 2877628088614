import React, { useEffect } from 'react';
import { SlideGateHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { SlideGateAcc } from '../../../common/Components/AccordionSection';
import { SlideGateCta } from '../../../common/Components/CtaSection';
import { SlideGateQUickLinks } from '../../../common/Components/QuickLinksSection';
import Meta from "../../../common/Meta/SlideGate";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const SlideGate = () => {
  useEffect(() => {
    document.title = "Unicon | Slide Gate Valves for Precise Control";
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <SlideGateHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <SlideGateAcc/>
        <SlideGateCta/>
        <SlideGateQUickLinks/>
    </>
  )
}

export default SlideGate;