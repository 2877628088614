import React, { useEffect } from 'react';
import {SilencersHero} from '../../../common/Components/HeroSection';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import { SilencersCta } from '../../../common/Components/CtaSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/Silencers";

const Silencers = () => {
  useEffect(() => {
    document.title = "Unicon | Industrial Silencers for Noise Control";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <SilencersHero/>
        <SectionTwo/>
        <SectionThree/>
        <SectionFour/>
        <SilencersCta/>
    </>
  )
}

export default Silencers;