import React, { useEffect } from 'react';
import { CfdSS } from '../../../common/Components/ServiceSecondSec';
import { CFDHero } from '../../../common/Components/HeroSection';
import { CfdCta } from '../../../common/Components/CtaSection';
import { CfdQUickLinks } from '../../../common/Components/QuickLinksSection';
import { CFDScroll } from '../../../common/Components/ScrollSec';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/Cfd";

const CFD = () => {
  useEffect(() => {
    document.title = "Unicon | Computational Fluid Dynamics for Optimized Design";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <CFDHero/>
        <CfdSS/>
        <CFDScroll/>
        <CfdCta/>
        <CfdQUickLinks/>
    </>
  )
}

export default CFD;