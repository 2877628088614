import React from 'react';
import {Link} from 'react-router-dom';

const CtaDesign = () => {
    return (
        <>
            <section className="cust-padding wow fadeInUp" data-wow-delay=".6s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="abt-cta" style={{"backgroundColor":"#052d83","padding":"50px","borderRadius":"20px"}}>
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="abt-cta-inner">
                                    <h4>Installing ESP’s for your factory, industry or plant? Our installation solutions extends around the world</h4>
                                    <Link to="" data-bs-toggle="modal"
          data-bs-target="#exampleModal" className="primary-btn-4 ouline-btn-white btn-hover mt-3" style={{"backgroundColor":"transparent !important"}}>
                                       Get In Touch <i className="bi bi-arrow-right"></i>
                                        
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-5 mt-lg-0 mt-4">
                                <div className="abt-cta-img2">
                                    <img src="/images/esp-1.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <style>
                {`
                
                .abt-cta-img2 img {
                    border-radius: 20px;
                    padding: 30px;
                    background-color: #3a5ba5;
                }
                @media(max-width:400px){
                    .abt-cta-img2 img {
                    padding: 18px;
                 }
                }
                `}
            </style>
           
        </>
    )
}

export default CtaDesign;