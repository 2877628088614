import React, { useEffect, useRef } from 'react';
import MapGlobe from './MapGlobe';

const GlobeMap = () => {
  return (
    <>
    
    <section className="cust-padding map-custom wow fadeInUpBig" data-wow-delay=".1s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUpBig", "backgroundColor": "#04062d"}}>
      <div className="container">
         <div className="row">
            <h4 className="section-heading mb-5 text-white text-center">Global Presence</h4>
            <MapGlobe/>
         </div>
      </div>
   </section>
    <style>
        {`
        @media (max-width: 575px) {
            #chartdiv {
                height: 200px !important;
            }
        }
                `}
    </style>
    </>
  )
}

export default GlobeMap;