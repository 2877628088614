import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Baghouse Spares for Efficient Dust Collection"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Baghouse Spares for Efficient Dust Collection</title>
      <meta name="title" content="Unicon Baghouse Spares for Efficient Dust Collection"/>
<meta name="description" content="Unicon supplies reliable, durable spares for baghouse systems, ensuring optimal dust collection performance and extending equipment lifespan"/>
<meta name="keywords" content="Best Baghouse Spares"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Baghouse Spares for Efficient Dust Collection"/>
<meta property="og:site_name" content="Unicon Spares for Baghouse"/>
<meta property="og:url" content="https://www.uniconinter.com/spares-for-baghouse"/>
<meta property="og:description" content="Unicon supplies reliable, durable spares for baghouse systems, ensuring optimal dust collection performance and extending equipment lifespan"/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/services/baghouse-spares/slide-1.jpg"/>

    </Helmet>
  );
};

export default Meta;
