import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Air Pollution Control Equipment Manufacturers"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    
      <Helmet>
        <title>
        Unicon - Air Pollution Control Equipment Manufacturers
        </title>
        <meta
          name="title"
          content="Unicon - Air Pollution Control Equipment Manufacturers"
        />
        <meta
          name="description"
          content="Leading manufacturers of air pollution control equipment & material handling systems in India, designed for various industrial applications."
        />
        <meta name="keywords" content="Air pollution control equipment" />
        <meta name="robots" content="index,follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta
          property="og:title"
          content="Unicon - Air Pollution Control Equipment Manufacturers"
        />
        <meta property="og:site_name" content="Unicon Home" />
        <meta property="og:url" content="https://www.uniconinter.com/" />
        <meta
          property="og:description"
          content="Leading manufacturers of air pollution control equipment & material handling systems in India, designed for various industrial applications."
        />
        <meta property="og:type" content="product" />
        <meta
          property="og:image"
          content="https://www.uniconinter.com/images/esp.jpg"
        />
      </Helmet>
    
  );
};

export default Meta;
