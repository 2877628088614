import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon Design | Custom Industrial Pollution Control Solutions"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>
        Unicon Design | Custom Industrial Pollution Control Solutions
      </title>
      <meta
        name="title"
        content="Unicon Design - Custom Industrial Pollution Control Solutions"
      />
      <meta
        name="description"
        content="Skilled in engineering design tools to deliver compact, lightweight, affordable, and customized pollution control equipment with top-notch services."
      />
      <meta name="keywords" content="Custom Industrial Pollution Control" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Design-Custom Industrial Pollution Control Solutions"
      />
      <meta property="og:site_name" content="Unicon Design" />
      <meta property="og:url" content="https://www.uniconinter.com/design" />
      <meta
        property="og:description"
        content="Skilled in engineering design tools to deliver compact, lightweight, affordable, and customized pollution control equipment with top-notch services."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/factory.png"
      />
    </Helmet>
  );
};

export default Meta;
