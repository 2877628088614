import React, { useEffect } from 'react';
import { EspHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import { EspCta } from '../../../common/Components/CtaSection';
import { EspQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/ElectrostaticPrecipitator";

const ElectrostaticPrecipitator = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    document.title = "Unicon Profile | Electrostatic Precipitator In Thermal Power Plant";
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <EspHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <SectionFive/>
        <EspCta/>
        <EspQUickLinks/>
    </>
  )
}

export default ElectrostaticPrecipitator;