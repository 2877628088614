
import React from 'react';
import {PcsTable } from '../../../common/Components/TablesProduct';

const SectionFour = () => {
    return (
        <>
            <section class="product-specification ip-padding" id="prodSpecs">
                <div class="container">
                    <div class="spec-wrapper">
                        <div class="row g-lg-5 g-3">
                            <div class="col-xxl-6 col-xl-5 col-lg-4 col-md-12 wow fadeInLeft" data-wow-delay=".4s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                <div class="bn-content-wrapper wow fadeInLeft" data-wow-delay=".5s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInLeft"}}>
                                    <span class="section-sub-title  d-block">Pneumatic Conveying Systems</span>
                                    <h4 class="section-heading mb-4">Product Specification</h4>


                                </div>
                                <figure class="image m-img pt-0">
                                    <img src="/images/products/pneumatic-conveying-system/cutout3.jpg" alt="" className='img-fluid' style={{"borderRadius":"8px"}}/>
                                </figure>

                                <div className="key-note lh-base mt-3" style={{ "backgroundColor": "#fff", "padding": "14px", "borderRadius": "8px", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}><small className="lh-base">
                                        *Other Industry —  <br />
                                        Cement: Conveying of Cements Dust, Clinker Dust, Gypsum Dust, Slag Duct, Fly Ash;<br />
                                        Iron & Steel: Conveying of Sinter Dust, Cast House dust, Stock House dust, etc..,</small>
                                    </div>


                            </div>
                            <div class="col-xxl-6 col-xl-7 col-lg-8 col-md-12 wow fadeInRight" data-wow-delay=".4s" style={{"visibility":"visible","animationDelay":".4s","animationName":"fadeInRight"}}>
                                <div class="specc-wrapper mt-lg-0 mt-4 ps-md-0 ps-lg-2">
                                    <div class="table-responsive">
                                        <PcsTable/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SectionFour;