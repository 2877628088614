import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';

const CtaProfile = () => {
  
    return (
        <>
            <section className="cust-padding wow fadeInUp" data-wow-delay=".6s" data-wow-offset="100" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUp" }}>
                <div className="container">
                    <div className="abt-cta" style={{"backgroundColor":"#052d83","padding":"50px","borderRadius":"20px"}}>
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="abt-cta-inner">
                                    <h4>Our team are highly skilled and experienced professionals in various functional areas.</h4>
                                    <Link to="/job" className="primary-btn-4 ouline-btn-white btn-hover mt-3" style={{"backgroundColor":"transparent !important"}}>
                                        Join Our Team <i className="bi bi-arrow-right"></i>
                                        
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-lg-0 mt-4">
                                <div className="abt-cta-img">
                                    <img src="/images/engineers.jpg" className="img-fluid" alt="" style={{borderRadius: "20px"}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
           
        </>
    )
}

export default CtaProfile;