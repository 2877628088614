import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon | Erection & Commissioning Services for Smooth Setup"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Erection & Commissioning Services for Smooth Setup</title>
      <meta
        name="title"
        content="Unicon Erection & Commissioning Services for Smooth Setup"
      />
      <meta
        name="description"
        content="Unicon offers expert erection and commissioning services to ensure seamless installation and operational efficiency for your industrial systems"
      />
      <meta name="keywords" content="Best Erection & Commissioning Services" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Erection & Commissioning Services for Smooth Setup"
      />
      <meta property="og:site_name" content="Unicon Erection & Commissioning" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/erection-commissioning"
      />
      <meta
        property="og:description"
        content="Unicon offers expert erection and commissioning services to ensure seamless installation and operational efficiency for your industrial systems."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/services/erection-comissioning/ec.jpg"
      />
    </Helmet>
  );
};

export default Meta;
