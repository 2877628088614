import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon Careers | Join Our Innovative Team"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon Careers | Join Our Innovative Team</title>
      <meta name="title" content="Unicon Careers – Join Our Innovative Team"/>
<meta name="description" content="Explore exciting job opportunities at Unicon. Join a dynamic team focused on innovation and excellence in the environmental protection and industrial solutions industry"/>
<meta name="keywords" content="Unicon Job"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Careers – Join Our Innovative Team"/>
<meta property="og:site_name" content="Unicon Job"/>
<meta property="og:url" content="https://www.uniconinter.com/job"/>
<meta property="og:description" content="Explore exciting job opportunities at Unicon. Join a dynamic team focused on innovation and excellence in the environmental protection and industrial solutions industry"/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/commitment.jpg"/>

    </Helmet>
  );
};

export default Meta;
