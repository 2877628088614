import React from 'react';


const DesignTable = () => {
  const data = [
    { name: 'Electrostatic Precipitator (ESP)', range: '5 MW to 600 MW' },
    { name: 'Bag Filter / Bag House', range: '5 MW to 300 MW' },
    { name: 'Ammonia Flue Gas Conditioning', range: '5 MW to 300 MW' },
    { name: 'Pneumatic Conveying Systems', range: '5 MW to 300 MW' },
    { name: 'Rotary Airlock Valve	', range: '100 mm to 1200 mm opening' },
    { name: 'Slide Gate Valves', range: '200 mm to 1500 mm opening' },
    { name: 'Double Cone Valves', range: '200 mm to 800 mm opening' },
    { name: 'Double Flap Valves', range: '200 mm to 1000 mm opening' },
    { name: 'Clam Shell Gate	', range: '300 mm to 1500 mm opening' },
    { name: 'Dome Valve', range: '200 mm to 500 mm opening' },
    { name: 'Defense Equipments', range: 'As per customer requirement' },
    { name: 'Storage Tanks', range: 'Upto 10,000 mm diameter' },
    { name: 'Agitators', range: 'Upto 5,000 mm diameter' },
    { name: 'Decomposer', range: 'As per customer requirement' },
    { name: 'Pressure Vessels	', range: 'Upto 5,000 mm diameter' },
    { name: 'Jacketed Vesselsr', range: 'Upto 5,000 mm diameter' },
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Name of Product</th>
            <th>Range</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.name}</td>
              <td>{product.range}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};

const LowNoxTable = () => {
  const data = [
    { parameter: 'Application', data: 'Power generation, refineries, industrial boilers' },
    { parameter: 'Fuel', data: 'Coal, natural gas, fuel oil, biomass' },
    { parameter: 'Boiler capacity', data: 'Up to 600 MWe' },
    { parameter: 'Materials', data: 'Carbon Steel, Stainless Steel, Alloy Steel' },
    { parameter: 'Firing pattern	', data: 'Wall-fired, tangential, down shot' },
    { parameter: 'Typical NOx reduction', data: '30-60%' },
    { parameter: 'Heat Rate reduction', data: '0.5-2.0% in coal-fired applications' },
    { parameter: 'Reduction fuel consumption (CO2)', data: '1.0-5.0% in oil & gas applications' },
    { parameter: 'Typical LOI reduction', data: '2-4%' },
    { parameter: 'Favorable in-furnace conditions', data: 'Corrosion control, CO levels < 200 ppm, tube temperature control, slagging reduction' },
    { parameter: 'Short boiler outage', data: '4 weeks' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const EspTable = () => {
  const data = [
    { parameter: 'Application', data: <>Power Plants - Boilers and Heaters, <b>*Other Industries</b></> },
    { parameter: 'Capacity (Power Plant MW)	', data: '5 to 600' },
    { parameter: 'Gas Temperatures (°C)	', data: '70 - 400' },
    { parameter: <>Gas Flow Rate (m<sup>3</sup>/s)</>, data: '5 - 1500' },
    { parameter: <>ESP inlet dust loading (g/Nm<sup>3</sup>)</>, data: <>0.2 - 250 g/Nm<sup>3</sup></> },
    { parameter: <>Guaranteed ESP outlet emission (mg/Nm<sup>3</sup>) </>, data: '10 - 200 (suitable design for ESP as pre-collector), filterable PM only' },
    { parameter: 'PM Collection Efficiency', data: 'Up to 99.95%' },
    { parameter: 'Availability', data: 'Up to 98%' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};

const BagHouseTable = () => {
  const data = [
    { parameter: 'Application', data: <>Power Plants - Boilers and Heaters, <b>*Other Industries</b></> },
    { parameter: 'Power Capacity (MW)	', data: '10 to 210' },
    { parameter: 'Gas Temperatures (°C)		', data: '50 - 250' },
    { parameter: <>Gas Flow Rate (m<sup>3</sup>/s)</>, data: '5 - 1500' },
    { parameter: <>Bag House inlet dust loading (g/Nm<sup>3</sup>)</>, data: <>0.2 - 100  g/Nm<sup>3</sup></> },
    { parameter: <>Guaranteed Bag House outlet emission (mg/Nm<sup>3</sup>) </>, data: '5 - 200 (suitable design for Bag House as pre-collector)' },
    { parameter: 'PM Collection Efficiency', data: 'Up to 99.99%' },
    { parameter: 'Availability', data: 'Up to 98%' },
    { parameter: 'Bag Cleaning	', data: 'Online or Off Line as per customer requirement' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};

const AfgcTable = () => {
  const data = [
    { parameter: 'Flue Gas Temperature', fb: '155', fa: '145' },
    { parameter: <>Flue Gas Flow (m<sup>3</sup>/s)</>, fb: '48.61	', fa: '48.61	' },
    { parameter: 'Plant Load, MW', fb: '45', fa: '45' },
    { parameter: 'Moisture content of Coal, % RC', fb: '4', fa: '4' },
    { parameter: 'Ash Content of Coal, %', fb: '15', fa: '15' },
    { parameter: <>SPM in the flue gas (mg/Nm<sup>3</sup>)</>, fb: '720', fa: '70' }

  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th style={{ width: '50%' }}>Parameter	</th>
            <th style={{ width: '25%' }}>Flue Gas before NH3 injection</th>
            <th style={{ width: '25%' }}>Flue Gas after NH3 injection</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.fb}</td>
              <td>{product.fa}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const PcsTable = () => {
  const data = [
    { parameter: 'Application', data: <>Power Plants (Coal Fired, Bagasse Fired, Biomass Fired) – Ash Handling System for conveying of Ash from Pre-heater, Economizer, ESP / Bag House / MDC, <b>*Other Industries</b></> },
    { parameter: 'Conveying Capacity	', data: 'As per requirement' },
    { parameter: 'Ash Temperature	', data: '250 Deg C' },
    { parameter: 'Conveying Distance', data: '2000 meter' },
    { parameter: 'Transporter Vessel Size	', data: 'Upto 20 CFT' },
    { parameter: 'Inlet Valve Size', data: '200 mm to 400 mm (normal)' },
    { parameter: 'Conveying line size	', data: '50 NB to 300 NB' },
    { parameter: 'Conveying air pressure	', data: <>4.5 to 7.5 (kg/cm<sup>2</sup>)</> },
    { parameter: 'Instrument air pressure	', data: <>6.5 (kg/cm<sup>2</sup>)</> }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const RotaryTable = () => {
  const data = [
    { parameter: 'Application', data: 'Cement, Clinker, Gypsum, Fly Ash, Sinter dust, Sponge Iron, Coal Dust, Etc,.' },
    { parameter: 'Capacity', data: '1 TPH to 300 TPH' },
    { parameter: 'Temperature', data: '350° C Max' },
    { parameter: 'Opening Size', data: 'Upto 1200 mm' },
    { parameter: 'Flange Type	', data: 'Square, Rectangle and Round' },
    { parameter: 'Material of construction', data: 'Mild Steel, Stainless Steel, Cast Iron, Cast Steel, Cast Alloy, Aluminium, etc,.' },
    { parameter: 'Construction', data: 'Fabricated / Casting' },
    { parameter: 'Abrasion resistant lining', data: 'Sail Hard, Hardox or Stainless Steel as per requirement' },
    { parameter: 'Drive type	', data: 'Direct coupling and Chain Drive' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};



const SlideGateTable = () => {
  const data = [
    { parameter: 'Application', data: 'Cement, Clinker, Gypsum, Fly Ash, Sinter dust, Sponge Iron, Coal Dust, Etc,.' },
    { parameter: 'Capacity', data: '1 TPH to 300 TPH' },
    { parameter: 'Temperature', data: '750° F Max' },
    { parameter: 'Opening Size', data: 'Upto 2000 mm' },
    { parameter: 'Flange Type	', data: 'Square, Rectangle and Round' },
    { parameter: 'Material of construction', data: 'Mild Steel, Stainless Steel, Cast Iron, Cast Steel, Cast Alloy, Aluminium, etc,.' },
    { parameter: 'Construction', data: 'Fabricated / Casting' },
    { parameter: 'Abrasion resistant lining', data: 'Sail Hard, Hardox or Stainless Steel as per requirement' },
    { parameter: 'Drive	', data: 'Manual, Motorized or Pneumatic' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Description	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};

const DoubleConeTable = () => {
  const data = [
    { parameter: 'Application', data: 'Cement, Clinker, Gypsum, Fly Ash, Sinter dust, Sponge Iron, Coal Dust, Etc,.' },
    { parameter: 'Capacity', data: '1 TPH to 100 TPH' },
    { parameter: 'Temperature', data: '350° C Max' },
    { parameter: 'Opening Size', data: 'Upto 500 mm cone' },
    { parameter: 'Flange Type	', data: 'Square or Rectangle' },
    { parameter: 'Material of construction (Casing)', data: 'Mild Steel / Stainless Steel' },
    { parameter: 'Material of construction (Cone)', data: 'Ni Hard / Cast Steel / Cast Iron as per requirement' },
    { parameter: 'Construction', data: 'Fabricated' },
    { parameter: 'Abrasion resistant lining', data: 'Sail Hard, Hardox or Stainless Steel as per requirement' },
    { parameter: 'Drive	type', data: 'Geared Motor through cam' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Description	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const DoubleFlapTable = () => {
  const data = [
    { parameter: 'Application', data: 'Cement, Clinker, Gypsum, Fly Ash, Sinter dust, Sponge Iron, Coal Dust, Etc,.' },
    { parameter: 'Capacity', data: '1 TPH to 100 TPH' },
    { parameter: 'Temperature', data: '350° C Max' },
    { parameter: 'Opening Size', data: 'Upto 600 mm Flap' },
    { parameter: 'Flange Type	', data: 'Square or Rectangle' },
    { parameter: 'Material of construction (Casing)', data: 'Mild Steel / Stainless Steel' },
    { parameter: 'Material of construction (Flap)', data: 'Mild Steel / Stainless Steel / Hardox / SAIL Hard with or without Hard facing' },
    { parameter: 'Construction', data: 'Fabricated' },
    { parameter: 'Abrasion resistant lining', data: 'Sail Hard, Hardox or Stainless Steel as per requirement' },
    { parameter: 'Drive	type', data: 'Geared Motor through cam' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Description	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const ClamShellTable = () => {
  const data = [
    { parameter: 'Application', data: 'Cement, Clinker, Gypsum, Fly Ash, Sinter dust, Sponge Iron, Coal Dust, Etc,.' },
    { parameter: 'Capacity', data: '1 TPH to 300 TPH' },
    { parameter: 'Temperature (°C)', data: '350° C Max' },
    { parameter: 'Opening Size', data: 'Upto 2000 mm' },
    { parameter: 'Flange Type	', data: 'Square or Rectangle' },
    { parameter: 'Material of construction', data: 'Mild Steel' },
    { parameter: 'Construction method', data: 'Fabricated' },
    { parameter: 'Abrasion resistant lining', data: 'Sail Hard, Hardox or Stainless Steel as per requirement' },
    { parameter: 'Drive	type', data: 'Motorized / Pneumatic / Hydraulic' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const DomeTable = () => {
  const data = [
    { parameter: 'Application', data: 'Cement, Clinker, Gypsum, Fly Ash, Sinter dust, Sponge Iron, Coal Dust, Etc,.' },
    { parameter: 'Temperature (°C)', data: '350° C Max' },
    { parameter: 'Opening Size', data: 'Upto 500 mm' },
    { parameter: 'Material of construction', data: 'Cast Iron' },
    { parameter: 'Drive	type', data: 'Pneumatic Cylinder' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const DragChainTable = () => {
  const data = [
    { parameter: 'Application', data: 'Cement, Clinker, Sinter dust, Raw meal, etc,.' },
    { parameter: 'Capacity', data: 'Upto 250 TPH' },
    { parameter: 'Casing width', data: 'Upto 1000 mm' },
    { parameter: 'Length', data: 'Upto 25 Meter' },
    { parameter: 'Inclination', data: 'Upto 60°' },
    { parameter: 'Combinations', data: 'Pneumatic conveying system after drag chain conveyor available.' },
    { parameter: 'Inlet & Outlet', data: 'Designed with multiple inlets and outlets as per requirement' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const BeltTable = () => {
  const data = [
    { parameter: 'Capacity', data: 'Upto 5000 TPH' },
    { parameter: 'Belt Width', data: 'Upto 2000 mm' },
    { parameter: 'Side of flow direction', data: 'As per requirement' },
    { parameter: 'Motor position', data: 'As per requirement' },
    { parameter: 'Type', data: 'Horizontal & Inclined' },
    { parameter: 'Belt Material', data: 'Natural or synthetic fabrics such as polyester, nylon, or other materials' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const ScrewTable = () => {
  const data = [
    { parameter: 'Capacity', data: '100 TPH' },
    { parameter: 'Variable Pitch', data: 'Available' },
    { parameter: 'Cut Flight, Ribbon Flight & Cut and Folded Flight', data: 'Available' },
    { parameter: 'Trough Diameter', data: 'Upto 1000 mm' },
    { parameter: 'Length', data: 'Upto 30 meter' },
    { parameter: 'Water cooling arrangement', data: 'Available as per requirement' },
    { parameter: 'Maximum Temperature', data: '900° C (Special Designs)' },
    { parameter: 'Material of Construction', data: 'MS & SS' },
    { parameter: 'Hard facing of Flight', data: 'Provided as per requirement' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Parameter	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};



const SilencersTable = () => {
  const data = [
    {
      parameter: "Application",
      data: "Safety valves, Control valves, Ejectors, Tanks, etc., mainly in Energy and Oil & Gas sectors",
    },
    {
      parameter: "Fluids",
      data: "Steam, Natural gas, Nitrogen, Hydrogen, Oxygen",
    },
    { parameter: "Capacity", data: "	Up to 300 Tn/h per silencer" },
    {
      parameter: "Materials",
      data: "Carbon Steel, Stainless Steel, Alloy Steel",
    },
    { parameter: "Temperature", data: "Up to 700° C" },
    { parameter: "Diameter", data: "Up to 2,300 mm" },
    { parameter: "Noise Reduction", data: "Up to 65 dBA" },
    { parameter: "Orientation", data: "Vertical & Horizontal" },
    {
      parameter: "Type",
      data: "Cylindrical with a diffuser and rockwool crowns",
    },
    { parameter: "Inlet & Outlet", data: "As per requirement" },
    { parameter: "Pressure drop", data: "As per requirement" },
    {
      parameter: "Construction",
      data: "According to ASME and regulations required",
    },
    { parameter: "FAT Tests", data: "Penetrant Dyes, X- Rays…" },
    {
      parameter: "Accessories",
      data: "Top rain protection, Anti-bird mesh, Connection flanges, expansion joints, drain, special supports (as per requirement)",
    },
  ];

  return (
    <>
      <table
        border="1"
        style={{ width: "100%", textAlign: "left" }}
        className="table table-striped table-hover cust-table"
      >
        <thead>
          <tr>
            <th className="w-25"> Description</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const StorageTankTable = () => {
  const data = [
    {
      parameter: "Application",
      data: <>Silos : Cement, Clinker, Gypsum, Fly Ash, Sinter dust, Sponge Iron, Coal Dust, Etc,.<br/> Fluid : Nitric Acid, Concentrated Sulfuric Acid, Week Sulfuric Acid, Alcohols, Water, etc,.</>,
    },
    {
      parameter: "Capacity",
      data: "As per requirement",
    },
    { parameter: "Temperature", data: "Upto 500° C" },
    {
      parameter: "Diameter",
      data: "	Upto 10,000 mm",
    },
    { parameter: "Orientation", data: "Vertical & Horizontal" },
    {
      parameter: "Type",
      data: "Cylindrical with conical bottom, Cylindrical with dished end, Cylindrical with flat end, etc,.",
    },
    { parameter: "Inlet & Outlet", data: "As per requirement" },
    { parameter: "Vent requirement", data: "Will be provided" },
    {
      parameter: "Material of construction",
      data: "Mild Steel, Stainless Steel (SS 304/304L, SS 316/316L/316 Ti), Aluminium, etc,. We are also providing RCC Tanks.",
    },
    { parameter: "Special Lining", data: "Lead, FRP, etc,." },
    { parameter: "Construction", data: "	Fabricated / RCC." },
    {
      parameter: "Aeration for Silos",
      data: "As per requirement",
    },
    {
      parameter: "Access",
      data: "Stair case, Ladders, Cat Walks, Hand Rails, etc,. as per requirement.",
    },
    {
      parameter: "Accessories",
      data: "RAV, DCV, DFV, etc,. for Silos Valves, Pipeline, Pumps, etc",
    },
  ];

  return (
    <>
      <table
        border="1"
        style={{ width: "100%", textAlign: "left" }}
        className="table table-striped table-hover cust-table"
      >
        <thead>
          <tr>
            <th className="w-25"> Description</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const AgitatorsTable = () => {
  const data = [
    {
      parameter: "Application",
      data: "	Paint, resins, chemicals, acids, breweries and other fields.",
    },
    { parameter: "Speed (smaller agitators)", data: "500 – 6,000 RPM" },
    { parameter: "Speed (larger agitators)", data: "300 – 3,000 RPM" },
    {
      parameter: "Tanks Diameter",
      data: "Upto 5,000 mm",
    },
    {
      parameter: "Material of Construction",
      data: "Mild Steel or Stainless Steel",
    },
    {
      parameter: "Lining",
      data: "Will be provided as per requirement",
    },
    {
      parameter: "Air / Steam aeration",
      data: "Will be provided as per requirement",
    },
    {
      parameter: "Provision of Heating / cooling",
      data: "Will be provided as per requirement",
    },
  ];

  return (
    <>
      <table
        border="1"
        style={{ width: "100%", textAlign: "left" }}
        className="table table-striped table-hover cust-table"
      >
        <thead>
          <tr>
            <th className="w-25"> Description</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const JacketedTable = () => {
  const data = [
    { parameter: 'Application', data: 'Chemicals, Acids, Alcohol, etc,.' },
    { parameter: 'Inner Shell Diameter	', data: 'Upto 10,000 mm' },
    { parameter: 'Inner Shell Material', data: 'MS, SS, Aluminium, Copper, etc,.' },
    { parameter: 'Outer Shell Material', data: 'MS, SS, Aluminium, Copper, etc,.' },
    { parameter: 'Agitators', data: 'Will be provided as per requirement' },
    { parameter: 'Construction method', data: 'Fabricated' },
    { parameter: 'Orientation', data: 'Vertical / Horizontal' },
    { parameter: 'Inlet and outlet connection', data: 'Flanged, threaded or coupling' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Description	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const PressureTable = () => {
  const data = [
    { parameter: 'Application', data: 'Pharmaceuticals, Cosmetics, Food and Beverage, Plastic Processing, Power Generation, Chemicals, Paper and Pulp' },
    { parameter: 'Construction', data: 'Fabricated' },
    { parameter: 'Orientation', data: 'Vertical / Horizontal' },
    { parameter: 'Material of Construction', data: 'Mild Steel / Stainless Steel' },
    { parameter: 'Shell Diameter', data: 'Upto 5,000 mm' },
    { parameter: 'Approvals', data: 'IIT, PSG Tech, CCOE, etc,. as per requirement' },
    { parameter: 'Non destructive testing (NDT)', data: 'DP, PT, RT, UT, etc,' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Description	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};


const DecomposersTable = () => {
  const data = [
    { parameter: 'Application', data: 'Acids & explosives' },
    { parameter: 'Speed', data: 'As per requirement' },
    { parameter: 'Tanks Diameter', data: 'Upto 5,000 mm' },
    { parameter: 'Material of Construction', data: 'Mild Steel or Stainless Steel' },
    { parameter: 'Lining', data: 'Will be provided as per requirement' },
    { parameter: 'Air / Steam aeration', data: 'Will be provided as per requirement' },
    { parameter: 'Provision of Heating / cooling', data: 'Will be provided as per requirement' },
    { parameter: 'Jacketing', data: 'Will be provided as per requirement' },
    { parameter: 'Surface Finish', data: 'As per requirement' }
  ];

  return (
    <>

      <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
        <thead>
          <tr>

            <th>Description	</th>
            <th>Data</th>

          </tr>
        </thead>
        <tbody>
          {data.map((product) => (
            <tr>
              <td>{product.parameter}</td>
              <td>{product.data}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </>
  );
};

const EspSparesTable = () => {
  const data = [
    { parameter: 'Spiral Emitting Electrodes', data: 'Shaft Insulator' },
    { parameter: 'Pipe & Spike type Discharge Electrodes', data: 'Support Insulator Heater' },
    { parameter: 'Rigid type Discharge Electrodes', data: 'Shaft Insulator Heater' },
    { parameter: 'Collecting Rapping Hammers', data: 'Hopper Heater' },
    { parameter: 'Emitting Rapping Hammers', data: 'Rapping Shafts' },
    { parameter: 'GD Rapping Hammers', data: 'Collecting Suspension' },
    { parameter: 'Inner Arm', data: 'Emitting Suspension' },
    { parameter: 'Outer Arm', data: '	Rotary Airlock Valves' },
    { parameter: 'Plain Bearing', data: 'Slide Gates' }
  ];

  return (
    <>

      <section className="ip-padding">
        <div className="container wow fadeInUp" data-wow-delay=".1s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUp" }}>
          <h4 className="mb-4">Please find below details of Some Spares for ESPs we are regularly supplying</h4>

          <div className="table-responsive">
            <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
              <thead>
                <tr>

                  <th>Collecting Electrodes</th>
                  <th>Support Insulator</th>

                </tr>
              </thead>
              <tbody>
                {data.map((product) => (
                  <tr>
                    <td>{product.parameter}</td>
                    <td>{product.data}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

    </>
  );
};


const BagHouseSparesTable = () => {
  const data = [
    { parameter: 'Polyester Filter Bags', data: 'Bag Cages with MOC GI' },
    { parameter: 'PTFE Coated Filter Bags', data: 'Bag Cages with MOC MS (Al HR Paint)' },
    { parameter: 'Fiber glass Filter Bags', data: 'Bag Cages with MOC Stainless Steel' },
    { parameter: 'Polypropylene Filter Bags', data: 'Solenoid Valves' },
    { parameter: 'Nomex Filter Bags etc', data: 'Tube Sheets' },
    { parameter: 'Homopolymer Acrylic Filter Bags', data: 'Purging Pipes' },
    { parameter: 'Spun Aluminium Venturi', data: 'Rotary Airlock Valves' },
    { parameter: 'Cast Aluminium Venturi', data: 'Slide Gates' }
  ];

  return (
    <>

      <section className="ip-padding">
        <div className="container wow fadeInUp" data-wow-delay=".1s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUp" }}>
          <h4 className="mb-4">Please find below details of Some Spares for Bag Houses we are regularly supplying </h4>

          <div className="table-responsive">
            <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
              <thead>
                <tr>

                  <th>Filter Bag Type	</th>
                  <th>Bag Cages Material/Other Components</th>

                </tr>
              </thead>
              <tbody>
                {data.map((product) => (
                  <tr>
                    <td>{product.parameter}</td>
                    <td>{product.data}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

    </>
  );
};


const CementPlantTable = () => {
  const data = [
    { parameter: 'Kiln Shells', data: 'Bucket Elevators and spares' },
    { parameter: 'Wearing liner & Lifting plates', data: 'Bucket carriage' },
    { parameter: 'Vertox Finder', data: 'Operator cabin assembly' },
    { parameter: 'Classifiers', data: 'Cyclone spares' },
    { parameter: 'Conveyors', data: 'Coal feed ventury' },
    { parameter: 'Pre-heaters & heat exchangers', data: 'Centrifugal fans and blowers' },
    { parameter: 'Clinker Cooler Spares', data: 'Spring plate' },
    { parameter: 'Immersion Pipe for PH cyclone', data: 'Diaphragm / Diaphragm Segment' },
    { parameter: 'Expansion Joints', data: 'All types of ducts' },
    { parameter: 'Double Flap Valves', data: 'Rotary Airlock Valves' },
    { parameter: 'Pin Gates', data: 'Slide Gates' }
  ];

  return (
    <>

      <section className="ip-padding">
        <div className="container wow fadeInUp" data-wow-delay=".1s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUp" }}>
          <h4 className="mb-4">Please find below details of Some Spares for Cement Plants we are regularly supplying</h4>

          <div className="table-responsive">
            <table border="1" style={{ width: '100%', textAlign: 'left' }} className='table table-striped table-hover cust-table'>
              <thead>
                <tr>

                  <th>Main Component</th>
                  <th>Associated Component</th>

                </tr>
              </thead>
              <tbody>
                {data.map((product) => (
                  <tr>
                    <td>{product.parameter}</td>
                    <td>{product.data}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

    </>
  );
};


export {
  DesignTable, LowNoxTable, EspTable, BagHouseTable, AfgcTable, PcsTable, RotaryTable, SlideGateTable, DoubleConeTable, DoubleFlapTable, ClamShellTable, DomeTable, DragChainTable, BeltTable, ScrewTable,
  SilencersTable, StorageTankTable, AgitatorsTable, JacketedTable, PressureTable, DecomposersTable, EspSparesTable, BagHouseSparesTable, CementPlantTable
};

