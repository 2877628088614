import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon Profile | Pioneers Environmental Protection Industry"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>
        Unicon Profile | Pioneers Environmental Protection Industry
      </title>
      <meta
        name="title"
        content="Unicon Profile | Pioneers Environmental Protection Industry"
      />
      <meta
        name="description"
        content="Delivering technology and services for diverse industrial needs with 25+ years of expertise, ensuring quality solutions at the best possible price."
      />
      <meta name="keywords" content="Pollution Free air solutions" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />

      <meta
        property="og:title"
        content="Unicon Profile | Pioneers Environmental Protection Industry"
      />
      <meta property="og:site_name" content="Unicon Profile" />
      <meta property="og:url" content="https://www.uniconinter.com/profile" />
      <meta
        property="og:description"
        content="Delivering technology and services for diverse industrial needs with 25+ years of expertise, ensuring quality solutions at the best possible price."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/green-tech.png"
      />
    </Helmet>
  );
};

export default Meta;
