import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Pressure Vessels for Safe Fluid Containment"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Pressure Vessels for Safe Fluid Containment</title>
      <meta
        name="title"
        content="Unicon Pressure Vessels for Safe Fluid Containment"
      />
      <meta
        name="description"
        content="Unicon's pressure vessels are designed for durable, safe containment of gases and liquids, ensuring reliability and efficiency in industrial operations."
      />
      <meta name="keywords" content="Best Pressure Vessels for Industries" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Pressure Vessels for Safe Fluid Containment"
      />
      <meta property="og:site_name" content="Unicon Pressure Vessels" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/pressure-vessels"
      />
      <meta
        property="og:description"
        content="Unicon's pressure vessels are designed for durable, safe containment of gases and liquids, ensuring reliability and efficiency in industrial operations."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/pressure-vessels/ps-main.jpg"
      />
    </Helmet>
  );
};

export default Meta;
