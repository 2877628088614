import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Supervisory Services for Optimal Performance"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Supervisory Services for Optimal Performance</title>
      <meta
        name="title"
        content="Unicon Supervisory Services for Optimal Performance"
      />
      <meta
        name="description"
        content="Unicon's supervisory services ensure effective oversight and management, optimizing performance and efficiency in various industrial operations"
      />
      <meta name="keywords" content="Best Supervisory Services" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Supervisory Services for Optimal Performance"
      />
      <meta property="og:site_name" content="Unicon Supervisory" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/supervisory-services"
      />
      <meta
        property="og:description"
        content="Unicon's supervisory services ensure effective oversight and management, optimizing performance and efficiency in various industrial operations"
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/services/supervisory-service/ss.jpg"
      />
    </Helmet>
  );
};

export default Meta;
