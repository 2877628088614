import React, { useEffect } from 'react';
import { FcsHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import { FgcsCta } from '../../../common/Components/CtaSection';
import { FgcsQUickLinks } from '../../../common/Components/QuickLinksSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/FlueGasSystem";

const FlueGasSystem = () => {
  useEffect(() => {
    document.title = "Unicon - Flue Gas Conditioning System";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <FcsHero/>
        <SectionTwoThree/>
        <FgcsCta/>
        <FgcsQUickLinks/>
    </>
  )
}

export default FlueGasSystem;