import React from 'react';
import {Link} from 'react-router-dom';

const SolutionSectionTwo = () => {
  return (
    <>
     <section className="cust-padding globe-sec position-relative">
      <div className="container">
         <div className="globe-sec-inner ">
            <div className="row align-items-center">

               <div className="col-lg-6 wow fadeInLeftBig" data-wow-delay="0s" style={{"visibility":"visible","animationDelay":"0s","animationName":"fadeInLeftBig"}}>
                  <div className="global-sec pe-lg-5 pe-0">
                     <h4 className="section-heading mb-sm-3 mb-2">Addressing the global warming
                        with our reliable range of solutions</h4>

                     <p style={{"fontSize":"20px","marginTop":"30px"}}>Addressing global warming with our reliable range of solutions
                        is at the heart of what we do. Our cutting-edge air pollution
                        control equipment, including ESPs, bag houses, wet scrubbers,
                        and pneumatic conveying systems, is designed to minimize
                        industrial emissions and improve air quality.
                     </p>
                    
                    <Link to="/products" className="primary-btn-4 light-bg mt-4">
                    Our Solutions <i className="bi bi-arrow-right"></i>
                    </Link>
                     
                  </div>
               </div>

               <div className="col-lg-6 d-lg-block d-flex justify-content-center wow fadeInRightBig" data-wow-delay="0s" sstyle={{"visibility":"visible","animationDelay":"0s","animationName":"fadeInLeftBig"}}>
                  <div className="global-img">
                     <img src="/images/global.jpg" alt="" className='img-fluid' style={{"padding":"15px","backgroundColor":"#fff"}}/>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </section>

   <style>
    {`
    .globe-sec::before {
        content: '';
        position: absolute;
        width: 70%;
        height: 100%;
        left: 0px;
        top: 0px;
        background-color: #ededff;
        z-index:-1;
    }
    @media (max-width: 991px) {
        .globe-sec::before {
            width: 100%;
            height: 70%;
        }
        .global-img {
            margin-top: 24px;
            text-align: center;
            width: fit-content;
            width: -moz-fit-content;
        }
        .global-img img {
            width: 430px;
        }
    }
    @media (max-width: 575px) {
        .global-sec p {
            font-size: 16px !important;
            margin-top: 16px !important;
            margin-bottom: 0 !important;
            line-height: 1.6 !important;
        }
    }
            
    `}
   </style>
    </>
  )
}

export default SolutionSectionTwo;