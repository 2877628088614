import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

const SectionOne = () => {
    
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const yOffset = -120;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [hash]);

  
  return (
    <>
      <section className="cust-padding3 bg-light" id="productslist">
        <div className="container">
          <div className="text-center">
            <h4 className="section-heading text-dark serv-prod-head">
              Our Services
            </h4>
          </div>

          <div
            className="mb-5 wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
            id="inspection-testing-services"
          >
            <h5 className="prods-head mb-4 pb-2">
              Inspection & Testing Services
              <i className="bi bi-dash-lg text-red"></i>
            </h5>
            <div className="row g-4">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/cfd" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/its-1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Computational Fluid Dynamics (CFD)</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/gd-testing" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/its-2.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>GD Testing</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/emission-testing" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/its-3.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Emission Testing</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/supervisory-services" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/its-4.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Supervisory Services</h6>
                </Link>
              </div>
            </div>
          </div>

          <hr
            className="my-hr wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
          />

          <div
            className="wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
            id="project-management"
          >
            <h5 className="prods-head  mb-4 pb-2">
              Project Management & Plant Maintenance
              <i className="bi bi-dash-lg text-red"></i>
            </h5>
            <div className="row g-4">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/erection-commissioning" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/pm-1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Erection & Commissioning Services</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/retrofits" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/pm-2.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Retrofits</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/upgrades" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/pm-3.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Upgrades</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/residual-life-assessment" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/pm-4.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Residual Life Assessment</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/shut-down-maintenance" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/pm-5.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Shut Down Maintenance</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/annual-maintenance" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/pm-6.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Annual Maintenance </h6>
                </Link>
              </div>
            </div>
          </div>

          <hr
            className="my-hr wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
          />

          <div
            className="wow fadeInUpBig"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUpBig",
            }}
            id="spares_and_support"
          >
            <h5 className="prods-head mb-4 pb-2">
              Spares and Support<i className="bi bi-dash-lg text-red"></i>
            </h5>
            <div className="row g-4">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/spares-for-esp" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/ss-1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Spares for ESP</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/spares-for-baghouse" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/ss-2.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Spares for Bag House</h6>
                </Link>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6">
                <Link to="/spares-of-cement-plant" className="prods-cards">
                  <div className="prods-img">
                    <img
                      src="/images/services/serv-list/ss-3.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h6>Spares for Cement Plant</h6>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionOne;
