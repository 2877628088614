import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon | Shutdown Maintenance for Optimal System Overhaul"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Shutdown Maintenance for Optimal System Overhaul</title>
      <meta
        name="title"
        content="Unicon Shutdown Maintenance for Optimal System Overhaul"
      />
      <meta
        name="description"
        content="Unicon offers comprehensive shutdown maintenance services to ensure thorough inspection, repair, and optimization of industrial systems for peak performance."
      />
      <meta name="keywords" content="Shutdown Maintenance Services" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Shutdown Maintenance for Optimal System Overhaul"
      />
      <meta property="og:site_name" content="Unicon Shutdown Maintenance" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/shut-down-maintenance"
      />
      <meta
        property="og:description"
        content="Unicon offers comprehensive shutdown maintenance services to ensure thorough inspection, repair, and optimization of industrial systems for peak performance."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/services/shutdown/shutdown-main.jpg"
      />
    </Helmet>
  );
};

export default Meta;
