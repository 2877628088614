import React from 'react';
import MapGlobe from '../Home/MapGlobe';

const SectionThree = () => {

    return (
        <>

            <section className="cust-padding map-custom wow fadeInUp" data-wow-delay=".1s" style={{"visibility":"visible","animationDelay":"0.1s","animationName":"fadeInUp", backgroundColor:"#04062d" }}>
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-6">
                            <MapGlobe/>
                        </div>

                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <div className="map-sec-abt ps-lg-5">
                                <h4 className="mb-4 section-heading text-white">Global presence over 25+ countries across the world</h4>
                                <p className='text-white'>Our Strength is the vast pool of expertise already mobilized from various fields of Design, Manufacturing, Project management, Servicing, Up gradation and Augmentation of Air Pollution Control equipments.</p>
                                <p className="mb-0 text-white">We have created our organization with a vision to provide world-class service to our clients in all the above areas where we are unquestionably strong. Our greatest strength lies in our highly skilled and committed workforce.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <style>
                {`
                #chartdiv {
                    width: 100%;
                    height: 450px !important;
                    background-color: #141637;
                    border-radius: 30px;
                }

                @media (max-width: 991px) {
                     #chartdiv {
                        height: 380px !important;
                    }
                }
                @media (max-width:575px) {
                     #chartdiv {
                        height: 300px !important;
                    }
                }
                `}
            </style>

        </>
    )
}

export default SectionThree;