import React, { useEffect } from 'react';
import { BagHouseHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import { BagHouseAcc } from '../../../common/Components/AccordionSection';
import { BagHouseQUickLinks } from '../../../common/Components/QuickLinksSection';
import { BagHouseCta } from '../../../common/Components/CtaSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/BagHouse";

const BagHouse = () => {
  useEffect(() => {
    document.title = "Unicon | Advance Bag Filters and Bag Houses";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <BagHouseHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <SectionFive/>
        <BagHouseAcc/>
        <BagHouseCta/>
        <BagHouseQUickLinks/>
    </>
  )
}

export default BagHouse;