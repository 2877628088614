import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import document1 from "../../../assets/catalogues/UNICON CATALOGUE - All products.pdf";
import document2 from "../../../assets/catalogues/UNICON Bag House Catalogue.pdf";
import document3 from "../../../assets/catalogues/UNICON ESP Catalogue.pdf";
import document4 from "../../../assets/catalogues/UNICON ESP Catalogue.pdf";
import document5 from "../../../assets/catalogues/ROTARY AIR LOCK VALVE-compressed.pdf";
import document6 from "../../../assets/catalogues/15 UNICON CEMENT PLANT SPARES.pdf";
import document7 from "../../../assets/catalogues/13 UNICON PLC Panel.pdf";
import document8 from "../../../assets/catalogues/14 UNICON SPL Panel.pdf";
import document9 from "../../../assets/catalogues/09 UNICON DOUBLE FLAP VALVE.pdf";
import document10 from "../../../assets/catalogues/10 UNICON DOME VALVE.pdf";
import document11 from "../../../assets/catalogues/11 UNICON Control Panels.pdf";
import document12 from "../../../assets/catalogues/12 UNICON Insulators.pdf";
import document13 from "../../../assets/catalogues/08 UNICON DOUBLE CONE VALVE.pdf";
import document14 from "../../../assets/catalogues/05 UNICON SLIDE GATE.pdf";
import document15 from "../../../assets/catalogues/06 UNICON AFGC.pdf";
import document16 from "../../../assets/catalogues/07 UNICON CLAM SHELL GATE.pdf";
import document17 from "../../../assets/catalogues/03 UNICON SPARES.pdf";
import document18 from "../../../assets/catalogues/02 - UNICON - ESP _ Other Pollution Control Equipments.pdf";
import Meta from "../../common/Meta/Catelogue";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import { PhoneInput } from "react-international-phone";
// import "react-international-phone/style.css";

const documents = [
  { id: 1, title: "Unicon Catalogue", file: document1, downloads: 0 },
  {
    id: 2,
    title: "Bag House by Unicon Engineers Coimbatore",
    file: document2,
    downloads: 0,
  },
  {
    id: 3,
    title: "ESP by Unicon Engineers Coimbatore",
    file: document3,
    downloads: 0,
  },
  { id: 4, title: "Unicon Catalogue 2023", file: document4, downloads: 0 },
  { id: 5, title: "Unicon rav", file: document5, downloads: 0 },
  { id: 6, title: "Unicon cement plant spares", file: document6, downloads: 0 },
  { id: 7, title: "Unicon PLC Panel", file: document7, downloads: 0 },
  { id: 8, title: "Unicon SPL Panel", file: document8, downloads: 0 },
  { id: 9, title: "Unicon double flap valve", file: document9, downloads: 0 },
  { id: 10, title: "Unicon dome valve", file: document10, downloads: 0 },
  { id: 11, title: "Unicon Control Panels", file: document11, downloads: 0 },
  { id: 12, title: "Unicon Insulators", file: document12, downloads: 0 },
  { id: 13, title: "Unicon double cone valve", file: document13, downloads: 0 },
  { id: 14, title: "Unicon slide gate", file: document14, downloads: 0 },
  { id: 15, title: "Unicon AFGC", file: document15, downloads: 0 },
  { id: 16, title: "Unicon clam shell gate", file: document16, downloads: 0 },
  { id: 17, title: "Unicon spares", file: document17, downloads: 0 },
  {
    id: 18,
    title: "UNICON - Corporate Catalogue",
    file: document18,
    downloads: 0,
  },
];

const Catelogue = () => {
  useEffect(() => {
    document.title = "Unicon Catalogues – Explore Our Full Product Range";
    window.scrollTo(0, 0);
  }, []);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentsdetail, setDocuments] = useState(documents);
  const [modalType, setModalType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [countryCode, setCountryCode] = useState("in");
  useEffect(() => {
    // Fetch the user's country based on their IP
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        // ipapi returns the country code in ISO Alpha-2 format, which react-phone-input-2 expects
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      })
      .catch((error) => {
        setCountryCode("in");
        console.error("Error fetching IP information:", error);
        // Optionally set a default or handle the error
      });
  }, []);

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };
  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Full Name is required")
      .test(
        "no-first-space",
        "First letter should not be a space",
        (value) => value && !/^ /.test(value)
      )
      .required("Name is required")
      .test(
        "only-one-space-between",
        "Only one space allowed",
        (value) => value && !/ {2,}/.test(value)
      )
      .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
      .min(3, "Name must be at least 3 characters long")
      .max(50, "Name must be not more than 50 characters long"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .min(9, "Please enter a valid phone number"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .test(
        "no-com.co",
        "Please enter a valid email address",
        (value) => !/\.com\.co$/.test(value)
      )
      .test(
        "no-single-letter-after-com",
        "Please enter a valid email address",
        (value) => !/\.com\.[a-zA-Z]$/.test(value)
      )
      .test(
        "no-spaces",
        "Please enter a valid email address",
        (value) => !/\s/.test(value)
      )
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test(
        "only-one-dot-com",
        "Please enter a valid email address",
        (value) => (value.match(/\.com/g) || []).length <= 1
      )
      .test(
        "no-repeated-domain",
        "Please enter a valid email address",
        (value) => {
          const domainExtensions = [".com", ".net", ".in", ".co"];
          const matches = value.match(/\.\w+/g);
          if (matches) {
            const uniqueExtensions = new Set(
              matches.map((match) => match.toLowerCase())
            );
            return uniqueExtensions.size === matches.length;
          }
          return true;
        }
      )
      .test(
        "laxEmail_new",
        "Please enter a valid email address.",
        function (value) {
          if (value) {
            if (
              value.indexOf("@") > 0 &&
              value.indexOf("@") === value.lastIndexOf("@")
            ) {
              const [localPart, domain] = value.split("@");
              const firstChar = localPart[0];
              const lastChar = localPart.charAt(localPart.length - 1);
              const afterAt = domain[0];

              if (
                firstChar === "." ||
                lastChar === "." ||
                /^[A-Za-z]$/.test(afterAt) === false
              ) {
                return false;
              }

              const domainParts = domain.split(".");
              if (domainParts.length < 2) {
                return false;
              }

              const topLevelDomain = domainParts.pop();
              if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
                return false;
              }

              return domainParts.every((part) => part.length > 1);
            } else {
              return false;
            }
          }
          return true;
        }
      )
      .required("Email is required"),

    companyName: Yup.string()
      .test(
        "no-first-space",
        "First letter should not be a space",
        (value) => value && !/^ /.test(value)
      )
      .test(
        "only-one-space-between",
        "Only one space allowed between words",
        (value) => value && !/ {2,}/.test(value)
      )
      .min(3, "Company name must be at least 3 characters")
      .max(50, "Company name must be not more than 50 characters long")
      .required("Company name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      companyName: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);

      // Send data to backend
      try {
        const response = await axios.post("backend/pdf_save.php", {
          name: values.name,
          email: values.email,
          phoneNumber: values.phoneNumber,
          companyName: values.companyName,
          category: "Catalogue",
          pdfName: selectedDocument.title,
          //doc_download_id: selectedDocument.id,
          createdAt: new Date().toISOString(),
          ip: "11.00.111.22",
        });

        if (response.data === 1) {
          if (modalType === "download") {
            // Handle file download
            const link = document.createElement("a");
            link.href = selectedDocument.file;
            link.download = selectedDocument.title;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Update download count
            const updatedDocuments = documents.map((doc) =>
              doc.id === selectedDocument.id
                ? { ...doc, downloads: doc.downloads + 1 }
                : doc
            );
            setSelectedDocument(
              updatedDocuments.find((doc) => doc.id === selectedDocument.id)
            );
          }

          navigate("/thank-you");
          formik.resetForm();
        } else {
          console.error("Error submitting form data");
        }
      } catch (error) {
        console.error("Error sending data to backend:", error);
      }

      setIsSubmitting(false);
      setModalType("");
    },
  });

  // Prevent scrolling when the modal is open
  useEffect(() => {
    if (modalType) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalType]);

  const handleViewClick = (document) => {
    setSelectedDocument(document);
    setModalType("view");
  };

  const handleDownloadClick = (document) => {
    setSelectedDocument(document);
    setModalType("download");
  };

  return (
    <>
      <Meta />
      <section className="cust-padding3 bg-light" id="productslist">
        <div className="container">
          <div className="">
            <h4 className="section-heading text-dark mb-3">Catalogues List</h4>
          </div>
          <div className="catelogue-wrapper">
            <div className="document-list">
              {documents.map((doc) => (
                <div key={doc.id} className="card download-item bg-grey">
                  <div className="download-img-wrapper">
                    <img
                      src="/images/pdf.png"
                      className="img-fluid"
                      alt="pdf icon"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="document-heading">{doc.title}</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {/* <a
                          className="btn btn-list-cp btn-sm me-2"
                          onClick={() => handleViewClick(doc)}
                        >
                          <i className="bi bi-eye-fill"></i>
                        </a> */}
                        <a
                          className="btn btn-list-cp btn-sm"
                          onClick={() => handleDownloadClick(doc)}
                        >
                          <i className="bi bi-download"></i>
                        </a>
                      </div>
                      {/* <p className="mb-0">
                      Download: <span>{doc.downloads}</span>

                      </p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {modalType && (
        <div
          className={`modal fade show d-block`}
          tabIndex="-1"
          aria-labelledby={`${modalType}formLabel`}
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id={`${modalType}formLabel`}>
                  Stay Informed! Enter Your Details
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalType("")}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit} id="catalogueform">
                  <div className="form-wrapper">
                    <div className="mb-3">
                      <label
                        htmlFor={`${modalType}Name`}
                        className="form-label"
                      >
                        Full Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`${modalType}Name`}
                        name="name"
                        placeholder="Enter your full name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onInput={handleNameInput}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor={`${modalType}Email`}
                        className="form-label"
                      >
                        Email address<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id={`${modalType}Email`}
                        name="email"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onKeyDown={handleKeyPress}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor={`${modalType}Number`}
                        className="form-label"
                      >
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      {/* <input
                        type="text"
                        className="form-control"
                        id={`${modalType}Number`}
                        name="phoneNumber"
                        placeholder="Enter your phone number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <div className="text-danger">{formik.errors.phoneNumber}</div>
                      ) : null} */}

                      <PhoneInput
                        country={countryCode} // Set the country code from state
                        value={formik.values.phoneNumber}
                        onChange={(phone) =>
                          formik.setFieldValue("phoneNumber", phone)
                        }
                        inputStyle={{ width: "100%" }} // Customize style as needed
                      />
                      {formik.errors.phoneNumber &&
                        formik.touched.phoneNumber && (
                          <div className="error text-danger">
                            {formik.errors.phoneNumber}
                          </div>
                        )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor={`${modalType}Company`}
                        className="form-label"
                      >
                        Company Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`${modalType}Company`}
                        name="companyName"
                        placeholder="Enter your company name"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <div className="text-danger">
                          {formik.errors.companyName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="text-end">
                    <input type="hidden" name="category" value="Catalogue" />
                    <button
                      type="submit"
                      className="primary-btn-1 btn-hover"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
        .error-msg{
             position: absolute;
            }
          .document-list {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            grid-gap: 15px;
          }
          .download-item {
            background-color: #f0f0f0;
            border: 1px solid #ddd;
            border-radius: .75rem;
            overflow: hidden;
          }
          .download-img-wrapper {
            background: #fff;
            padding: 25px 10px;
            border-radius: 0 0 0.75rem 0.75rem;
            text-align: center;
            border-bottom: 1px solid #ddd;
          }
          .download-img-wrapper img {
            width: 75px;
          }
          .document-heading {
            text-transform: capitalize;
            font-size: 18px !important;
            height: 50px;
            font-weight: 600 !important;
            margin-bottom: 10px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .btn-list-cp {
            padding: 3px 8px;
            background: #fff;
            font-size: 14px;
            border: 1px solid #ddd;
          }
          .btn-list-cp:hover {
            background: #161b87;
            color: #fff;
            border: 1px solid #fff;
          }
        `}
      </style>
    </>
  );
};

export default Catelogue;
