import React from 'react';
import { Link } from 'react-router-dom';


const ProductsMegamenu = ({ closeMenu }) => {

    // const handleLinkClick = () => {
    //     const menuWrapper = document.querySelector('.mm-menu-wrapper');
    //     if (menuWrapper) {
    //       // Hide the mega menu by setting display to none
    //       setTimeout(() => {
    //         menuWrapper.style.display = 'none';
    //       }, 10); // 10 milliseconds delay before hiding
      
    //       // Remove the inline display style after an additional delay
    //       setTimeout(() => {
    //         menuWrapper.style.display = ''; // Remove the inline display style
    //       }, 20); // 20 milliseconds total delay before removing the style
    //     }
    //   };
      


    return (
        <>
            <ul className="dropdown-menu mm-menu-wrapper bg-blue ">
                <div className='mega-menu bg-blue'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 d-lg-block d-none pe-0">
                                <div className="menu-state_left">
                                    <div>
                                        <h6 className="small-head">Our Products </h6>
                                        <div className='hr'></div>
                                        <p className='mt-2'>Our products include advanced filtration systems, eco-friendly designs, and state-of-the-art emission reduction technologies.</p>
                                     
                                        <Link to="/products" onClick={closeMenu} className="view-btn p-0">View all Products <i class="bi bi-arrow-right ps-3"></i></Link>
                                    </div>

                                    <div className="mt-40">
                                        <h6 className="small-head mb-3">Downloadables </h6>
                                        <div className="d-flex  justify-content-center flex-column gap-3 mt-3">
                                        <Link to="/catalogues" onClick={closeMenu} className='p-0'>
                                           <div className='primary-btn-1 bg-red outlined header-butn ps-3 d-flex justify-content-between'>
                                                <span>Catalogues</span> <i class="bi bi-arrow-right"></i>
                                            </div>
                                           </Link> 
                                           <Link to="/questionnaire" onClick={closeMenu} className='p-0'>
                                           <div className='primary-btn-1 bg-red outlined header-butn ps-3 d-flex justify-content-between'>
                                                <span>Questionnaires</span> <i class="bi bi-arrow-right"></i>
                                            </div>
                                           </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9 bg-gray">
                                <div className="color-row-menu menu-state_right">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h6 className="">Air Pollution Control Systems</h6>
                                            <div className='hr-2'></div>
                                            <ul className="mmenu-ul ps-0">
                                                <li><Link to="/low-nox-burners" onClick={closeMenu}>Low NOx Burners </Link></li>
                                                <li><Link to="/electrostatic-precipitator" onClick={closeMenu}>Electrostatic Precipitators (ESP) </Link></li>
                                                <li><Link to="/bag-house" onClick={closeMenu}>Bag Filter / Bag House</Link></li>
                                                <li><Link to="/flue-gas-conditioning-system" onClick={closeMenu}>Flue Gas Conditioning System for Waste to Energy </Link></li>
                                                <li><Link to="/afgc" onClick={closeMenu}>Ammonia Flue Gas Conditioning</Link></li>
                                                <li><Link to="/mist-separators" onClick={closeMenu}>Mist Separators – Candle Filters</Link></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-4">
                                            <h6 className="">Material Handling Systems</h6>
                                            <div className='hr-2'></div>
                                            <ul className="mmenu-ul">
                                                <li><Link to="/pneumatic-conveying-system" onClick={closeMenu}>Pneumatic Conveying System / Ash Handling System</Link></li>
                                                <li><Link to="/rotary-airlock-valve" onClick={closeMenu}>Rotary Airlock Valve</Link></li>
                                                <li><Link to="/slide-gate" onClick={closeMenu}>Slide Gate</Link></li>
                                                <li><Link to="/double-cone-valve" onClick={closeMenu}>Double Cone Valve</Link></li>
                                                <li><Link to="/double-flap-valve" onClick={closeMenu}>Double Flap Valve</Link></li>
                                                <li><Link to="/clam-shell-gate" onClick={closeMenu}>Clam Shell Gate / Sector Gate</Link></li>
                                                <li><Link to="/dome-valve" onClick={closeMenu}>Dome Valve</Link></li>
                                                <li><Link to="/drag-chain-conveyor" onClick={closeMenu}>Drag Chain Conveyor</Link></li>
                                                <li><Link to="/belt-conveyor" onClick={closeMenu}>Belt Conveyor</Link></li>
                                                <li><Link to="/screw-conveyor" onClick={closeMenu}>Screw Conveyor</Link></li>

                                            </ul>
                                        </div>

                                        <div className="col-lg-4">
                                            <h6 className="">Process Equipments</h6>
                                            <div className='hr-2'></div>
                                            <ul className="mmenu-ul">
                                            <li><Link to="/silencers" onClick={closeMenu}>Silencers</Link></li>
                                                <li><Link to="/defence" onClick={closeMenu}>Defence Equipments</Link></li>
                                                <li><Link to="/storage-tank" onClick={closeMenu}>Storage Tank</Link></li>
                                                <li><Link to="/agitators" onClick={closeMenu}>Agitators</Link></li>
                                                <li><Link to="/decomposers" onClick={closeMenu}>Decomposers</Link></li>
                                                <li><Link to="/pressure-vessels" onClick={closeMenu}>Pressure Vessels</Link></li>
                                                <li><Link to="/jacketed-vessels" onClick={closeMenu}>Jacketed Vessels</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ul>

        </>
    )
}

export default ProductsMegamenu;