import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const ServiceMegamenu = ({ closeMenu }) => {
    return (
        <>

            <ul className="dropdown-menu mm-menu-wrapper bg-blue">
                <div className='mega-menu bg-blue'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 d-lg-block d-none pe-0">
                                <div className="menu-state_left">
                                    <div>
                                        <h6 className="small-head">Our Services </h6>
                                        <div className='hr'></div>
                                        <p className='mt-2'>Our Services in air pollution control equipment includes installation, maintenance, system upgrades, and comprehensive emission monitoring.</p>
                                        <Link to="/services" onClick={closeMenu} className="view-btn p-0">View all Services <i class="bi bi-arrow-right ps-3"></i></Link>

                                    </div>

                                    <div className="mt-40">
                                        <h6 className="small-head mb-3">Downloadables </h6>
                                        <div className="d-flex  justify-content-center flex-column gap-3 mt-3">
                                            <Link to="/catalogues" onClick={closeMenu} className='p-0'>
                                                <div className='primary-btn-1 bg-red outlined header-butn ps-3 d-flex justify-content-between'>
                                                    <span>Catalogues</span> <i class="bi bi-arrow-right"></i>
                                                </div>
                                            </Link>
                                            <Link to="/questionnaire" onClick={closeMenu} className='p-0'>
                                                <div className='primary-btn-1 bg-red outlined header-butn ps-3 d-flex justify-content-between'>
                                                    <span>Questionnaires</span> <i class="bi bi-arrow-right"></i>
                                                </div>
                                            </Link>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9 bg-gray">
                                <div className="color-row-menu menu-state_right">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h6 className="">Inspection & Testing Services</h6>
                                            <div className='hr-2'></div>
                                            <ul className="mmenu-ul ps-0">
                                                <li><Link to="/cfd" onClick={closeMenu}>Computational Fluid Dynamics (CFD)</Link></li>
                                                <li><Link to="/gd-testing" onClick={closeMenu}>GD Testing </Link></li>
                                                <li><Link to="/emission-testing" onClick={closeMenu}>Emission Testing</Link></li>
                                                <li><Link to="/supervisory-services" onClick={closeMenu}>Supervisory Services</Link></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-4">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="button-tooltip">
                                                        Project Management & Plant Maintenance
                                                    </Tooltip>
                                                }
                                            >
                                                <h6 className="" >Project Management ...</h6>
                                            </OverlayTrigger>
                                            <div className='hr-2'></div>
                                            <ul className="mmenu-ul">
                                                <li><Link to="/erection-commissioning" onClick={closeMenu}>Erection & Commissioning Services</Link></li>
                                                <li><Link to="/retrofits" onClick={closeMenu}>Retrofits</Link></li>
                                                <li><Link to="/upgrades" onClick={closeMenu}>Upgrades</Link></li>
                                                <li><Link to="/residual-life-assessment" onClick={closeMenu}>Residual Life Assessment</Link></li>
                                                <li><Link to="/shut-down-maintenance" onClick={closeMenu}>Shut Down Maintenance</Link></li>
                                                <li><Link to="/annual-maintenance" onClick={closeMenu}>Annual Maintenance</Link></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-4">
                                            <h6 className="">Spares and Support</h6>
                                            <div className='hr-2'></div>
                                            <ul className="mmenu-ul">
                                                <li><Link to="/spares-for-esp" onClick={closeMenu}>Spares for ESP </Link></li>
                                                <li><Link to="/spares-for-baghouse" onClick={closeMenu}>Spares for Bag House</Link></li>
                                                <li><Link to="/spares-of-cement-plant" onClick={closeMenu}>Spares for Cement Plant</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ul>
        </>
    )
}

export default ServiceMegamenu;