import React, { useEffect } from 'react';
import { GDTHero } from '../../../common/Components/HeroSection';
import { GdTestingSS } from '../../../common/Components/ServiceSecondSec';
import { GdTestingCta } from '../../../common/Components/CtaSection';
import { GdTestingQUickLinks } from '../../../common/Components/QuickLinksSection';
import { GDTScroll } from '../../../common/Components/ScrollSec';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/GDT";


const GDT = () => {
  useEffect(() => {
    document.title = "Unicon | GD Testing Services for Reliable Performance";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <GDTHero/>
        <GdTestingSS/>
        <GDTScroll/>
        <GdTestingCta/>
        <GdTestingQUickLinks/>
    </>
  )
}

export default GDT;