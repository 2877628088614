import React, { useEffect } from 'react';
import ProductSelect from './ProductSelect';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from '../../common/Meta/GetQuote';

const GetQuote = () => {
  useEffect(() => {
    document.title = "Unicon Get a Quote – Tailored Solutions for Your Needs";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <ProductSelect/>
    </>
  )
}

export default GetQuote;