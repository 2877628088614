import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon's Advanced Low NOx Burners"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>
      Unicon's Advanced Low NOx Burners
      </title>
      <meta name="title" content="Unicon's Advanced Low NOx Burners" />
      <meta
        name="description"
        content="Unicon's Low NOx Burners ensure cleaner combustion with advanced technology, reducing emissions and enhancing efficiency for a sustainable future."
      />
      <meta name="keywords" content="Custom Low NOx Burners" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />

      <meta property="og:title" content="Unicon's Advanced Low NOx Burners" />
      <meta property="og:site_name" content="Unicon Low NOx Burners" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/low-nox-burners"
      />
      <meta
        property="og:description"
        content="Unicon's Low NOx Burners ensure cleaner combustion with advanced technology, reducing emissions and enhancing efficiency for a sustainable future."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/low-nox-burners/burner2.jpg"
      />
    </Helmet>
  );
};

export default Meta;
