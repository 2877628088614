import React from 'react';


const SectionFour = () => {
  return (
    <>
    
     <section className="complimentry-section-three position-relative ip-padding wow fadeInUp" data-wow-delay="0s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInUp"}}>
        <div className="container">
            <div className="section-title text-center mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                <h4 className="section-heading">Our types of Electrostatic <br/> Precipitators</h4>
            </div>
            <div className="row g-4">
                <div className="col-xl-4 col-md-6 col-sm-12">
                    <div className="blog-item aos-init aos-animate h-100" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50" style={{"border":"1px solid #ddd","padding":"0","borderRadius":"10px"}}>
                        <div className="image mb-4">
                            <img src="/images/products/electrostatic-percipitators/type1.jpg" alt="Blog" style={{"width":"100%","borderRadius":"10px 10px 0px 0px"}} />

                        </div>
                        <div className="content" style={{"padding":"20px","paddingTop":"0"}}>

                            <h4 className="section-sub_heading mb-2">Dry Electrostatic Precipitators
                            </h4>
                            <p className="mb-0 lh-base section-paragraph">Designed to remove dry particulate matter, such
                                as dust and
                                ash, from industrial exhaust streams. They work by passing dirty gas between
                                electrically charged plates and collecting the particles on those plates.</p>
                        </div>

                    </div>
                </div>
                <div className="col-xl-4 col-md-6 col-sm-12">
                    <div className="blog-item aos-init aos-animate h-100" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50" style={{"border":"1px solid #ddd","padding":"0","borderRadius":"10px"}}>
                        <div className="image mb-4">
                            <img src="/images/products/electrostatic-percipitators/type2.jpg" alt="Blog" style={{"width":"100%","borderRadius":"10px 10px 0px 0px"}} />

                        </div>
                        <div className="content" style={{"padding":"20px","paddingTop":"0"}}>

                            <h4 className="section-sub_heading mb-2">Wet Electrostatic Precipitators
                            </h4>
                            <p className="mb-0 lh-base section-paragraph">Used for removing wet and sticky particles like
                                oil, tar, or paint from gas streams. They operate similarly to dry ESPs but use
                                moisture to help collect the particulate matter.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6 col-sm-12">
                    <div className="blog-item aos-init aos-animate h-100" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="50" style={{"border":"1px solid #ddd","padding":"0","borderRadius":"10px"}}>
                        <div className="image mb-4">
                            <img src="/images/products/electrostatic-percipitators/type3.jpg" alt="Blog" style={{"width":"100%","borderRadius":"10px 10px 0px 0px"}} />

                        </div>
                        <div className="content" style={{"padding":"20px","paddingTop":"0"}}>

                            <h4 className="section-sub_heading mb-2">Plate Electrostatic Precipitators
                            </h4>
                            <p className="mb-0 lh-base section-paragraph">These use a series of flat plates to collect
                                particles. They are commonly used in power plants because they can handle large
                                volumes of gas and high particulate concentrations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <style>
        {`
        .lh-base {
            line-height: 1.5 !important;
        }
        `}
    </style>
    </>
  )
}

export default SectionFour;