import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';



const MobileMenu = () => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [openIndex, setOpenIndex] = useState(null);

    const toggleSublist = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => {
        if (show) {
            document.body.classList.add('offcanvas-open');
        } else {
            document.body.classList.remove('offcanvas-open');
        }

        // Clean up the class when the component unmounts or when `show` changes
        return () => {
            document.body.classList.remove('offcanvas-open');
        };
    }, [show]);

    return (
        <div>
            <button className="" type="button" onClick={handleShow}>
                <i class="bi bi-filter-right"></i>
            </button>

            <div
                className={`offcanvas offcanvas-end z-4 ${show ? 'show' : ''}`}
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
                style={{ visibility: show ? 'visible' : 'hidden' }}
            >
                <div className="offcanvas-header header-logo">

                    <img src="/images/logo-dark.png" className="light-logo" alt="" />

                    <button type="button" className="side-bar btn-close" onClick={handleClose} aria-label="Close"> <i class="bi bi-x-lg"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="main-menu">
                        <ul className='ps-0'>
                            <li>
                                <Link to="/" className='p-0' onClick={handleClose} aria-label="Close">Home</Link>
                            </li>
                            <li>
                                <Link to="/profile" className='p-0' onClick={handleClose} aria-label="Close"> Profile</Link>
                            </li>
                            <li>
                                <Link to="/design" className='p-0' onClick={handleClose} aria-label="Close"> Design</Link>
                            </li>
                            <li>
                                <div className='mbl-sub-list'>
                                    <Link
                                        to="/products"
                                        className='p-0'
                                        onClick={handleClose}
                                        aria-label="Close"
                                    >
                                        Products
                                    </Link>
                                    <i
                                        className={`bi bi-plus ${openIndex === 1 ? 'rotate-icon' : ''}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleSublist(1);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    ></i>
                                </div>
                                {openIndex === 1 && (
                                    <div className="color-row-menu menu-state_right">
                                        <div className="">
                                            <div className="">
                                                <h6 className="">Air Pollution Control Systems</h6>
                                                <div className='hr-2'></div>
                                                <ul className="mmenu-ul ps-0">
                                                    <li><Link to="/low-nox-burners" onClick={handleClose} aria-label="Close">Low NOx Burners </Link></li>
                                                    <li><Link to="/electrostatic-precipitator" onClick={handleClose} aria-label="Close">Electrostatic Precipitators (ESP) </Link></li>
                                                    <li><Link to="/bag-house" onClick={handleClose} aria-label="Close">Bag Filter / Bag House</Link></li>
                                                    <li><Link to="/flue-gas-conditioning-system" onClick={handleClose} aria-label="Close">Flue Gas Conditioning System for Waste to Energy </Link></li>
                                                    <li><Link to="/afgc" onClick={handleClose} aria-label="Close">Ammonia Flue Gas Conditioning</Link></li>
                                                    <li><Link to="/mist-separators" onClick={handleClose} aria-label="Close">Mist Separators – Candle Filters</Link></li>
                                                </ul>
                                            </div>

                                            <div className="mt-3">
                                                <h6 className="">Material Handling Systems</h6>
                                                <div className='hr-2'></div>
                                                <ul className="mmenu-ul">
                                                    <li><Link to="/pneumatic-conveying-system" onClick={handleClose} aria-label="Close">Pneumatic Conveying System / Ash Handling System</Link></li>
                                                    <li><Link to="/rotary-airlock-valve" onClick={handleClose} aria-label="Close">Rotary Airlock Valve</Link></li>
                                                    <li><Link to="/slide-gate" onClick={handleClose} aria-label="Close">Slide Gate</Link></li>
                                                    <li><Link to="/double-cone-valve" onClick={handleClose} aria-label="Close">Double Cone Valve</Link></li>
                                                    <li><Link to="/double-flap-valve" onClick={handleClose} aria-label="Close">Double Flap Valve</Link></li>
                                                    <li><Link to="/clam-shell-gate" onClick={handleClose} aria-label="Close">Clam Shell Gate / Sector Gate</Link></li>
                                                    <li><Link to="/dome-valve" onClick={handleClose} aria-label="Close">Dome Valve</Link></li>
                                                    <li><Link to="/drag-chain-conveyor" onClick={handleClose} aria-label="Close">Drag Chain Conveyor</Link></li>
                                                    <li><Link to="/belt-conveyor" onClick={handleClose} aria-label="Close">Belt Conveyor</Link></li>
                                                    <li><Link to="/screw-conveyor" onClick={handleClose} aria-label="Close">Screw Conveyor</Link></li>

                                                </ul>
                                            </div>

                                            <div className="mt-3">
                                                <h6 className="">Process Equipments</h6>
                                                <div className='hr-2'></div>
                                                <ul className="mmenu-ul">
                                                    <li><Link to="/silencers" onClick={handleClose} aria-label="Close">Silencers</Link></li>
                                                    <li><Link to="/defence" onClick={handleClose} aria-label="Close">Defence Equipments</Link></li>
                                                    <li><Link to="/storage-tank" onClick={handleClose} aria-label="Close">Storage Tank</Link></li>
                                                    <li><Link to="/agitators" onClick={handleClose} aria-label="Close">Agitators</Link></li>
                                                    <li><Link to="/decomposers" onClick={handleClose} aria-label="Close">Decomposers</Link></li>
                                                    <li><Link to="/pressure-vessels" onClick={handleClose} aria-label="Close">Pressure Vessels</Link></li>
                                                    <li><Link to="/jacketed-vessels" onClick={handleClose} aria-label="Close">Jacketed Vessels</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </li>

                            <li>
                                <div className='mbl-sub-list'>
                                    <Link
                                        to="/services"
                                        className='p-0'
                                        onClick={handleClose}
                                        aria-label="Close"
                                    >
                                        Services
                                    </Link>
                                    <i
                                        className={`bi bi-plus ${openIndex === 2 ? 'rotate-icon' : ''}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleSublist(2);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    ></i>
                                </div>
                                {openIndex === 2 && (
                                    <div className="color-row-menu menu-state_right">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <h6 className="">Inspection & Testing Services</h6>
                                                <div className='hr-2'></div>
                                                <ul className="mmenu-ul ps-0">
                                                    <li><Link to="/cfd" onClick={handleClose} aria-label="Close">Computational Fluid Dynamics (CFD)</Link></li>
                                                    <li><Link to="/gd-testing" onClick={handleClose} aria-label="Close">GD Testing </Link></li>
                                                    <li><Link to="/emission-testing" onClick={handleClose} aria-label="Close">Emission Testing</Link></li>
                                                    <li><Link to="/supervisory-services" onClick={handleClose} aria-label="Close">Supervisory Services</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-4">
                                                <h6 className="">Project Management & Plant Maintenance</h6>
                                                <div className='hr-2'></div>
                                                <ul className="mmenu-ul">
                                                    <li><Link to="/erection-commissioning" onClick={handleClose} aria-label="Close">Erection & Commissioning Services</Link></li>
                                                    <li><Link to="/retrofits" onClick={handleClose} aria-label="Close">Retrofits</Link></li>
                                                    <li><Link to="/upgrades" onClick={handleClose} aria-label="Close">Upgrades</Link></li>
                                                    <li><Link to="/residual-life-assessment" onClick={handleClose} aria-label="Close">Residual Life Assessment</Link></li>
                                                    <li><Link to="/shut-down-maintenance" onClick={handleClose} aria-label="Close">Shut Down Maintenance</Link></li>
                                                    <li><Link to="/annual-maintenance" onClick={handleClose} aria-label="Close">Annual Maintenance</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-4">
                                                <h6 className="">Spares and Support</h6>
                                                <div className='hr-2'></div>
                                                <ul className="mmenu-ul">
                                                    <li><Link to="/spares-for-esp" onClick={handleClose} aria-label="Close">Spares for ESP </Link></li>
                                                    <li><Link to="/spares-for-baghouse" onClick={handleClose} aria-label="Close">Spares for Bag House</Link></li>
                                                    <li><Link to="/spares-of-cement-plant" onClick={handleClose} aria-label="Close">Spares for Cement Plant</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </li>

                            <li>  <Link to="/job" className='p-0' onClick={handleClose} aria-label="Close"> Job</Link> </li>
                            <li> <Link to="/contact" className='p-0' onClick={handleClose} aria-label="Close"> Connect To Us </Link>  </li>                            
                        </ul>
                        <Link to="/get-a-quote" className="primary-btn btn-hover mt-3" onClick={handleClose} aria-label="Close">
                        Get a Quote <i class="bi bi-arrow-right ps-2"></i>
                    
                      </Link>
                        
                    </div>
                </div>
            </div>

            {show && <div className="modal-backdrop fade show" onClick={handleClose}></div>}
        </div>


    )
}

export default MobileMenu;