import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Flue Gas Conditioning System"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Flue Gas Conditioning System</title>
      <meta name="title" content="Unicon - Flue Gas Conditioning System" />
      <meta
        name="description"
        content="Install FGC systems for your plant to enhance flue gas conditioning and reduce SPM emissions effectively with our advanced technology."
      />
      <meta name="keywords" content="FGC technology" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="UniConinter - Flue Gas Conditioning System"
      />
      <meta property="og:site_name" content="Unicon FGC technology" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/flue-gas-conditioning-system"
      />
      <meta
        property="og:description"
        content="Install FGC systems for your plant to enhance flue gas conditioning and reduce SPM emissions effectively with our advanced technology."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/flue-gas-conditioning/img-1.jpg"
      />
    </Helmet>
  );
};

export default Meta;
