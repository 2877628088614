import React, { useEffect } from 'react';
import { StorageTankHero } from '../../../common/Components/HeroSection';
import SectionTwoThree from './SectionTwoThree';
import SectionFour from './SectionFour';
import { StorageTankAcc } from '../../../common/Components/AccordionSection';
import { StorageTankCta } from '../../../common/Components/CtaSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/StorageTank";


const StorageTank = () => {
  useEffect(() => {
    document.title = "Unicon | Storage Tanks & Silos for Safe Material Storage";
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  
  return (
    <>
        <Meta/>
        <StorageTankHero/>
        <SectionTwoThree/>
        <SectionFour/>
        <StorageTankAcc/>
        <StorageTankCta/>
    </>
  )
}

export default StorageTank;