import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon Contact Us – We're Here to Help"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon Contact Us - We're Here to Help</title>
      <meta name="title" content="Unicon Contact Us – We're Here to Help" />
      <meta
        name="description"
        content="Get in touch with Unicon for inquiries, support, or service. Our team is ready to assist you with customized solutions for all your industrial needs"
      />
      <meta name="keywords" content="Unicon Contact Us" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Contact Us – We're Here to Help"
      />
      <meta property="og:site_name" content="Unicon Contact" />
      <meta property="og:url" content="https://www.uniconinter.com/contact" />
      <meta
        property="og:description"
        content="Get in touch with Unicon for inquiries, support, or service. Our team is ready to assist you with customized solutions for all your industrial needs"
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/commitment.jpg"
      />
    </Helmet>
  );
};

export default Meta;
