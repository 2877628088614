import React from "react";
import { Link } from "react-router-dom";

const SectionFour = () => {
  return (
    <>
      <section className="complimentry-section-one ip-padding">
        <div className="container">
          <div className="row g-lg-4 g-3 align-items-center">
            <div
              className="col-md-6  wow fadeInLeft"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInLeft",
              }}
            >
              <div className="bn-content-wrapper ps-lg-4 ps-2 mt-lg-0 mt-4">
                <h4 className="section-heading  mb-2">Why Choose UNICON</h4>
                <p className="section-paragraph">
                  With over 30 years of experience, UNICON excels in the design,
                  supply, erection, and commissioning of turnkey defence
                  equipment projects, ensuring superior quality and reliability.
                </p>
                <div className="d-flex gap-3  mt-4  ">
                  <ul className="icon-list style-two me-3 ps-0 mb-0">
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Extensive Experience
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Turnkey Solutions
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Cost-Effective Designs
                    </li>
                  </ul>
                  <ul className="icon-list style-two d-xl-block d-none ps-0">
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Advanced Technology
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Longevity and Durability
                    </li>
                    <li>
                      <i className="bi bi-check-circle"></i>
                      Trusted by Defence Departments
                    </li>
                  </ul>
                </div>

                <div className="mt-3">
                  <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Enquire Now<i className="bi bi-arrow-right ms-3"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 wow fadeInRight"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: ".4s",
                animationName: "fadeInRight",
              }}
            >
              <div className="banner-gallery text-md-center">
                <img
                  style={{
                    boxShadow: "none",
                    borderRadius: "8px",
                    backgroundColor: "#f4f4f4",
                  }}
                  className="w-100 img-fluid"
                  src="/images/products/defence/dfc-main2.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <style>
        {`

                .ar, .ad{
                    font-size:30px;
                }
                    .adm{
                     font-size: 18px;
                      margin-top: -18px;
                    }
                .flow-diagram {
                    display: grid;
                    grid-template-columns: 1fr 50px 1fr 50px 1fr;
                    grid-template-rows: repeat(5, auto);
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                }
                .box {
                    padding: 10px;
                    border-radius:8px;
                    font-weight: 600;
                    color: #1a1a1a;
                    border: 1px solid;
                    background-color: #fff;
                    position: relative;
                }
        
        @media only screen and (max-width: 991px) {
            .icon-list.style-three {
                display: flex;
            }
            ul.icon-list.style-three li {
                align-items: center;
                margin-bottom: 0;
                text-align: center;
            }
        }
        @media only screen and (max-width: 575px) {
            .flex-sm-d-wrap {
                flex-wrap: wrap;
            }
        }
        `}
      </style>
    </>
  );
};

export default SectionFour;
