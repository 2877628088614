import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon Get a Quote – Tailored Solutions for Your Needs"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon Get a Quote – Tailored Solutions for Your Needs</title>
      <meta
        name="title"
        content="Unicon Get a Quote – Tailored Solutions for Your Needs"
      />
      <meta
        name="description"
        content="Request a customized quote from Unicon. Let us provide you with the best solutions for your industrial projects, tailored to meet your specific requirements."
      />
      <meta name="keywords" content="Unicon Get a Quote" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Get a Quote – Tailored Solutions for Your Needs"
      />
      <meta property="og:site_name" content="Unicon Get a Quote" />
      <meta property="og:url" content="https://www.uniconinter.com/get-a-quote" />
      <meta
        property="og:description"
        content="Request a customized quote from Unicon. Let us provide you with the best solutions for your industrial projects, tailored to meet your specific requirements."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/commitment.jpg"
      />
    </Helmet>
  );
};

export default Meta;
