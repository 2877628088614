import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon Jacketed Vessels for Temperature Control"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>
      Unicon Jacketed Vessels for Temperature Control
      </title>
      <meta name="title" content="Unicon Jacketed Vessels for Temperature Control"/>
<meta name="description" content="Unicon's jacketed vessels provide efficient temperature control for heating or cooling, ensuring reliable performance in various industrial processes."/>
<meta name="keywords" content="Best Jacketed Vessels for Industries"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Jacketed Vessels for Temperature Control"/>
<meta property="og:site_name" content="Unicon Jacketed Vessels"/>
<meta property="og:url" content="https://www.uniconinter.com/jacketed-vessels"/>
<meta property="og:description" content="Unicon's jacketed vessels provide efficient temperature control for heating or cooling, ensuring reliable performance in various industrial processes."/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/products/jacketed-vessels/jv-main.jpg"/>

    </Helmet>
  );
};

export default Meta;
