import React, { useEffect } from 'react';
import { SFBHero } from '../../../common/Components/HeroSection';
import { BaghouseSpareCta } from '../../../common/Components/CtaSection';
import { BaghouseSparesQUickLinks } from '../../../common/Components/QuickLinksSection';
import { BagHouseSparesTable } from '../../../common/Components/TablesProduct';
import { BHSparesSS } from '../../../common/Components/ServiceSecondSec';
import { BagHouseTabs } from '../../../common/Components/TabsSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from '../../../common/Meta/BagHouseSpares';

const BagHouse = () => {
  useEffect(() => {
    document.title = "Unicon | Baghouse Spares for Efficient Dust Collection"; 
    // Force scroll to top when the Profile component mounts
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <SFBHero/>
        <BHSparesSS/>
        <BagHouseTabs/>
        <BagHouseSparesTable/>
        <BaghouseSpareCta/>
        <BaghouseSparesQUickLinks/>
    </>
  )
}

export default BagHouse;