import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title =
        "Unicon | Computational Fluid Dynamics for Optimized Design"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Computational Fluid Dynamics for Optimized Design</title>
      <meta
        name="title"
        content="Unicon Computational Fluid Dynamics for Optimized Design"
      />
      <meta
        name="description"
        content="Unicon utilizes computational fluid dynamics (CFD) to simulate and optimize fluid flow, enhancing performance and efficiency in industrial applications"
      />
      <meta
        name="keywords"
        content="Best Computational Fluid Dynamics Services"
      />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta
        property="og:title"
        content="Unicon Computational Fluid Dynamics for Optimized Design"
      />
      <meta
        property="og:site_name"
        content="Unicon Computational Fluid Dynamics"
      />
      <meta property="og:url" content="https://www.uniconinter.com/cfd" />
      <meta
        property="og:description"
        content="Unicon utilizes computational fluid dynamics (CFD) to simulate and optimize fluid flow, enhancing performance and efficiency in industrial applications"
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/services/cfd/comp-img.jpg"
      />
    </Helmet>
  );
};

export default Meta;
