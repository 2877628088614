import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Double Flap Valves for Bulk Handling"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Double Flap Valves for Bulk Handling</title>
      <meta name="title" content="Unicon Double Flap Valves for Bulk Handling"/>
<meta name="description" content="Unicon's double flap valves ensure efficient bulk material flow with precise control and durability, designed to enhance performance in industrial applications"/>
<meta name="keywords" content="Double Flap Valves"/>
<meta name="robots" content="index,follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta property="og:title" content="Unicon Double Flap Valves for Bulk Handling"/>
<meta property="og:site_name" content="Unicon Double Flap Valves"/>
<meta property="og:url" content="https://www.uniconinter.com/double-flap-valve"/>
<meta property="og:description" content="Unicon's double flap valves ensure efficient bulk material flow with precise control and durability, designed to enhance performance in industrial applications"/>
<meta property="og:type" content="product"/>
<meta property="og:image" content="https://www.uniconinter.com/images/products/double-flap-valve/dfp-main.jpg"/>

    </Helmet>
  );
};

export default Meta;
