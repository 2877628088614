import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      document.title = "Unicon | Clam Shell Gate & Sector Gate Systems"; // Reset title if necessary
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Helmet>
      <title>Unicon | Clam Shell Gate & Sector Gate Systems</title>
      <meta
        name="title"
        content="Unicon Clam Shell Gate & Sector Gate Systems"
      />
      <meta
        name="description"
        content="Unicon's clam shell and sector gate systems provide efficient material flow control, offering robust design and reliable performance for industrial applications."
      />
      <meta name="keywords" content="Clam Shell Gate & Sector Gate Systems" />
      <meta name="robots" content="index,follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />

      <meta
        property="og:title"
        content="Unicon Clam Shell Gate & Sector Gate Systems"
      />
      <meta property="og:site_name" content="Unicon Clam Shell Gate" />
      <meta
        property="og:url"
        content="https://www.uniconinter.com/clam-shell-gate"
      />
      <meta
        property="og:description"
        content="Unicon's clam shell and sector gate systems provide efficient material flow control, offering robust design and reliable performance for industrial applications."
      />
      <meta property="og:type" content="product" />
      <meta
        property="og:image"
        content="https://www.uniconinter.com/images/products/clam-shell-gate/csg-main.jpg"
      />
    </Helmet>
  );
};

export default Meta;
