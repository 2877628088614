import React from 'react';
import {PressureTable} from '../../../common/Components/TablesProduct';

const SectionFour = () => {
    return (
        <>
            <section className="product-specification  position-relative ip-padding" id="prodSpecs">
                <div className="container">
                    <div className="spec-wrapper">
                        <div className="row g-lg-4 g-3">
                            <div className="col-lg-5 col-md-12 wow fadeInLeft" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                <div className="bn-content-wrapper wow fadeInLeft pe-xl-4 pe-0" data-wow-delay=".5s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInLeft" }}>
                                    <span className="section-sub-title  d-block">Pressure Vessels</span>
                                    <h4 className="section-heading mb-4">Product Specification</h4>
                                    <figure className="image m-img pt-0">
                                        <img src="/images/products/pressure-vessels/tab-img.jpg" alt="" className='img-fluid w-100 ' style={{ borderRadius: '8px' }} />
                                    </figure>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12 wow fadeInRight" data-wow-delay=".4s" style={{ "visibility": "visible", "animationDelay": ".4s", "animationName": "fadeInRight" }}>
                                <div className="specc-wrapper">
                                    <div className="table-responsive">

                                       <PressureTable/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionFour;