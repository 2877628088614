import React from 'react'
import { Link } from 'react-router-dom';
import JobForm from '../../common/Components/JobForm';


const jobOpenings = [
    {
        id: 1,
        title: "Jr. Design Engineer",
        date: "02/01/2024",
        description: "Drafting of Fabrication Drawings based on the Sub Assembly Drawings. Education Qualification: DME / BE or B. Tech (Mechanical). Experience Required: 1 to 2 years. Should be proficient in MS Office.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 2,
        title: "Marketing Engineer",
        date: "06/01/2024",
        description: "Assisting the sales team and clients, understanding the technical aspects of a product, executing marketing ideas. Education Qualification: Diploma, BE or B. Tech (EEE/ Electrical with instrumentation). Experience Required: Freshers or Experienced. Should be fluent in English.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 3,
        title: "Sales Coordinator",
        date: "07/01/2024",
        description: "Support the sales team by managing schedules, filing important documents, and coordinating sales activities. Ensure smooth operation between the sales and project teams. Education Qualification: Relevant degree in Engineering. Experience Required: 4-5 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 4,
        title: "Quality Manager",
        date: "15/01/2024",
        description: "Ensure that the company's products meet the highest quality standards. Develop and implement quality control processes, conduct audits, and resolve quality issues. Education Qualification: Relevant degree in Quality Management or Engineering. Experience Required: 15 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 5,
        title: "Sales Manager",
        date: "17/01/2024",
        description: "Lead the sales team to achieve sales targets and expand the customer base. Develop sales strategies, analyze market trends, and manage key accounts. Education Qualification: Relevant degree in Sales or Business Management. Experience Required: 10 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 6,
        title: "Telecalling Executive",
        date: "21/01/2024",
        description: " Handle inbound and outbound calls to potential and existing customers, providing product information and generating sales leads. Responsible for maintaining customer relationships and achieving sales targets. Education Qualification: Relevant degree or diploma. Experience Required: 2 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 7,
        title: "Purchase Manager",
        date: "28/01/2024",
        description: "  Manage the procurement of goods and services, ensuring that purchases meet the company's quality and cost standards. Develop and implement purchasing strategies and manage supplier relationships. Education Qualification: Relevant degree in Supply Chain or Procurement. Experience Required: 15-20 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 8,
        title: "Project Manager",
        date: "17/03/2024",
        description: "Lead and manage large-scale projects from conception to completion, ensuring that projects are delivered on time, within scope, and within budget. Education Qualification: Relevant degree in Project Management or Engineering. Experience Required: 15-20 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 9,
        title: "Production Engineer (Ass. Manager)",
        date: "09/04/2024",
        description: "Oversee production processes and ensure that production runs smoothly and efficiently. Responsible for planning and organizing production schedules and assessing project and resource requirements. Education Qualification: Relevant degree in Engineering. Experience Required: 5-7 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 10,
        title: "HR - Admin Executive",
        date: "28/04/2024",
        description: " Manage HR functions including recruitment, employee relations, and performance management. Oversee administrative tasks and coordinate with various departments to ensure smooth operations. Education Qualification: Relevant degree in HR or Administration. Experience Required: 5-7 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 11,
        title: "Mechanical Engineer",
        date: "11/05/2024",
        description: "Responsible for designing, developing, and analyzing mechanical systems. This role involves creating detailed designs, performing simulations, and working closely with cross-functional teams. Education Qualification: DME / BE or B. Tech (Mechanical). Experience Required: 3-4 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 12,
        title: "Mechanical Engineer (Fresher)",
        date: "19/06/2024",
        description: "Work under the supervision of senior mechanical engineers to assist in the design and development of mechanical systems and components. Involved in testing, analysis, and troubleshooting during the development process. Education Qualification: DME / BE or B. Tech (Mechanical). Experience Required: 3 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 13,
        title: "Electrical Engineer (Fresher)",
        date: "25/07/2024",
        description: "Assist senior engineers in designing, developing, and testing electrical systems. Gain hands-on experience in project work and collaborate with various teams. Education Qualification: Relevant degree in Electrical Engineering. Experience Required: 3 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 14,
        title: "Electrical Engineer",
        date: "13/08/2024",
        description: "Design, develop, and maintain electrical systems and components to required specifications, focusing on economy, safety, reliability, quality, and sustainability. Education Qualification: Relevant degree in Electrical Engineering. Experience Required: 3-4 years.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 15,
        title: "External Engineer - EEE",
        date: "08/10/2024",
        description: "Lead the design, development, and optimization of electrical systems, ensuring high performance, safety, and sustainability. Education: Bachelor’s in Electrical and Electronics Engineering (EEE). Experience: 3-4 years. Open Positions: 2.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    {
        id: 16,
        title: "External Engineer - Mechanical",
        date: "08/10/2024",
        description: "Spearhead the design and maintenance of mechanical systems, enhancing durability and operational efficiency. Education: Bachelor’s in Mechanical Engineering. Experience: 3-4 years. Open Positions: 2.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },

    {
        id: 17,
        title: "External Engineer - EEE (Fresher)",
        date: "08/10/2024",
        description: "Support electrical engineering projects, focusing on system integration and gaining hands-on experience in the field. Education: Bachelor’s in Electrical and Electronics Engineering (EEE). Experience: Fresher graduate. Open Positions: 5.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },
    
    {
        id: 18,
        title: "Purchase - Senior Executive",
        date: "08/10/2024",
        description: "Oversee purchasing operations, drive vendor negotiations, and ensure efficient supply chain management. Education: Any degree. Experience: 5+ years in procurement and purchasing. Open Positions: 1.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },

    {
        id: 19,
        title: "Marketing - Telecalling",
        date: "08/10/2024",
        description: "Execute targeted telemarketing campaigns, focusing on lead generation and customer engagement. Education: Specialization in Telecalling or related field. Experience: 2-3 years in marketing and telecalling. Open Positions: 1.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },

    {
        id: 20,
        title: "Production - Lath Turner (Fresher)",
        date: "08/10/2024",
        description: "Operate lathes in production, ensuring precision and quality in the manufacturing process. Education: IT/Diploma in relevant field. Experience: Fresher. Open Positions: 1.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },

    {
        id: 20,
        title: "Maintenance - Electrical/Plumbing",
        date: "08/10/2024",
        description: "Conduct routine maintenance and troubleshooting of electrical and plumbing systems, ensuring operational efficiency. Education: IIT/Electrician certification. Experience: 3-4 years. Open Positions: 1.",
        hrContact: "94431 21300",
        landline: "0422 2362167",
    },


];




const OpeningsSection = () => {
    return (
        <>
            <section className="cust-padding2 pt-0  wow fadeInUp" data-wow-delay=".1s" style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp" }}>
                <div className="container">
                    <div className="careers-card">
                        <h4 className="section-heading mb-4">Current Openings</h4>
                        <div className='openings-wrapper'>
                            <div className="row g-md-4 g-3">
                                {/* Reverse the jobOpenings array and then map over it */}
                                {jobOpenings
                                    .slice()
                                    .reverse()
                                    .map((job) => (
                                        <div key={job.id} className="col-lg-6">
                                            <div className="card jobs-card h-100">
                                                <div>
                                                    <div className="d-xxl-flex  d-lg-block d-sm-flex flex-wrap align-items-center gap-2">
                                                        <h4 className="mb-xxl-0 mb-lg-2 mb-sm-0 mb-2">{job.title}</h4>
                                                        <p className="ms-auto fw-bold mb-0">
                                                            <i className="bi bi-calendar-event"></i> {job.date}
                                                        </p>
                                                    </div>
                                                    <hr className="my-3" />
                                                    <div className="mb-2">
                                                        <h6 className="mb-2 fw-bold">Job Description</h6>
                                                        <p>{job.description}</p>
                                                    </div>
                                                </div>

                                                <div className='d-sm-flex justify-content-between align-items-center mt-lg-3 mt-1'>
                                                    <div>
                                                        <h6 className="mb-2 fw-bold">Contact Details</h6>
                                                        <div className="d-xl-flex d-lg-block d-md-flex d-block gap-4">
                                                            <p className="mb-0">
                                                                <b>
                                                                    <i className="bi bi-telephone-fill text-blue"></i> HR :
                                                                </b>{" "}
                                                                
                                                                <Link to="tel:+919443121300" style={{textDecoration: 'none', color: '#101010'}}>{job.hrContact}</Link>
                                                            </p>


                                                            {/* <p className="mb-0">
                                                            <b>
                                                                <i className="bi bi-telephone-fill text-blue"></i> Land Line :
                                                            </b>{" "}
                                                            {job.landline}
                                                        </p> */}
                                                        </div>
                                                    </div>

                                                    {/* <div className=" mt-sm-0 mt-3">
                                                        <Link to="" className="bg-less-btn" data-bs-toggle="modal" data-bs-target="#exampleModalJob">
                                                            Apply Now<i className="bi bi-arrow-right ms-1"></i>
                                                        </Link>
                                                        <JobForm />
                                                    </div> */}
                                                </div>




                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <style>
                {`

                
                .careers-card {
                    padding: 60px;
                    border-radius: 10px;
                    background-color: #ededff;
                }
                .jobs-card {
                    border-radius: 10px;
                    border: none;
                    padding: 30px;
                    justify-content: space-between;
                }
                .text-blue {
                    color: #052d83;
                }
                @media (max-width: 767px) {
                    .careers-card {
                        padding: 40px !important;
                    }
                    .jobs-card {
                        padding: 20px;
                    }
                }
                @media (max-width: 575px) {
                    .careers-card {
                        padding:20 px !important;
                    }
                }


               
                `}
            </style>
        </>
    )
}

export default OpeningsSection;