import React, { useEffect } from 'react';
import {LowNoxHero} from '../../../common/Components/HeroSection';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import { LowNoxCta } from '../../../common/Components/CtaSection';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Meta from "../../../common/Meta/LowNOxBurners";
const LowNox = () => {
  useEffect(() => {
    // Force scroll to top when the Profile component mounts
    document.title = "Unicon's Advanced Low NOx Burners";
    window.scrollTo(0, 0);

    setTimeout(() => {
      new WOW.WOW().init();
    }, 100); // 100ms delay
  }, []);
  return (
    <>
        <Meta/>
        <LowNoxHero/>
        <SectionTwo/>
        <SectionThree/>
        <SectionFour/>
        <LowNoxCta/>
    </>
  )
}

export default LowNox;