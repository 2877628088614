import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import $, { data } from "jquery";
import axios from "axios";
import Meta from "../../common/Meta/Connecttous";
// import { PhoneInput } from "react-international-phone";
// import "react-international-phone/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const countries = [
  { id: 1, name: "USA", flag: "/images/flags/usa.jpg" },
  { id: 2, name: "India", flag: "/images/flags/india.jpg" },
  { id: 3, name: "UAE", flag: "/images/flags/uae.jpg" },
  { id: 4, name: "Thailand", flag: "/images/flags/thailand.jpg" },
  { id: 5, name: "Malaysia", flag: "/images/flags/malaysia.jpg" },
  { id: 6, name: "Italy", flag: "/images/flags/italy.jpg" },
  { id: 7, name: "Indonesia", flag: "/images/flags/indonesia.jpg" },
  { id: 8, name: "Vietnam", flag: "/images/flags/vietnam.jpg" },
  { id: 9, name: "Sri Lanka", flag: "/images/flags/sri_lanka.jpg" },
  { id: 10, name: "Singapore", flag: "/images/flags/singapore.jpg" },
  { id: 11, name: "Costa Rica", flag: "/images/flags/costa_rica.jpg" },
  { id: 12, name: "Colombia", flag: "/images/flags/colombia.jpg" },
  { id: 13, name: "Chile", flag: "/images/flags/chile.jpg" },
  { id: 14, name: "Guinea", flag: "/images/flags/guinea.jpg" },
  { id: 15, name: "Congo", flag: "/images/flags/congo.jpg" },
  { id: 16, name: "Kenya", flag: "/images/flags/kenya.jpg" },
  { id: 17, name: "Spain", flag: "/images/flags/spain.jpg" },
  { id: 18, name: "Ireland", flag: "/images/flags/ireland.jpg" },
  { id: 19, name: "Turkey", flag: "/images/flags/turkey.jpg" },
  { id: 20, name: "Iraq", flag: "/images/flags/iraq.jpg" },
  { id: 21, name: "Jordan", flag: "/images/flags/jordan.jpg" },
  { id: 22, name: "Egypt", flag: "/images/flags/egypt.jpg" },
  { id: 23, name: "Iran", flag: "/images/flags/iran.jpg" },
  { id: 24, name: "Dubai", flag: "/images/flags/dubai.jpg" },
  { id: 25, name: "Nepal", flag: "/images/flags/nepal.jpg" },
];

const areaOfInterestOptions = [
  {
    id: 1,
    name: "Air Pollution Control Equipments",
    sub: [
      "Low NOx Burners",
      "Electrostatic Precipitators (ESP)",
      "Bag Filter / Bag House",
      "Flue Gas Conditioning System for Waste to Energy",
      "Ammonia Flue Gas Conditioning",
      "Mist Separators – Candle Filters",
    ],
  },
  {
    id: 2,
    name: "Material Handling",
    sub: [
      "Pneumatic Conveying System / Ash Handling System",
      "Rotary Airlock Valve",
      "Slide Gate",
      "Double Cone Valve",
      "Double Flap Valve",
      "Clam Shell Gate / Sector Gate",
      "Dome Valve",
      "Drag Chain Conveyor",
      "Belt Conveyor",
      "Screw Conveyor",
    ],
  },
  {
    id: 3,
    name: "Process Equipments",
    sub: [
      "Silencers",
      "Defence Equipments",
      "Storage Tank",
      "Agitators",
      "Decomposers",
      "Pressure Vessels",
      "Jacketed Vessels",
    ],
  },
  {
    id: 4,
    name: "Inspection & Testing",
    sub: [
      "Computational Fluid Dynamics (CFD)",
      "GD Testing(ESP)",
      "Emission Testing",
      "Supervisory Services",
    ],
  },
  {
    id: 5,
    name: "Projects Management",
    sub: [
      "Erection & Commissioning Services",
      "Retrofits",
      "Upgrades",
      "Residual Life Assessment",
      "Shut Down Maintenance",
      "Annual Maintenance",
    ],
  },
  {
    id: 6,
    name: "Plant Maintenance & Spares",
    sub: ["Spares for ESP", "Spares for Bag House", "Spares for Cement Plant"],
  },
];

const Connect = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [selectedAreaId, setSelectedAreaId] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [subOptions, setSubOptions] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState("");
  const [isSubFieldVisible, setIsSubFieldVisible] = useState(false);

  const [countryCode, setCountryCode] = useState("in");
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
        //console.log(countryCode);
      })
      .catch((error) => {
        setCountryCode("in")
        // console.log(countryCode);

        console.error("Error fetching IP information:", error);
      });
  }, []);

  const handleAreaChange = (e) => {
    const selectedId = e.target.value;
    const selectedArea = areaOfInterestOptions.find(
      (area) => area.id === parseInt(selectedId, 10)
    );

    setSelectedAreaId(selectedId);
    setSelectedAreaName(selectedArea ? selectedArea.name : "");
    setSubOptions(selectedArea ? selectedArea.sub : []);
    setSelectedSubOption("");

    // Show the sub-field if an area is selected
    setIsSubFieldVisible(selectedId !== "");
  };

  const handleSubOptionChange = (e) => {
    setSelectedSubOption(e.target.value);
  };

  const handleNameInput = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    e.target.value = filteredValue;
  };
  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      designation: "",
      organisation: "",
      message: "",
      areaOfInterest: "",
      subField: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .test(
          "no-first-space",
          "First letter should not be a space",
          (value) => value && !/^ /.test(value)
        )
        .required("Name is required")
        .test(
          "only-one-space-between",
          "Only one space allowed",
          (value) => value && !/ {2,}/.test(value)
        )
        .matches(/^[A-Za-z ]*$/, "Should be in A-Z, a-z letters")
        .min(3, "Name must be at least 3 characters long")
        .max(50, "Name must be not more than 50 characters long"),
      designation: Yup.string()
        .required("Designation is required")
        .test(
          "no-first-space",
          "First letter should not be a space",
          (value) => value && !/^ /.test(value)
        )
        .test(
          "only-one-space-between",
          "Only one space allowed between words",
          (value) => value && !/ {2,}/.test(value)
        )
        .min(3, "Designation must be at least 3 characters long")
        .max(50, "Designation must be not more than 50 characters long"),
      organisation: Yup.string()
        .required("Organisation is required")       
        .test(
          "no-first-space",
          "First letter should not be a space",
          (value) => value && !/^ /.test(value)
        )
        .test(
          "only-one-space-between",
          "Only one space allowed between words",
          (value) => value && !/ {2,}/.test(value)
        )
        .min(3, "Organisation must be at least 3 characters long")
        .max(50, "Organisation must be not more than 50 characters long"),
        email: Yup.string()
        .email("Please enter a valid email address")
        .test(
          "no-com.co",
          "Please enter a valid email address",
          (value) => !/\.com\.co$/.test(value)
        )
        .test(
          "no-single-letter-after-com",
          "Please enter a valid email address",
          (value) => !/\.com\.[a-zA-Z]$/.test(value)
        )
        .test(
          "no-spaces",
          "Please enter a valid email address",
          (value) => !/\s/.test(value)
        )
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Please enter a valid email address"
        )
        .test(
          "only-one-dot-com",
          "Please enter a valid email address",
          (value) => (value.match(/\.com/g) || []).length <= 1
        )
        .test(
          "no-repeated-domain",
          "Please enter a valid email address",
          (value) => {
            const domainExtensions = [".com", ".net", ".in", ".co"];
            const matches = value.match(/\.\w+/g);
            if (matches) {
              const uniqueExtensions = new Set(
                matches.map((match) => match.toLowerCase())
              );
              return uniqueExtensions.size === matches.length;
            }
            return true;
          }
        )
        .test(
          "laxEmail_new",
          "Please enter a valid email address.",
          function (value) {
            if (value) {
              if (
                value.indexOf("@") > 0 &&
                value.indexOf("@") === value.lastIndexOf("@")
              ) {
                const [localPart, domain] = value.split("@");
                const firstChar = localPart[0];
                const lastChar = localPart.charAt(localPart.length - 1);
                const afterAt = domain[0];
    
                if (
                  firstChar === "." ||
                  lastChar === "." ||
                  /^[A-Za-z]$/.test(afterAt) === false
                ) {
                  return false;
                }
    
                const domainParts = domain.split(".");
                if (domainParts.length < 2) {
                  return false;
                }
    
                const topLevelDomain = domainParts.pop();
                if (topLevelDomain.length < 2 || topLevelDomain.length > 4) {
                  return false;
                }
    
                return domainParts.every((part) => part.length > 1);
              } else {
                return false;
              }
            }
            return true;
          }
        )
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required")
      .min(9, "Please enter a valid phone number"),
      message: Yup.string()
      .test(
        "min-length",
        "Message must be at least 3 characters",
        (value) => !value || value.length >= 3
      )
      .test(
        "max-length",
        "Message must not be more than 500 characters long",
        (value) => !value || value.length <= 500
      )
      .test(
        "only-one-space-between",
        "Only one space allowed between words",
        (value) => !value || !/ {2,}/.test(value)
      ),
      areaOfInterest: Yup.string().required(
        "Please select an area of interest"
      ),
      subField: Yup.string().required("Please select a sub-option"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          "backend/Contact_form.php",
          {
            ...values,
            areaOfInterest: selectedAreaName,
            subField: selectedSubOption,
            mode: "addCenterModuleData_new",
          }
        );
        const responseData = response.data;
        if (responseData === 1) {
          formik.resetForm();
          setSelectedAreaName("");
          setSubOptions([]);
          setSelectedSubOption("");
          navigate("/thank-you");
          window.location.reload();
        } else {
          console.error("Error submitting form data");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      setIsSubmitting(false);
    },
  });

  useEffect(() => {
    document.title = "Unicon | Contact Us – We're Here to Help";
    window.scrollTo({
      top: 0,
      behavior: "auto", // Instant scroll to the top
    });
  }, []);
  return (
    <>
      <Meta />
      <section className="cust-padding3 bg-white" id="productslist">
        <div className="container">
          <div className="text-center">
            <h4 className="section-heading text-dark serv-prod-head">
              Get in touch with us
            </h4>
          </div>

          <div className="row">
            <div
              className="col-xxl-5 col-xl-5 col-lg-6 col-md-12 wow fadeInLeft "
              data-wow-delay=".5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInLeft",
              }}
            >
              <div className="card border-0">
                <div className="contact-box mb-3">
                  <i class="bi bi-geo-alt f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Location (Head Office)</p>
                    <p className="contact-info">
                      Bharathi Road,
                      <br />
                      Chinnavedampatti (PO), <br />
                      Coimbatore - 641 049, <br />
                      Tamil Nadu, India.
                    </p>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-phone f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Mobile</p>
                    <h4>
                      <a href="tel:+919487744417" className="contact-info">
                        +91 94877 44417
                      </a>
                    </h4>
                    <h4>
                      <a href="tel:+919442644463" className="contact-info">
                        +91 94426 44463
                      </a>
                    </h4>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-telephone f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Phone</p>
                    <h4>
                      <a href="tel:+914222362167" className="contact-info">
                        +91 422 2362167
                      </a>
                    </h4>
                    <h4>
                      <a href="tel:+914222362169" className="contact-info">
                        +91 422 2362169
                      </a>
                    </h4>
                    <h4>
                      <a href="tel:+914222362174" className="contact-info">
                        +91 422 2362174
                      </a>
                    </h4>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-printer f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Fax</p>
                    <a href="fax:+914222667309" className="contact-info">
                      +91 422 2667309
                    </a>
                  </div>
                </div>

                <div className="contact-box mb-3">
                  <i class="bi bi-envelope f-50 text-dark-red"></i>
                  <div className="contact-details">
                    <p className="text-dark-red mb-0">Email</p>
                    <h4 className="">
                      <a
                        href="mailto:marketing@uniconinter.com"
                        className="contact-info"
                      >
                        marketing@uniconinter.com
                      </a>
                    </h4>
                    <h4>
                      <a
                        href="mailto:info@uniconinter.com"
                        className="contact-info"
                      >
                        info@uniconinter.com
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-7 col-xl-7 col-lg-6 col-md-12 wow fadeInRight"
              data-wow-delay=".5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInRight",
              }}
            >
              <div className="card border-0">
                <div className="contact-form-area">
                  <div className="mb-2">
                    <h4 className="section-sub-title">LET’S TALK</h4>
                    <h4 className="mt-2">Contact Us</h4>
                  </div>
                  <div className="connect-form">
                    <form
                      id="connectusform"
                      className="contactform"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="row g-4 p-3">
                        <div className="col-xl-6 col-lg-12  col-12">
                          <div className="form-group position-relative">
                            <label>
                              Name <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="connectusName"
                              name="name"
                              className="form-control"
                              placeholder="Enter your name"
                              onInput={handleNameInput}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name && (
                              <div className="error-msg text-danger">
                                {formik.errors.name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12  col-12">
                          <div className="form-group position-relative">
                            <label>
                              Designation <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="connectusDesignation"
                              name="designation"
                              className="form-control"
                              placeholder="Enter Your Designation"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.designation}
                            />
                            {formik.touched.designation &&
                              formik.errors.designation && (
                                <div className="error-msg text-danger">
                                  {formik.errors.designation}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group position-relative">
                            <label>
                              Organisation <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="connectusOranisation"
                              name="organisation"
                              className="form-control"
                              placeholder="Enter Your Organisation"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.organisation}
                            />
                            {formik.touched.organisation &&
                              formik.errors.organisation && (
                                <div className="error-msg text-danger">
                                  {formik.errors.organisation}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-12">
                          <div className="form-group position-relative">
                            <label>
                              Email Address <span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              id="connectusEmail"
                              name="email"
                              className="form-control"
                              placeholder="Enter Your Email Address"
                              onKeyDown={handleKeyPress}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <div className="error-msg text-danger">
                                {formik.errors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-12 position-relative">
                          <div className="form-group">
                            <label>
                              Phone Number <span className="text-red">*</span>
                            </label>
                            {/* <input
                              type="text"
                              id="connectusphone"
                              name="phone"
                              className="form-control"
                              placeholder="Enter Your Phone Number"
                            /> */}

                            <PhoneInput
                              country={countryCode}
                              placeholder="Phone"
                              onChange={(phone) =>
                                formik.setFieldValue("phone", phone)
                              }
                              value={formik.values.phone}
                              inputStyle={{
                                width: "100%",
                                height: "45px",
                              }}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                              <div className="error-msg text-danger">
                                {formik.errors.phone}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label>
                              Area of Interest{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <select
                              name="areaOfInterest"
                              className="form-select border"
                              aria-label="Default select example"
                              value={selectedAreaId}
                              id="connectareaOfInterest"
                              onChange={(e) => {
                                // Call the area change handler
                                handleAreaChange(e);

                                // Reset the subfield selection
                                formik.setFieldValue("subField", ""); // Reset the selected subField

                                // Mark subField as touched to show the error message
                                formik.setFieldTouched("subField", true); // Mark it as touched to trigger error display

                                // Call Formik's handleChange
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                            >
                              <option value="">Select</option>
                              {areaOfInterestOptions.map((area) => (
                                <option key={area.id} value={area.id}>
                                  {area.name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.areaOfInterest &&
                              formik.errors.areaOfInterest && (
                                <div className="error-msg text-danger">
                                  {formik.errors.areaOfInterest}
                                </div>
                              )}
                          </div>
                        </div>

                        {isSubFieldVisible && (
                          <div className="col-lg-12 mt-0">
                            <div className="form-group position-relative">
                              <select
                                name="subField"
                                className="form-select mt-2 mb-1 border"
                                aria-label="Default select example"
                                value={selectedSubOption}
                                onChange={(e) => {
                                  handleSubOptionChange(e);
                                  formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                disabled={!subOptions.length}
                                id="connectussubField"
                              >
                                <option value="">Select</option>
                                {subOptions.map((subOption, index) => (
                                  <option key={index} value={subOption}>
                                    {subOption}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.subField &&
                                formik.errors.subField && (
                                  <div className="error-msg text-danger">
                                    {formik.errors.subField}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}

                        <div className="col-12">
                          <div className="form-group position-relative">
                            <label>
                              Message <small>(Optional)</small>
                            </label>
                            <textarea
                              id="connectusmessage"
                              name="message"
                              className="form-control"
                              style={{ cursor: "auto" }}
                              placeholder="Write Message..."
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.message}
                              rows="3"
                            ></textarea>
                            {formik.touched.message &&
                              formik.errors.message && (
                                <div className="error-msg text-danger">
                                  {formik.errors.message}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer  p-3 mt-2 mb-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            color: "#fff",
                            fontWeight: "600",
                            fontSize: "18px",
                            textTransform: "none",
                            backgroundColor: "#052d83",
                            border: "1px solid #052d83",
                            padding: "8px 24px",
                            borderRadius: "8px",
                            whiteSpace: "normal",
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  ">
              <div
                className="country-list-section wow fadeInUp"
                data-wow-delay=".5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="country-list-title">
                  <p>
                    UNICON proudly maintains a global presence in over 25
                    countries
                  </p>
                </div>
                <div className="contry-list-wrapper d-flex">
                  {countries.map((country) => (
                    <div key={country.id} className="flag-pill">
                      <img src={country.flag} alt={country.name} />
                      <p className="country-name mb-0">{country.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <style>
        {`

        .react-international-phone-country-selector-button{
            height: 45px; 
          }
            .error-msg {
              position: absolute;             
              font-size: 13px;
              line-height: 1;
            }
            .contact-box {
              padding: 20px;
              background: #F8F8F8;
              display: flex;
              justify-content: start;
              align-items: start;
              gap: 20px;
              border-radius: 0.75rem;
            }
            .f-50{
              font-size: 50px;
            }
            .text-dark-red{
              color: #cc0017;
            }
            .contact-info{
              margin-top: 8.4px;
              font-size: 18px;
              font-weight: 700;
              color: #1c1c1c !important;
              text-decoration: none;
            }
            .contact-form-area {
              padding: 50px 48px;
              background: #F8F8F8;
              border-radius: 0.75rem;
            }
            .contact-form-area input, .contact-form-area select, .contact-form-area textarea {
              height: auto;              
              border-radius: 0.5rem;
              border: 1px solid #cdcdcd;
              line-height: normal;
              padding: 12px;
              font-size: 16px;
            }
            .country-list-section{
              padding-top: 70px;
            }
            .country-list-title p{
              font-size: 24px;
              color: #101010;
              line-height: 1.5;
              margin-bottom: 1.5rem;
              text-align: center;
            }
            .contry-list-wrapper{
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              gap: .75rem;
            }
            .flag-pill{
              display: flex;
              column-gap: .3rem;
              align-items: center;
              padding: 5px 20px;
              background-color: #fff;
              border: 1px solid #d6d7f5;
              border-radius: 50px;
              text-align: left;
            }
            .flag-pill img {
              width: 24px;
            }
            .flag-pill p {
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 0px;
            }
              
            @media only screen and (max-width: 425px){
            .contact-form-area {
              padding: 20px;
            } 
            .contact-info {
              font-size: 14px;
            }
            }
            
          
        `}
      </style>
    </>
  );
};

export default Connect;
